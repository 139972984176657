import { useState, useEffect } from 'react'

// Usage
// function App() {
//   // Call our hook for each key that we'd like to monitor
//   const happyPress = useKeyPress('h')
//   const sadPress = useKeyPress('s')
//   const robotPress = useKeyPress('r')
//   const foxPress = useKeyPress('f')

//   return (
//     <div>
//       <div>h, s, r, f</div>
//       <div>
//         {happyPress && '😊'}
//         {sadPress && '😢'}
//         {robotPress && '🤖'}
//         {foxPress && '🦊'}
//       </div>
//     </div>
//   )
// }

export default function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler(e) {
    // e.preventDefault(); e.stopPropagation(); e.stopImmediatePropagation()
    if (e.keyCode===targetKey) {
      setKeyPressed(true)
    }
  }

  const upHandler = (e) => {
    // e.preventDefault(); e.stopPropagation(); e.stopImmediatePropagation()
    if (e.keyCode===targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return keyPressed
}