import { is_obj, oget, capitalize } from '../js/util'

export const BACKSPACE = 8
export const ENTER = 13
const COMA = 44
const DOT = 46

// console.log(n.toLocaleString('pt'))
//str.replace(/[A-Z]/g, u => u.toLowerCase())

const CURRENCY_SYMBOL = '€'

const DECIMAL = DOT
const RE_DECIMAL_EU = /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/
const RE_DECIMAL_US = /^(?:(?:\d+(?:\.\d*)?|\.\d+)(?:[-+/*%]|$))+$/ig
let RE = /^(d+)\.(d+)/ig

export const NUMERIC_TYPES = ['N', 'N1', 'N2', 'N3', 'N4', 'NK', 'NB', 'C', 'C2', 'C4', 'P', 'P1', 'P2', 'NC', 'NC2', 'NC3', 'NC4',
                              'F', 'F1', 'F2', 'F3', 'F4', 'FK']

// const frmt_float = (v,d) => console.log(parseFloat(v).toFixed(d))

const frmt_number = (n, decimals=0) => {
    const DECIMAL_SEPARATOR = ','
    const THOUSANDS_SEPARATOR = '.'
    if(n==='') return 0
    if(typeof n==='string' && isNaN(n)) return 'NA'
    if(typeof n==='string') n=parseFloat(n)
    if(n===undefined||n===null) n=0
    if(n>=10e11) return n.toExponential().toString()
    n = parseInt(n*10**decimals)/10**decimals
    let s = n.toString(), s2=''
    let k = s.indexOf(".")
    if(k===-1) k=s.length
    for(let i=0; i<k ; i++) {
        s2 += (s[i])
        if(!((k-i-1)%3) && i!==k-1) s2 += THOUSANDS_SEPARATOR
    }
    if(decimals) {
        let s_decimal =  s.substring(k+1, k+1+decimals)
        if(s_decimal==='') s_decimal=Array(0).fill(decimals)
        s2 += DECIMAL_SEPARATOR + s_decimal
        for(let i=s_decimal.length; i<decimals; i++) s2 += '0'
    }
    if(s2[0]==='-'&&s2[1]==='.') s2='-'+s2.substring(2)
    return s2
}

export const frmt_number_B = (n) => {
    if(n>=Math.pow(1024,4)) return `${Math.round(10*n/Math.pow(1024,4))/10}TB`
    if(n>=Math.pow(1024,3)) return `${Math.round(10*n/Math.pow(1024,3))/10}GB`
    if(n>=Math.pow(1024,2)) return `${Math.round(10*n/Math.pow(1024,2))/10}MB`
    if(n>=1024) return `${Math.round(10*n/1024)/10}KB`
    return `${Math.round(n)}B`
}

export const frmt_number_K = (n) => {
    let n2, un
    if(n>=Math.pow(1000,4)) {n2 = n/Math.pow(1000,4); un='t' }
    else if(n>=Math.pow(1000,3)) {n2 = n/Math.pow(1000,3); un='b' }
    else if(n>=Math.pow(1000,2)) {n2 = n/Math.pow(1000,2); un='m' }
    else if(n>=1000) {n2 = n/1000; un='k' }
    else {n2 = n; un='' }
    if(n2>10)
        n2 = Math.round(n2)
    else
        n2 = Math.round(10*n2)/10
    return `${n2}${un}`
}

const frmt_T = (n) => {
    let H = Math.floor(n/3600)
    let M = Math.floor((n-H*3600)/60)
    let S = Math.floor(n%60)
    H =  String(H).paddingLeft("00")
    M =  String(M).paddingLeft("00")
    S =  String(S).paddingLeft("00")
    return `${H}:${M}:${S}`
}

const frmt_percent = (n, decimals=0) => Number.parseFloat(n*100).toFixed(decimals)+'%'
const frmt_currency = (v, decimals=0) => v ? `${CURRENCY_SYMBOL}${frmt_number(v,decimals)}` : ''
const frmt_currency_K = (v) => `${CURRENCY_SYMBOL}${frmt_number_K(v)}`
const frmt_str = (v) => (v!==null&&v!==undefined) ? v.toString():''
const frmt_date = (v) => {
    if(!v || !v.length || v.length<10 || v=='') return ''
    const mmm = ['','Jan','Feb','Mar','Apr','Mai','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let m,d
    try {
        m = parseInt(v.substring(5,7))
        m = mmm[m]
        d = parseInt(v.substring(8,10))
    } catch(error) {
        return ''
    }
    return `${m} ${d}`
}
const frmt_datetime = (v) => {
    if(!v || !v.length || v=='') return ''
    return v.substring(0,16)
}

const check_c_number = (c, v, digits=15, decimals=2) => {
    let s = ((v||'') + String.fromCharCode((c===COMA && DECIMAL===DOT)?DOT:c))
    if(s.length>digits) return false
    let m = s.match(/(\d+)/g)
    if(!m) return false
    if(m.length===2 && m[1].length>decimals) return false
    return s.match(RE_DECIMAL_US)
}
const check_c_nq = (c, v, digits=15, decimals=2) => {
    // let s = ((v||'') + String.fromCharCode((c===COMA && DECIMAL===DOT)?DOT:c))
    // if(s.length>digits) return false
    // let m = s.match(/(\d+)/g)
    // if(!m) return false
    // if(m.length===2 && m[1].length>decimals) return false
    // return s.match(RE_DECIMAL_US)
}
const check_passwd = v => v ? ((v.length>=6) ? true : false) : false
const check_passwd2 = (v, props) => props ? (v===props.match) : false
const check_otp = v => v ? ((v.length===4) ? true : false) :  false
const check_email = v => {
    if(!v) return false
    if(v.substring(v.length-3)=='.co') return false
    return /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/.test(v.toUpperCase())
}

export const formater = (type, v) => {
    if(v==null)
        return null
    if(!type) type='S'
    switch(type.toUpperCase()) {
        case 'S': return frmt_str(v)
        case 'D': return frmt_date(v)
        case 'DT': return frmt_datetime(v)
        case 'N': return frmt_number(v,0)
        case 'N1': return frmt_number(v,1)
        case 'N2': return frmt_number(v,2)
        case 'N3': return frmt_number(v,3)
        case 'N4': return frmt_number(v,4)
        case 'K':
        case 'NK': return frmt_number_K(v)
        case 'NB': return frmt_number_B(v)
        case 'C': return frmt_currency(v,0)
        case 'C2': return frmt_currency(v,2)
        case 'C4': return frmt_currency(v,4)
        case 'CK': return frmt_currency_K(v)
        case 'P': return frmt_percent(v,0)
        case 'P1': return frmt_percent(v,1)
        case 'P2': return frmt_percent(v,2)
        case 'F': return frmt_number(v,0)
        case 'FK': return frmt_number_K(v)
        case 'F1': return frmt_number(v,1)
        case 'F2': return frmt_number(v,2)
        case 'F3': return frmt_number(v,3)
        case 'F4': return frmt_number(v,4)
        default: return frmt_str(v)
    }
}

export const get_align = (type)  => {
    if(NUMERIC_TYPES.includes(type) || type==='DT') return 'Right'
    return 'Left'
}
export const get_width = (type)  => {
    if(NUMERIC_TYPES.includes(type)) return 100
    if(type==='DT') return 130
    return 250
}

export const get_checkc = (type) => {
    const t = type.toLowerCase()
    if(!TYPES[t] || !TYPES[t].check_c) return null
    return TYPES[t].check_c
    // return () => TYPES[t].check_c
    //     ? (c,v)=>TYPES[t].check_c(c,v,TYPES[t].digit,TYPES[t].decimals)
    //     : ()=>true
}

export const get_checkf = (type) => {
    const t = type.toLowerCase()
    if(!(t in TYPES)) return null
    return TYPES[t].check_f || null
}

const check_c_no_space = (c,v,props) => !([32,9].includes(c))
const check_c_size = (s,n) => (n&&s) ? (s.length<=n-1?true:false) : true
const check_size = (s,n) => (n&&s) ? (s.length>=n?true:false) : true

export const TYPES = {
    text: {inputtype: 'text', placeholder: '', maxlen: 255, errormsg: '', check_c: (c,v,props)=>check_c_size(v,props.maxlen), check_f: (v,props)=>check_size(v,props.minlen)},
    area: {inputtype: 'text', placeholder: '', maxlen: 10, errormsg: '', check_c: (c,v,props)=>check_c_size(v,props.maxlen), check_f: (v,props)=>check_size(v,props.minlen)},
    email: {inputtype: 'email', placeholder: 'Email', maxlen: 255, errormsg: 'Invalid email', check_c:check_c_no_space, check_f:check_email},
    user: {inputtype: 'email', placeholder: 'Email', maxlen: 255, errormsg: 'Invalid email', check_c:check_c_no_space, check_f:check_email},
    password: {inputtype: 'password', placeholder: 'Password', maxlen: 255, errormsg: 'Minimum 6 characters', check_f:check_passwd},
    password1: {inputtype: 'password', placeholder: 'Password', maxlen: 255, errormsg: 'Minimum 6 characters', check_f:check_passwd},
    password2: {inputtype: 'password', placeholder: 'Password', maxlen: 255, errormsg: 'No match', check_f:check_passwd},
    otp: {inputtype: 'text', placeholder: 'OTP', maxlen: 4, errormsg: '', check_f: check_otp},
    enum: {inputtype: 'text', placeholder: '', maxlen: 15, errormsg: ''},
    n: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 0, check_c: check_c_number},
    n1: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 1, check_c: check_c_number},
    n2: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 2, check_c: check_c_number},
    n3: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 3, check_c: check_c_number},
    n4: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 4, check_c: check_c_number},
    nk: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, check_c: check_c_number},
    nb: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, check_c: check_c_number},
    nq: {inputtype: 'text', placeholder: '', maxlen: 15, errormsg: '', digits: 15, check_c: check_c_nq},
    c: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 0, check_c: check_c_number},
    c2: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 2, check_c: check_c_number},
    c3: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 3, check_c: check_c_number},
    c4: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 4, check_c: check_c_number},
    p: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 0, check_c: check_c_number},
    p1: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 1, check_c: check_c_number},
    p2: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', decimals: 2, check_c: check_c_number},
    f: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 0, check_c: check_c_number},
    f1: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 1, check_c: check_c_number},
    f2: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 2, check_c: check_c_number},
    f3: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 3, check_c: check_c_number},
    f4: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, decimals: 4, check_c: check_c_number},
    fk: {inputtype: 'number', placeholder: '', maxlen: 10, errormsg: '', digits: 15, check_c: check_c_number},
    // ENUM, SET, REF, CC, CCDATE, CCCVC, DT, D, T
}

export const get_col_name = (meta, col, layout=null) => {
    if(is_obj(col)) return col.name
    const name1 = oget(layout, ['cols',col,'name'])
    if(name1) return name1
    const name2 = oget(meta, ['fields',col,'name'])
    if(name2) return name2
    return capitalize(col)
}

export const get_col_type = (props, col)  => {
    if(!props) return 'S'
    let t = oget(props, ['layout', 'cols', col, 'type'])
    if(t) return t
    t = oget(props, ['meta', 'fields', col, 'type'])
    if(t) return t
    if(col==='date') return 'D'
    if(['count', 'quantity', 'clicks', 'orders', 'range', 'quantity', 'requests', 'logins', 'ustatus', 'nproducts', 'nprices', 'rank'].includes(col)) return 'N'
    if(['price'].includes(col)) return 'C2'
    if(['min', 'max', 'mean', 'median', 'std', 'var', 'variation'].includes(col)) return 'N2'
    if(['index', 'indexall', 'p'].includes(col)) return 'P2'
    if(props.frmt) return props.frmt
    return 'S'
}

export const is_numeric_type = (type)  => {
    const t = type.substring(0,1).toLowerCase()
    if(t==='n'||t==='c') return true
    return false
}

export const is_col_numeric = (props, col) => is_numeric_type(get_col_type(props,col))

// export const is_col_numeric = (props, col) => {
//     const t = get_col_type(props,col).substring(0,1)
//     if(t==='N'||t==='C') return true
//     return false
// }

export const get_col_align = (props, col) => {
    let a
    if(is_obj(col) && col.align) a=col.align
    else a=oget(props, ['layout', 'cols', col, 'align'])
    if(a) return a
    a = oget(props, ['meta', 'fields', col, 'align'])
    if(a) return a
    a = oget(props, ['hrender', 'align'])
    if(a) return a
    a=oget(props, ['layout', 'align'])
    if(a) return a
    const t = get_col_type(props, col)
    return get_align(t)
}

export const get_lookup = (meta,cols) => cols.map(e=>{return {id:e, name:get_name(meta,e)}})

export const name_from_col = (d, col) => d[col]['name'] || capitalize(col)
export const get_name = (meta, col) => {
  if(col in meta['drills']) return name_from_col(meta['drills'], col)
  if(col in meta['fields']) return name_from_col(meta['fields'], col)
  return col.replace(/_/g, ' ').replace(/\w\S*/g, (w)=>w.charAt(0).toUpperCase()+w.substr(1).toLowerCase())
}
export const get_frmt = (meta, col) => {
  if(col.substring(col.length-2) === '_p') return 'P'
  return (col in meta['fields'] && meta['fields'][col]['type']) ? meta['fields'][col]['type'] : 'T'
}
export const get_enums = (meta, d) => (meta.enums && meta.enums[d]) ? meta.enums[d] : []
export const get_drills = (meta, col) => oget(meta, ['drills', col, ['drills']])
export const get_c = (meta, d, drill_filters) => {
    if(!meta) return null
    if(!(d in meta.drills)) return d
    let idx = drill_filters ? drill_filters.length : 0
    return meta.drills[d].drills[idx]
}

export const desc_period = (d1, d2) => {
    return 'Last week'
}

export const get_tbl = (meta, col) => {
    let tbl = oget(meta, ['enums', col])
    if(tbl) return col
    tbl = oget(meta, ['lookups', col])
    if(tbl) return tbl
    tbl = oget(meta, ['tlookups', col])
    if(tbl) return tbl
    return col
  }