const URL = 'http://127.0.0.1'


const fetch_headers = () => { 
  const headers = {'Content-Type': 'application/json', 'Accept': 'application/json'}
  const jwt_token = localStorage.getItem('jwt_token')
  if(jwt_token) headers['Authorization'] = `Bearer ${jwt_token}`
  return headers
}

const request = (rurl, method, payload, f1, f2) => {
  const PORT = window.location.port==='3000' ? 443 : window.location.port
  const url = `https://${window.location.hostname}:${PORT}${rurl}`
  const headers = fetch_headers()
  let options = {
    method: method,
    headers: headers,
    mode: 'cors'
  }
  if(method==='POST') options['body'] = JSON.stringify(payload)
  fetch(url, options)
  .then(res=>res.json())
  .then(
    res => {
      if(res.status==='OK') f1(res)
      else f2(res.error_msg)
    },
    (error) => {
      console.log(`WEB request fetch error: url=${url}, error=${error}`)
      if(f2) f2(error)
    }
  )
}

const wrequest = async (rurl, method='POST', payload={}) => {
  const PORT = window.location.port==='3000' ? 443 : window.location.port
  const url = `https://${window.location.hostname}:${PORT}${rurl}`
  const headers = fetch_headers()
  let options = {method: method, headers: headers}
  if(method==='POST') options['body'] = JSON.stringify(payload)
  try {
    let res = await fetch(url, options)
    if(res==null) {
      console.log(`WREQUEST ERROR: ${url} returned null`)
      return null
    }
    if(res.status===200) {
      res = await res.json()
      return res
    }
    else if(res.status===400) {
      res = await res.json()
      console.log(`WREQUEST SERVICE ERROR: ${url}: http_status_code=400`)
      console.log(res)
      return null
    }
    else {
      console.log(`WREQUEST SERVICE ERROR: ${url}: http_status_code=${res.status}`)
      console.log(res)
      return null
    }
  }
  catch(err) {
    console.log(`WREQUEST EXCEPTION: ${url}`)
    console.log(err)
    return null
  }
}

const download = async (fname) => {
  const PORT = window.location.port==='3000' ? 443 : window.location.port
  const url = `https://${window.location.hostname}:${PORT}/api/download_workfile/${fname}`
  console.log(url)
  const headers = {'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`}
  let options = {method:'GET', headers:headers, mode:'cors'}
  try {
    let res = await fetch(url, options)
    if(res.status!=200) {
      console.log(`ERROR: download fetch status!=200: ${res.status}`)
      return null
    }
    res = await res.blob()
    return res
  }
  catch(err) {
    console.log(`EXCEPTION: download ${url}: ${err}`)
    return null
  }
}

const upload = (route, files, onload, onerror, onabort, onprogress) => {
  // const PORT = window.location.port==='3000' ? 443 : window.location.port
  const PORT = window.location.port
  // const url = `https://${window.location.hostname}:${PORT}${route}`
  // const host = window.location.hostname
  // const url1 = `https://${host}${url}`
  const url = `https://${window.location.hostname}${route}`
  let xhr = new XMLHttpRequest()
  console.log(url)
  // xhr.upload.onload =  function() {onload(xhr)}
  xhr.addEventListener('load', function() {onload(this)})
  xhr.upload.onerror = onerror
  xhr.upload.onabort = onabort
  xhr.upload.onprogress = onprogress
  xhr.open('POST', url)
  const formData = new FormData()
  files.forEach((e,i) => formData.append(`file-${i}`, e.file, e.path))
  xhr.send(formData)
  return xhr
}

const download_blob = (blob, fname) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fname
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

const smart_pool = async (jid, f1, f2, eta) => {
  eta = eta || 100
  try {
    let res = await wrequest("/api/get_job", 'POST', {'jid': jid})
    if(res['jstatus']!=2) {
      // console.log(eta, res['eta'])
      setTimeout(smart_pool, eta*2, jid, f1, f2, eta*2)
      return
    }
    f1(res)
  }
  catch(err) {f2(err)}
}

export { upload, download, wrequest, request, fetch_headers, download_blob }

//const upload2 = (file, onload, onerror, onabort, onprogress) => {
  // var input = document.querySelector('input[type="file"]')
  // var data = new FormData()
  // data.append('file', input.files[0])
  // data.append('user', 'hubot')
  // fetch('/avatars', {
  //   method: 'POST',
  //   body: data
  // })
//}

// export const wrequest_log_error = (res, pre='') => {
//   let s = 'wrequest ERROR '
//   if(pre) s+=`${pre}`
//   if(res && res.err_msg) s += `: ${res.err_msg}`
//   console.log(s)
// }

// export const wrequest_upload_link = async (name, type, size) => {
//   const PORT = window.location.port==='3000' ? 443 : window.location.port
//   const url = `https://${window.location.hostname}:${PORT}/upload`
//   const headers = fetch_headers()
//   let options = {method: 'POST', headers: headers, mode: 'cors'}
//   const payload = {'name':name, type:type, size:size}
//   options['body'] = JSON.stringify(payload)
//   try {
//     const res = await fetch(url, options)
//     res = await res.json()
//     if(res.status!=200) {
//       console.log(`wrequest_upload_link error: url=${url}, error=${res.error}`)
//       return null
//     }
//     return res.uploadlink
//   }
//   catch(err) {
//     console.log(`wrequest_upload_link EXCEPTION: url=${url}, error=${err}`)
//     return null
//   }
// }

// const upload_fetch = (url, file, controller, uploadDone_cb, uploadError_cb) => {
//   const host = window.location.hostname
//   const url1 = `https://${host}${url}`
//   let formData = new FormData()
//   formData.append('file', file)
//   fetch(url1, {method:'POST', body:formData, signal:controller.signal})
//       .then(async res => uploadDone_cb(res))
//       .catch(err => uploadError_cb(err))
// }
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController
// let controller = new AbortController()
// setAbortController(controller)
// upload(res.url, file, controller, uploadDone, uploadError)

// const upload_job = async (q, uid, fname, ondone, onerror, oneta) => {
//   let res =  await wrequest('/api/publish_job', 'POST', {q:q, payload: {uid:uid, fname:fname}})
//   if(!res || !res.jid) {onerror('/api/publish_job request error'); return}
//   const jid = res.jid
//   for(let i=0; i<3; i++) {
//       await sleep(500)
//       res = await wrequest('/api/get_job', 'POST', {jid:jid})
//       if(!res) {onerror('/api/get_job request error'); return}
//       if(res.eta) break
//   }
//   if(!res) {onerror('/api/get_job no data'); return}
//   let eta = res.eta
//   oneta(eta)
//   let t = eta/2*1000
//   t = Math.max(1000,Math.min(t,15000))
//   for(let i=0; i<3; i++) {
//       await sleep(t)
//       res = await wrequest('/api/get_job', 'POST', {jid:jid})
//       if(!res) {onerror('/api/get_job request error'); return}
//       if(res.ts_done) break
//       t *= 2
//   }
//   ondone()
// }

// await upload_job('Q-PARSER', uid, fname)

