import React, { useState, useRef, useEffect } from 'react'
import Box from './Box'
import Icon from './Icon'
import Image from './Image'
import Text from './Text'
import ValueBox from './ValueBox'
import Button from './Button'
import Section from './Section'
import Select from './Select'
import '../App.css'
import { oget, get_prop, cls_get, get_url, urlf } from '../js/util'

const TYPES = ['Msg', 'Card1', 'Card2', 'Simple', 'Item', 'Box', 'Menu', 'Image', 'Topic', 'Documents', 'Error', 'Profile']

export default (props) => {

    const onSelect = (id) => props.onSelect ? props.onSelect(id):null
    const onLink = (e) => {e.preventDefault(); e.stopPropagation(); window.open(props.url, '_blank').focus()}

    useEffect(() => {
    }, [props.mobile])

    const card_msg = () => {
        const w = props.w || 400
        const h = props.h || 264
        return(
            <Box column w={w} h={h} ph={50} pt={50} pb={32} center centerv bg="white" br={12} shadow onClick={props.onClick}>
                {props.onCloseCard && <Icon size={25} right={32} top={26} icon="close" onClick={props.onCloseCard}/>}
                <Text fs={24} w100 mb={27} text={props.title}/>
                <Text fs={16} w100 text={props.text}/>
                {props.children}
                {/* {props.buttons && props.buttons.map(e=>e())} */}
                <Box w100 alignbottom>
                    {props.onOk && <Button ok onClick={props.onOk}/>}
                    {props.onClose && <Button close onClick={props.onClose}/>}
                    {props.onCancel && <Button mr={20} cancel onClick={props.onCancel}/>}
                    {props.onDelete && <Button delete onClick={props.onDelete}/>}
                </Box>
            </Box>
        )
    }

    const card_1 = () => {
        const img = urlf(oget(props.content,['baseurl']),oget(props,['img']))
        const mb = props.mb || 20
        return(
            <Box column w={props.w||380} minh={310} mr={20} mb={mb} ph={30} pv={30} br={16} center bg="sec80" onClick={props.onClick}>
                <Box w100 mb={0} pv={20} href={props.url}>
                    <Image w={130} iurl={img}/>
                </Box>
                <Box center>
                    <Text fs={22} lineHeight={1.3} bold w100 mb={27} color="pri80" text={props.title}/>
                    <Text fs={18} lineHeight={1.5} w100 color="pri80" text={props.text}/>
                </Box>
                {props.callout &&
                    <Box w100 mt={20}>
                        <Box ph={20} pv={10} bg="red" br={10}>
                            <Text fs={16} bold color="white" text={props.callout}/>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const card_2 = () => {
        const w = props.w100 ? '100%' : (props.w || 300)
        const wz = props.wz || null
        const h = props.h || 310
        const h1 = 54
        const mr = props.mr!=null ? props.mr : 20
        const bg = props.bg || 'white'
        const color = props.color || "#1E414C"
        return(
            <Box w={wz?null:w} wz={wz} h={h} mr={mr} mb={20} br={12} b="#DCDDE1" bg={bg}>
                <Box w100 h={h1} bb="#DCDDE1">
                    <Icon ml={22} mr={10} mt={15} icon={props.icon}/>
                    <Text fs={16} caps text={props.title} bold mt={15} color={color}/>
                </Box>
                <Box column={true} w100 hz={h1} ph={24}>
                    {props.children}
                </Box>
            </Box>
        )
    }

    const card_box = () => {
        const bg = props.bg || 'white'
        const b = '1px solid var(--grey100)'
        // if(bg==='white') {style.borderTop=b; style.borderBottom=b}
        return(
            <Box column w={220} h={60} mh={10} mv={10} ph={20} pv={10} white br onClick={()=>onSelect(props.id)} b="grey500">
                <Box w100 pv={3}>
                    <Text fs={16} text={props.name}/>
                </Box>
                {props.size!=null && props.size!=0 && <Box w100 pv={2}>
                    <Text fs={10} mr={5} text="Size:"/>
                    <Text fs={10} mr={5} bold format="N" text={props.size}/>
                    <Text fs={10} mr={5} text="ECGS"/>
                </Box>}

                {/* <Box w100 top h={40} ph={20} pv={10}>
                    <ValueBox ll label="Size" value={props.size} format="N"/>
                </Box> */}
            </Box>
        )
    }

    const card_item = () =>
        <Box column w={350} m={20} br pad={20}>
            <Box w100 h={50}><Icon size={50} center icon={props.icon}/></Box>
            <Box w100 h={55} mv={10}><Text fs={20} bold w100 center text={props.title}></Text></Box>
            <Text fs={18} mt={0} center lineHeight={1.5} text={props.text}></Text>
        </Box>

    const card_menu = () => {
        const bg = props.bg || 'white'
        const b = '1px solid var(--grey100)'
        let style = {}
        if(bg==='white') {style.borderTop=b; style.borderBottom=b}
        return(
            <Box column w={240} m={20} white shadow br onClick={()=>onSelect(props.id)}>
                <Box w100 top h={60} pad={20}>
                    <Image id={props.id} h={30} center iurl={get_url(props.content,props.lurl)} mr={20} onClick={props.onSelect}/>
                </Box>
                <Box w100 top h={100} pad={20} bg={bg} style={style}>
                    <Text fs={16} center text={props.name}/>
                </Box>
                <Box w100 top h={40} ph={20} pv={10}>
                    <ValueBox ll label="Size" value={props.size} format="N"/>
                    <Icon right icon="link" onClick={onLink}/>
                </Box>
            </Box>
        )
    }

    const card_image = () => {
        return(
            <Box column w={260} h={250} m={20} white br style={{overflow:'hidden'}} href={props.url} onClick={()=>onSelect(props.id)}>
                <Box w100 top h={50} pad={10}>
                    <Text fs={20} center text={props.title}/>
                </Box>
                <Box w100 top h={60}>
                    <Image h={200} center iurl={get_url(props.content,props.img)} mr={20} onClick={props.onSelect}/>
                </Box>
            </Box>
        )
    }

    const card_topic = () =>
        <Box column w={400} h={300} m={20} br pad={20} bg={props.bg}>
            {props.icon && <Icon size={60} center icon={props.icon}/>}
            <Box w100 top h={50} pad={10}><Text fs={20} w100 center text={props.title}></Text></Box>
            <Text fs={16} text={props.text}></Text>
        </Box>

    const card_documents = (props, cls) => {
        return(
            <Box column w={320} mv={20} mh={20} white shadow br>
                <Box w100 top h={50} pad={20} url><Text fs={16} center text={props.title}></Text></Box>
                <Box w100 top pad={20}>
                    {props.items.map((e,i)=>
                        <Text fs={16} w100 mv={5} bb="var(--grey100)" href={`${props.content.baseurl}/documents/${e.url}`} text={e.text}></Text>)}
                </Box>
            </Box>
        )
    }

    const card_profile = () => {
        const w = props.mobile ? '100%':'50%'
        const w1 = props.mobile ? '100%':'auto'
        const h1 = props.mobile ? 'auto':400
        const align = props.mobile ? 'center':'left'
        return(
            <Box w100 mv={40}>
                <Box left w={w}>
                    <Image w={w1} h={h1} left center centerv iurl={get_url(props.content,props.img)}/>
                </Box>
                <Box column w={w} ph={20}>
                    <Text fs={20} w100 center mt={20} mb={10} text={props.name}/>
                    <Text fs={20} w100 center mb={20} text={props.title}/>
                    {props.textlines.map(e=><Text fs={16} w100 mb={10} align={align} text={e}/>)}
                </Box>
            </Box>
        )
    }

    const card_error = () =>
        <Box cls="_Error" w={400} h={140} b br dashed pad={20}>
            <Box w={100} h100 mr={10}><Icon icon="error" size={80}/></Box>
            <Box wz={110} h={100} column>
                <Box w100 mt={5}><Text fs={16} w100 error_title text={props.error||'ERROR'}/></Box>
                <Box w100><Text fs={16} w100 error text={props.error_msg||''}/></Box>
            </Box>
        </Box>

    const type = get_prop(props, 'type', TYPES, 'Normal', 'Capitalize')
    const cls = cls_get('Card', [props.type, props.variant])
    switch(type) {
        case 'Msg': return card_msg()
        case 'Card1': return card_1()
        case 'Card2': return card_2()

        case 'Item': return card_item(props)
        case 'Box': return card_box(props, cls)
        case 'Menu': return card_menu(props, cls)
        case 'Image': return card_image(props, cls)
        case 'Topic': return card_topic(props)
        case 'Documents': return card_documents(props, cls)
        case 'Profile': return card_profile(props, cls)
        case 'Error': return card_error()
        default: return card_1()
    }
}
