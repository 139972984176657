import { Link } from 'react-router-dom'
import { set_palette, get_c } from './palette'

/* ***************************************************************************************************************************************
    Basic functions
****************************************************************************************************************************************** */

export const is_string = (s) => s!=null && (typeof s==='string' || s instanceof String)
export const is_obj = (e) => e!==null && typeof e==='object'
export const obj_empty = (o) => o ? (Object.keys(o).length===0 && o.constructor===Object) : true
export const is_array = (e) => e!==null && e!==undefined && Array.isArray(e)
export const is_2d_array = (e) => is_array(e) && e.length && is_array(e[0])

export const oget = (o, fs, df=null) => {
    if(!is_obj(o)||!fs) return null
    for(let i=0; i<fs.length; i++) {
        if(o===null||o===undefined) return df
        if(o[fs[i]]===undefined) return df
        o = o[fs[i]]
    }
    return o
}

export const uuidv4 = () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))

/* ***************************************************************************************************************************************
    CSS functions
****************************************************************************************************************************************** */

export const css_flex = (wrap=true) => {
    return {position:'relative', display:'flex', flexDirection:'row', flexWrap: wrap?'wrap':'nowrap',
            alignContent:'flexStart', alignItems:'flexStart'}
}

export const set_width = (props, style, w0='auto') => {
    if(props.minw) style['minWidth']=props.minw
    if(props.maxw) style['maxWidth']=props.maxw
    if(props.w) {style.width=props.w; return}
    if(props.wz!=null) {style.width=`calc(100% - ${props.wz}px)`;return}
    if(props.w100) {style.width='100%'; style.marginRight=0; style.marginLeft=0; return}
    const ws = ['w90','w80','w70','w60','w50','w40','w33','w30','w25','w20','w10']
    for(let i=0; i<ws.length; i++)
        if(props[ws[i]]) {style.width=`${ws[i].slice(1)}%`; return}
    return w0
}

export const set_height = (props, style, h0='auto') => {
    if(props.minh) style['minHeight']=props.minh
    if(props.maxh) style['maxHeight']=props.maxh
    if(props.h) {style.height=props.h; return}
    if(props.hz) {style.height=`calc(100% - ${props.hz}px)`;  style.marginLeft=0; return}
    if(props.h100) {style.height='100%'; style.marginTop=0; style.marginBottom=0; return}
    const hs = ['h90','h80','h70','h60','h50','h40','h33','h30','h25','h20','h10']
    for(let i=0; i<hs.length; i++)
        if(props[hs[i]]) {style.hidth=`${hs[i].slice(1)}%`; return}
    return h0
}

export const set_absolute = (props, style) => {
    const position = props.fixed ? 'fixed' : 'absolute'
    if(props.topleft) {style.position=position; style.top=10; style.left=10; return}
    if(props.topright) {style.position=position; style.top=10; style.right=10; return}
    if(props.bottomleft) {style.position=position; style.bottom=10; style.left=10; return}
    if(props.bottomright) {style.position=position; style.bottom=10; style.right=10; return}
    if(props.left!=null) {style.position=position; style.left=props.left}
    if(props.right!=null) {style.position=position; style.right=props.right}
    if(props.top!=null) {style.position=position; style.top=props.top}
    if(props.bottom!=null) {style.position=position; style.bottom=props.bottom}
}

export const set_align = (align, style) => {
    const al = is_string(align) ? align.toLowerCase() : 'left'
    if(al==='left') {style.marginLeft=0; style.marginRight='auto'; return}
    if(al==='center') {style.marginLeft='auto'; style.marginRight='auto'; return}
    if(al==='right') {style.marginLeft='auto'; style.marginRight=0; return}
}

export const set_margin = (props, style) => {
    if(style.position==='absolute') return
    if(props.m!==undefined) {style.margin=props.m; return}
    const m0 = 10
    let ml = props.ml===true ? m0 : (props.ml||0)
    let mr = props.mr===true ? m0 : (props.mr||0)
    let mt = props.mt===true ? m0 : (props.mt||0)
    let mb = props.mb===true ? m0 : (props.mb||0)
    let mh = props.mh===true ? m0 : (props.mh||0)
    let mv = props.mv===true ? m0 : (props.mv||0)
    if(mh) {ml=mh; mr=mh}
    if(mv) {mt=mv; mb=mv}
    if(ml) style.marginLeft=ml
    if(mr) style.marginRight=mr
    if(mt) style.marginTop=mt
    if(mb) style.marginBottom=mb
    if(props.alignleft) {style.marginLeft=ml; style.marginRight='auto'}
    if(props.alignright) {style.marginLeft='auto'; style.marginRight=mr}
    if(props.center) {style.marginLeft='auto'; style.marginRight='auto'}
    if(props.align) set_align(props.align, style)
    if(props.centerv) {style.marginTop='auto'; style.marginBottom='auto'}
    if(props.aligntop) {style.marginTop=mt; style.marginBottom='auto'}
    if(props.alignbottom) {style.marginTop='auto'; style.marginBottom=mb}
}

export const set_padding = (props, style) => {
    if(props.pad) {style['padding']=props.pad; return}
    if(props.panel) style.padding = '20px 20px'
    const p0 = 10
    if(props.ph) {style['paddingLeft']=props.ph===true?p0:props.ph; style['paddingRight']=props.ph===true?p0:props.ph}
    if(props.pr) style['paddingRight']=props.pr===true?p0:props.pr
    if(props.pl) style['paddingLeft']=props.pl===true?p0:props.pl
    if(props.pv) {style['paddingTop']=props.pv===true?p0:props.pv; style['paddingBottom']=props.pv===true?p0:props.pv}
    if(props.pt) style['paddingTop']=props.pt===true?p0:props.pt
    if(props.pb) style['paddingBottom']=props.pb===true?p0:props.pb
    if(props.nopad) style.padding = '0px'
}

export const set_border_for_group = (style, b, color='n100') => {
    const [H,i,n] = b
    const str = `1px solid ${get_color(color)}`
    if(H) {
        style.borderTop = str
        style.borderBottom = str
        style.borderLeft = str
        if(i===0) style.borderLeft = str
        if(i===n-1) style.borderRight = str
        return
    }
    return str
}

export const set_border = (props, style, color='var(--grey500)') => {
    if(props.border) {style.border = props.border; return}
    const line = props.dashed ? 'dashed':'solid'
    if(Array.isArray(props.b)) {set_border_for_group(style, props.b); return}
    if(typeof props.b==='string') {const color = get_color(props.b); style.border=`1px ${line} ${color}`; return}
    // bright instead of br not to confuse with border radius
    // if(props.br!=null && !Number.isInteger(props.br)) {style.borderRight=`1px ${line} ${get_color(props.br)}`}
    if(props.bt) {style.borderTop=`1px ${line} ${get_color(props.bt)}`}
    if(props.bb) {style.borderBottom=`1px ${line} ${get_color(props.bb)}`}
    if(props.bl) {style.borderLeft=`1px ${line} ${get_color(props.bl)}`}
    if(props.bright) {style.borderRight=`1px ${line} ${get_color(props.bright)}`}
    if(props.bv) {style.borderLeft=`1px ${line} ${get_color(props.bv)}`; style.borderRight=`1px ${line} ${get_color(props.bv)}`}
    if(props.bh) {style.borderTop=`1px ${line} ${get_color(props.bh)}`; style.borderBottom=`1px ${line} ${get_color(props.bh)}`}
    if(props.menu && props.vertical && !props.last) style.borderBottom=0
    if(props.menu && !props.vertical && !props.last) style.borderRight=0
}

const COLORS = [
    'white', 'black', 'n20', 'n40', 'n60', 'n80', 'n100', 'n120', 'n140', 'n160', 
    'green', 'yellow', 'red', 'orange', 'blue', 'grey', 'red',
    'green2', 'blue2', // intense
]

const props_color = (props) => {
    for(let i=0; i<COLORS.length; i++)
        if(props[COLORS[i]]) return get_c(COLORS[i])
    return null
}

export const get_color = (color, c0='#333333') => {
    if(!color || !color.length) return c0
    if(color[0]==='#') return color
    if(color.substring(0,5).toLowerCase()==='hsla(') return color
    if(color.substring(0,5).toLowerCase()==='rgba(') return color
    if(color.substring(0,3).toLowerCase()==='var') return color
    return get_c(color)
}

export const get_background = (props, def='transparent') => {
    if(props.dark) return get_c('dark')
    if(props.white) return '#FFFFFF'
    if(props.transparent) return 'transparent'
    let bg = props_color(props)
    if(bg) return bg
    return get_color(props.bg, def=def)    
}

export const set_background = (props, style, def='transparent') => {
    style.background = get_background(props, def)
}

export const set_color = (props, style) => {
    style.color = get_color(props.color)
}

/* To review */
export const get_cls = (options, props=null, attrs=null) => {
    if(!options) return ''
    let cls = options.map(e=>e?capitalize(e):null).filter(e=>e)
    if(!props) return cls.join(' ')
    if(attrs) attrs.map(e=>props[e]?cls.push(capitalize(e)):null)
    cls = cls.join(' ')
    if(props.cls) cls+=` ${props.cls}`
    return cls
}

/* To review */
export const cls_get_many = (cls0, props, options, cls1=null) => {
    const props1 = Object.keys(props)
    // const props2 = Object.keys(props).map(e=>e.toUpperCase())
    const options2 = options.map(e=>e.toUpperCase())
    let cls = cls0 || ''
    if(cls1) cls += ` ${cls1}`
    props1.forEach((c, _) => {
        const idx = options2.indexOf(c.toUpperCase())
        if(idx===-1) return
        if(props[c]!==false) cls+=` ${capitalize(c)}`
    })
    return cls
}

/* ***************************************************************************************************************************************
    React functions
****************************************************************************************************************************************** */

export const SIZES = ['XS','SM','MD','LG','XL']
export const ALIGNS = ['Left','Center','Right']
export const FORMATS = ['S','N','D','C','C2','P']  // -> refer to fields.js

const BOX_PROPS = ['w', 'h', 'ml', 'mr', 'mt', 'mb']

export const get_pass_props = (props, attrs) => {
    const props1 = {}
    if(!is_obj(props) || !is_array(attrs)) return props1
    attrs.map(e=>props[e]!==undefined ? props1[e]=props[e] : null)
    return props1
}

export const get_box_props = (props) => get_pass_props(props, BOX_PROPS)

export const get_pass_int_props = (props, attrs) => {
    const props1 = {}
    if(!is_obj(props) || !is_array(attrs))
        return props1
    attrs.map(e=>is_number(props[e]) ? props1[e]=props[e] : null)
    return props1
}

export const get_prop = (props, prop, options=[], def=null, trfm=null) => {
    if(!props) return null
    if(props[prop] && options.includes(props[prop])) return transform_text(trfm,props[prop])
    const options2 = options.map(e=>e.toUpperCase())
    let v = props[prop]
    if(is_string(v)) {
        v = v.toUpperCase()
        if(options2.findIndex(e=>e===v)!==-1) return transform_text(trfm,v)
    }
    props = Object.keys(props)
    for(let i=0; i<props.length; i++) {
        const match = options2.find(e=>e===props[i].toUpperCase())
        if(match) return transform_text(trfm, match)
    }
    if(def) return transform_text(trfm, def)
    return null
}

export const get_size = (props, sizes=null) => get_prop(props, 'size', sizes||['XS','SM','MD','LG','XL'], 'MD', 'Uppercase')

export const get_props_cls = (props, cls0) => {
    if(!props.cls) return cls0
    return ` ${cls0} ${props.cls}`
}

export const get_lpos = (props) => {
    if(props.lpos) {
        const lpos = props.lpos.toUpperCase()
        if(['L','R','T','B','N'].includes(lpos)) return lpos
        return 'T'
    }
    if(props.ll) return 'L'
    if(props.lr) return 'R'
    if(props.lt) return 'T'
    if(props.lb) return 'B'
    if(props.l0) return 'N'
    if(props.label==null) return 'T'
    return 'T'
}

export const get_one_attr = (props, options=[], default_=null, transform=null) => {
    props = Object.keys(props)
    for(let i=0; i<props.length; i++) {
        const match = options.find(e=>e===props[i])
        if(match) return transform_text(transform, match)
    }
    return transform_text(transform, default_)
}

export const get_attr = (props, attr, default_=null, transform=null) => {
    const v = props[attr]
    if(v) return transform_text(transform, v)
    if(attr in props) return true
    if(default_!==null) return transform_text(transform, default_)
    return null
}

export const prop_get = (props, options, _default) => {
    const props2 = Object.keys(props)
    for(let i=0; i<props2.length; i++)
        if(options.indexOf(props2[i])!=-1) return props2[i]
    return _default || null
}

export const cls_get = (cls0, cls_optional) => {
    let cls = cls0
    cls_optional.forEach((c, _) => cls += (c && c!=='undefined') ? ` ${c}` : '')
    return cls
}

/* ***************************************************************************************************************************************
    Utils
****************************************************************************************************************************************** */


export const compose_url = (url, o) => {
    const o1 = {}
    for(const key in o)
      if(!['id','parent'].includes(key)) o1[key]=o[key]
    const name = o.name?o.name.toLowerCase():'noname'
    return `${url}/${name}?${encodeURI(JSON.stringify(o1))}`
}

export const deep_copy_array_2d = (a) => {
    if(!a) return null
    const a2 = []
    for(let i=0; i<a.length; i++)
      a2.push([...a[i]])
    return a2
}

export function bin_search(a, e, comp_f) {
    let m = 0
    let n = a.length-1
    while (m <= n) {
        let k = (n+m) >> 1
        let cmp = comp_f(e, a[k])
        if (cmp > 0) {m = k+1}
        else if(cmp < 0) {n = k-1}
        else {return k}
    }
    return ~m
}

export const getDevice = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) return 'tablet'
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) return 'mobile'
    return 'desktop'
}

export const sleep = async (ms) => await new Promise(r=>setTimeout(r,ms))

export const bound = (n,a,b) => {
    if(n==null) return a
    if(isNaN(n)) return a
    if(n<a) return a
    if(n>b) return b
    return n
}

const find_node = (node, k, v, n=0) => {
    if(!Array.isArray(node)) {
      if(node[k]===v) return n
      else return null
    }
    for(const i=0; i<=node.length; i++) {
      const r = find_node(node[i],k,v,n)
      if(r!==null) return r
      if(node[i].children) return find_node(node[i].children,k,v,n+1)
    }
  }

export const get_dates = (meta) => {
    if(!meta) return [null,null]
    const ds = oget(meta,['etl','iso'])
    if(!ds) return [null,null]
    let d2 = new Date(ds)
    let d1 = new Date(d2)
    d1.setDate(d1.getDate()-30)
    return [d1.toISOString().split('T')[0],d2.toISOString().split('T')[0]]
}

export const isNumeric = (s) => {
    if (!s || typeof s!='string') return false
    return !isNaN(s) && !isNaN(parseFloat(s))
  }

export const aadd = (a,n) => {
    if(!a) return [n]
    const i = a.indexOf(n)
    if(i!==-1) return a
    const a2 = [...a]
    a2.push(n)
    return a2
}

export const arm = (a,n) => {
    if(!a) return []
    const i = a.indexOf(n)
    if(i===-1) return a
    const a2 = [...a]
    a2.splice(i,1)
    return a2
}

export const is_sub_link = (e) => {
    if(!e.path) return false
    const path1 = e.path.split('/')
    if(path1.length>2) return true
    return false
}

export const menu_find = (menu, path) => {
    if(!path || path==='/app') return 0
    const i = menu.findIndex(e=>e.path===path)
    return i===-1?0:i
}

export const push2 = (v, o, f) => v.find(e=>f(e)==f(o))===undefined ? (v.push(o)!==-1) : false

const containsOnlyNumbers = (s) => /^-?\d{2}(\.\d+)?$/.test(s)
// export const is_integer = (s) => /^(\.\d+)$/.test(s)
export const is_integer = (e) => Number.isInteger(e)
export const is_number = (e) => {
    if(e==null || e===true || e===false) return false
    if(isNaN(e)) return false
    if(typeof e=='number') return true
    if(typeof e!='string') return false
    return containsOnlyNumbers(e)
}


export const capitalize = (s) => s ? String(s).toLowerCase().replace(/^\w/, c => c.toUpperCase()):''
export const s_eq_no_case = (s1,s2) => (!s1||!s2) ? false : s1.toUpperCase()===s2.toUpperCase()

const transform_text = (transform, s) => {
    if(!s||!is_string(s)) return ''
    if(transform==='Capitalize') return capitalize(s)
    if(transform==='Uppercase') return s.toUpperCase()
    if(transform==='Lowercase') return s.toLowerCase()
    return s.toLowerCase()
}

export const get_org = (props) => oget(props, ['meta','user_info','org'])

export const get_first = (o,f) => (o && o[f] && o[f].length) ? o[f][0] : null

export const update_filters = (fs, col, e) => {
    let fs2
    if(e) {
        const f = fs.find(e=>e.col===col)
        if(!f) {fs2=fs.map(e=>({...e})); fs2.push({col:col, val:e.id})}
        else {fs2=fs.filter(e=>e.col!==col); fs2.push({col:col, val:e.id})}
    } else {
        fs2 = fs.filter(e=>e.col!==col) 
    }
    return fs2
}


const domain_from_url = (url) => {
    let result, match
    if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
        result = match[1]
        if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
            result = match[1]
        }
    }
    return result
}

// export const get_domain = () => 'dl2050.com'
// const get_domain = () => domain_from_url(window.location.href)

const S3 = "http://dlogic-websites.s3-website-eu-west-1.amazonaws.com"
// export const urlf = (u, dir='images', domain=get_domain()) => (u && u!='undefined') ? `${S3}/${domain}/${dir}/${u}` :  null
// export const urlf = (proj, url) => (proj && url) ? `https://storage.googleapis.com/dl2050-www/${proj}/${url}` : null
export const urlf = (baseurl, url) => (baseurl && url) ? `${baseurl}/${url}` : null


// export const get_url = (app, url) => `https://storage.googleapis.com/dl2050-www/${app}/${url}`
export const get_url = (content, url) => content ? `${content.baseurl}/${url}`:null

const path_extract = (path) => {
    if(!path) return null
    const r = /^\/reports\/(.*)$/g.exec(path)
    if(!r||r.length!==2) return null
    return r[1]
}

export const get_drop_top = (size, lpos) => {
    if(lpos!=='T') {
        if(size==='LG') return 38
        if(size==='SM') return 26
        return 26
    }
    if(size==='LG') return 60
    if(size==='SM') return 48
    return 48
}

export const mkWeeks = (year) => {
    return [{label: 'Maio 10' }]
}

// String.prototype.paddingLeft = function(pad) { return String(pad+this).slice(-pad.length) }

export const sec_to_time = (s) => {
    if(!s) return ''
    const h = Math.floor(s/3600)
    let m = Math.floor((s-h*3600)/60)
    if(m<10) m = `0${m}`
    return `${h}h ${m}m`
}

export const addSecondsToDateTime = (dateTimeStr, seconds) => {
    if(!dateTimeStr) return null
    const date = new Date(dateTimeStr)
    if(isNaN(date)) return null
    date.setSeconds(date.getSeconds() + seconds)
    return date.toISOString()
}
