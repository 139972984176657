import React, { useState, useEffect } from 'react'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Image from './Image'
import { oget, is_obj, get_background, get_size, get_lpos } from '../js/util'

// To be used on: Dropdown, Tree, Switch, Date, DateRange, Field, Ranger, Radio, Value

/*
    <Value
        lt | ll | lb | lr | l0 | lpos={'T'|'B'|'L'|'R'}
        md | sm | lg | size={'md'|'sm'|'lg'}
        white dark border
        bg
        w
        mb mt ml mr m
        innactive
        msg | error
        onOpenClose
        onClear
        onRemove
        onEdit
        onOk
        onCancel
        isedit
        check
    />
*/

export default (props) => {
    const [hover, setHover] = useState(false)

    useEffect(() => {
    }, [props.error, props.check])

    const WS = {'SM': 120, 'MD': 170, 'LG': 300}
    const MWS = {'SM': 120, 'MD': 170, 'LG': 300}
    const HS = {'SM': 20, 'MD': 24, 'LG': 36}
    const LH = {'SM': 15, 'MD': 15, 'LG': 15}
    const FS = {'SM': 10, 'MD': 12, 'LG': 16}
    const LFS = {'SM': 10, 'MD': 12, 'LG': 14}

    const onMouseOver = (ev) => setHover(true)
    const onMouseOut = (ev) => setHover(false)
    const onClear = () => props.onClear ? props.onClear():null

    const onClick = () => {
        if(props.onOpenClose) {props.onOpenClose(); return}
        if(props.onClick) props.onClick()
    }

    const render_label = () => {
        const w_ = V ? '100%' : props.w ||'auto'
        const ml = (lpos==='T' || lpos==='B') ? 5 : (lpos==='R' ? 10:0)
        const mr = lpos==='L' ? 10:0
        const mt = lpos==='B' ? 5:0
        const mb = lpos==='T' ? 5:0
        const centerv = (lpos==='L' || lpos==='R')
        return(
            <Box cls="_LabelBox" w={w_} h={LH[size]} centerv={centerv} ml={ml} mr={mr} mt={mt} mb={mb}>
                <Text fs={LFS[size]} color="pri160" text={props.label}/>
            </Box>
        )
    }

    const render_value = () => {
        if(props.content) return props.content
        const text = is_obj(props.value) ? props.value.name : props.value
        const icon = oget(props.value,['icon'])
        const img = oget(props.value,['img'])
        if(!text && props.placeholder) return <Text fs={FS[size]} placeholder color="n80" text={props.placeholder}/>
        return(
            <Box>
                {icon && <Icon icon={icon}/>}
                {img && <Image h={12} mr={5} centerv icon src={img}/>}
                <Text fs={FS[size]} value text={text} color="pri160" format={props.format}/>
            </Box>
        )
    }

    const render_valuebox = () => {
        let w_ = (V||props.w100) ? '100%' : props.w
        if(props.w100) w_ = "calc('100%' - 100)"
        const h = HS[size]
        const wi = get_wi(props)
        const show_clear = props.onClear && (props.value!=null && props.value!='')
        const b = (props.highlight && (props.value || props.open)) ? 'high120' : null
        const shadow = props.open ? '0px 0px 4px #043893' : null
        return(
            <Box cls="_ContentBox" w={w_} minw={minw} h={h} br={6} border={border} ph={5} pv={2} bg={bg} b={b} shadow={shadow} hidden
                 onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <Box cls="_Value" w={`calc(100% - ${wi}px)`} ph={10} centerv>
                    {render_value()}
                </Box>
                <Box w={wi} h100>
                    {show_clear &&<Box w={20} h100 alignright>
                        <Icon centerv center size={12} color="n100" icon="selectclear" onClick={onClear}/>
                    </Box>}
                    {props.onOpenClose && <Box w={20} h100 alignright>
                        <Icon mr={0} centerv center size={16} color="n100" icon='down' onClick={props.onOpenClose}/>
                    </Box>}
                </Box>
            </Box>
        )
    }

    const render_remove = () => {
        return(
            <Box cls="_Remove" w={20} h={20}>
                <Icon box icon="close_red" size={16} onClick={props.onRemove}/>
            </Box>
        )
    }

    const render_childrenbox = () => {
        return(
            <Box cls="_ChildrenBox" w100>
                {props.children}
            </Box>
        )
    }

    const render_row_1 = () => {
        if(props.compact || props.l0) return null
        if(lpos!=='T') return null
        const h = 15
        const mb = size==='lg' ? 8:2
        return <Box cls="_Value_Row_1" w100 mb={mb}>{render_label()}</Box>
    }

    const render_row_2 = () => {
        return(
            <Box cls="_Value_Row_2" w100 minw={minw}>
                {lpos==='L' && render_label()}
                {render_valuebox()}
                {lpos==='R' && render_label()}
            </Box>
        )
    }

    const render_row_3 = () => {
        if(lpos!=='B') return null
        return(
            <Box cls="_Value_Row_3" w100>
                {render_label()}
            </Box>
        )
    }

    const render_row_4 = () => {
        if(props.msg===undefined && props.error===undefined) return null
        const h = 20
        const icon = props.error ? 'x':null
        const color = props.error ? 'grey800':'grey800'
        const sz = 14
        return(
            <Box cls="_Value_Row_4" w100 h={LH}>
                <Box cls="_Error" h={h} mt={1} ph={5}>
                    {icon && <Icon size={8} mt={6} mr={8} color="red" icon={icon}/>}
                    <Text centerv fs={sz} color={color} text={props.error}/>
                </Box>
            </Box>
        )
    }

    const size = get_size(props, ['SM','MD','LG'])
    const lpos = get_lpos(props)
    const bg = get_bg(props)
    const V = lpos==='T' || lpos==='B' || lpos==='N'
    const w0 = get_w0(props, WS, size, V)
    const mr = props.w100 ? 0 : (props.mr!=null ? props.mr:0)
    const mb = props.mb||0
    const w1 = props.onRemove ? 'calc(100% - 20px':'100%'
    const border = get_border(props)
    let h = lpos==='N' ? HS[size] : 'auto'
    if(props.compact) h = 20
    const minw = !props.w && (lpos==='L' || lpos==='R') ? MWS[size] : null
    const minw0 = props.compact ? w0 : minw+100
    
    return(
        <Box column cls="_Value" w={w0} minw={minw0} h={h} mr={mr} mb={mb} mt={props.mt} onClick={onClick}>
            <Box cls="_Value_Content" w={w1}>
                {render_row_1()}
                {render_row_2()}
                {render_row_3()}
                {render_row_4()}
                {props.children}
            </Box>
            {props.onRemove && <Box cls="_Value_Remove" w={20} h100 mt={lpos==='T'?LH:0}>{render_remove()}</Box>}
        </Box>
    )
}

const get_bg = (props, def='white') => {
    if(props.white) return 'white'
    if(props.grey) return 'grey2'
    if(props.dark) return 'transparent'
    if(props.innactive && !props.dark) return 'var(--grey300)'
    if(props.innactive && props.dark) return 'var(--grey800)'
    if(props.bg) return get_background(props)
    return def
}

const get_w0 = (props, WS, size, V) => {
    if(props.compact) return 70
    if(props.w100) return '100%'
    if(props.w) return props.w
    if(V) return WS[size]
    return 'auto'
}

const get_wi = (props) => {
    let n = 0
    if(props.onOpenClose) n+=1
    if(props.onClear && (!props.onEdit||props.onOk||props.onCancel)) n+=1
    return n*20
}

const get_border = (props) => {
    if(!props.border)
        return null
    if(props.error)
        return '1.5px solid var(--red)'
    if(!props.check)
        return '1.5px solid var(--grey3)'
    if(props.check)
        return '1.5px solid var(--green)'
    return null
}