import React, { useState, useRef, useEffect } from 'react'
import Box from './Box'
import Text from './Text'
import { oget, get_prop, urlf, getDevice } from '../js/util'

const MONTHS = {'01':'Jan','02':'Fev','03':'Mar','04':'Apr','05':'Mai','06':'Jun','07':'Jul','08':'Ago','09':'Sep','10':'Oct','11':'Nov','12':'Dez'}

export default (props) => {
    const style = {}
    if(props.topright) {style.position='absolute'; style.top=0; style.right=0; style.margin='20px 20px 20px 20px'}
    const v = props.value
    if(!v) return null
    const y = v.substring(13,15)
    const m = MONTHS[v.substring(5,7)]
    const d = v.substring(8,10)
    const w = v.substring(0,4)
    const h = v.substring(11,13)
    const min = v.substring(14,16)
    const color = props.dark ? 'white':'black'
    return(
        <Box center centerv w={95} pv={3}>
            <Box w100 h={12} centerv>
                <Text alignright md mr={5} color="n80" text="Last update"/>
            </Box>
            <Box w100>
                <Text alignright fs={12} caps color="n80" mt={6} ml={2} text={`${m} ${d}`}/>
                <Text alignright fs={16} bold mr={5} mt={2} color="orange" text={`${h}:${min}`}/>
            </Box>
        </Box>
    )
}
