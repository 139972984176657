import * as d3 from 'd3'
import { formater } from '../../js/field.js'
// https://www.viget.com/articles/add-colors-to-your-palette-with-color-mixing/

// https://htmlcolorcodes.com/color-chart/


// export const COLORS_12 = ['#4293B6', '#357695', '#285A75', '#1A3B52', '#A6B674', '#6DA493',
//                           '#E17237', '#E28E43', '#E2AB4F',  '#E5C75C', '#C95630', '#B13A29']
// export const COLORS_6 = ['#4293B6', '#357695', '#A6B674', '#6DA493', '#E17237', '#E28E43']
// export const COLORS_3 = ['#4293B6', '#357695', '#A6B674']
// export const COLOR = '#4293B6'

// export const COLORS_12 = ['#357695', '#4293B6', '#647b87', '#6DA493',
//                           '#E17237', '#E28E43', '#E2AB4F',  '#E5C75C', '#C95630', '#B13A29']

export const COLORS_16 = ['var(cyan700)', 'var(--deeporange700)', 'var(teal700)', 'var(--red700)',
                    'var(cyan700)', 'var(--deeporange700)', 'var(teal700)', 'var(--red700)',
                    'var(cyan700)', 'var(--deeporange700)', 'var(teal700)', 'var(--red700)',
                    'var(cyan700)', 'var(--deeporange700)', 'var(teal700)', 'var(--red700)']

export const COLORS_12 = ['#71A3B7', '#4293B6', '#647b87', '#6DA493',
                          '#E17237', '#E28E43', '#E2AB4F',  '#E5C75C', '#C95630', '#B13A29']
                  
export const COLORS_6 = COLORS_12
export const COLORS_3 = ['var(--cyan800)', 'var(--green700)', 'var(--orange700)']
export const COLOR = '#357695'

// export const COLORS_6 = COLORS_12.filter((e, i) => {return i%2===0})
// export const COLORS_3 = COLORS_12.filter((e, i) => {return i%4===0})
// export const COLOR = COLORS_12[0]

export const EU = () => d3.formatLocale({
  "decimal": ",",
  "thousands": ".",
  "grouping": [3],
  "currency": ["$", ""],
  "dateTime": "%a %b %e %X %Y",
  "date": "%m/%d/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
})

const getv = (opt,d) => {
  
}

const get_triangle = (pos) => {
  switch(pos) {
    case 'Top': return '<svg width="30" height="6" fill="var(--bw-500)"><polygon points="10,0 20,0 15,6"/><line stroke="var(--bw-500)" strokeWidth="2" x1="0" y1="0" x2="30" y2="0"/></svg>'
    case 'Bottom': return '<svg width="30" height="6" fill="var(--bw-500)"><polygon points="10,6 20,6 15,0"/><line stroke="var(--bw-500)" strokeWidth="2" x1="0" y1="10" x2="30" y2="10"/></svg>'
    case 'Right': return '<svg width="6" height="20" fill="var(--bw-500)"><polygon points="6,5 6,15 0,10"/><line stroke="var(--bw-500)" strokeWidth="2" x1="6" y1="0" x2="6" y2="20"/></svg>'
  }
  return null
}

export const get_id = () => '_' + Math.random().toString(36).substr(2, 9)

export const find_data = (data,ycols,idx,d) => {
  for(let v=0,i=0; i<ycols.length; i++) {
    if(i>1) v += data[idx][ycols[i]]-data[idx][ycols[i-1]]
    else v = data[idx][ycols[i]]
    if(v==d[1]-d[0]) return {
      'x': data[idx].x,
      'col': ycols[i],
      'value': data[idx][ycols[i]]
    }
  }
  return null
}

const round2 = (x,n) => Math.floor(x/Math.pow(10,n))*Math.pow(10,n)
const round2up = (x,n) => {
    const x2 = round2(x,n)
    if(x2==x) return x
    return x2+Math.pow(10,n)
}
export const get_range = (x1,x2) => {
    const l1 = Math.floor(Math.log10(x1))
    const l2 = Math.floor(Math.log10(x2))
    return [0,round2up(x2,l2-1)]
}

export const add_tooltip = (node, id, pos, x, y, t1, t2='') => {
  const tr = get_triangle(pos)
  let div = d3.select(node).append('div')
    .attr('id', id)
    .attr('class',`TooltipChart`)
    .style('left', x+'px')
    .style('top', y+'px')
  if(tr) div.append('div').attr('class',`Pointer${pos}`).html(tr)
  div.append('p').text(t1)
}

export const remove_item = (id) => d3.select(`#${id}`).remove()

export const add_pointer = (svg, id, x, y, color='var(--orange)') =>
  svg.append('path')
    .attr('id', id)
    .attr('d', `M ${x} ${y} l 0 16 l 8 -8 z`)
    .attr('fill', color)

export const get_d2_idx = (stacked, d1, v) => {
  for(let d2=0; d2<stacked.length; d2++)
      if(stacked[d2][d1][1]>=v) return d2
}

export const y_to_xy_dict = (v, x0, xdelta, xcol, ycol) => {
  xcol = xcol || 'x'
  ycol = ycol || 'y'
  let res = []
  for(let i=0; i<v.length; i++) {
    let e = {}
    let x = x0+i*xdelta
    e[xcol] = x
    e[ycol] = v[i]
    res.push(e)
  }
  return res
}


export const draw_icon_legend = (svg, x, y, colors) => {
  let d1 = "M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm4.021-7.962c-.152-.135-.388-.135-.523.017-.792.792-1.804 1.214-2.815 1.214-1.484 0-2.9-.91-3.71-2.411h3.845c.219 0 .387-.169.387-.388v-1.315a.382.382 0 0 0-.387-.388H8.299c-.033-.253-.033-.506-.033-.742s.016-.489.05-.758h4.502c.219 0 .387-.169.387-.388V9.564a.382.382 0 0 0-.387-.388H8.974c.809-1.484 2.208-2.411 3.709-2.411 1.028 0 2.023.438 2.815 1.23.152.153.388.153.523.018l1.467-1.35c.084-.067.118-.168.135-.286a.458.458 0 0 0-.101-.286C16.19 4.74 14.487 4 12.7 4 9.75 4 7.068 6.074 6.006 9.16H4.388A.382.382 0 0 0 4 9.546v1.315c0 .22.169.388.388.388h1.18c-.017.27-.034.522-.034.758s.017.49.034.742h-1.18a.382.382 0 0 0-.388.388v1.315c0 .22.169.388.388.388h1.618C7.068 17.926 9.75 20 12.7 20c1.787 0 3.507-.742 4.822-2.09a.407.407 0 0 0 .118-.287.416.416 0 0 0-.135-.287l-1.484-1.298z"
  let d2 = "M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm4.794-16.757l.742-3.843h-2.882l-.764 3.843h-2.058l.764-3.843H9.684l-.741 3.843H5.872v2.75h2.522l-.753 3.834H5.4v2.75h1.714L6.327 20.6h2.886L10 16.578h2.058L11.27 20.6h2.908l.787-4.022h3.027v-2.751h-2.5l.753-3.834h2.22v-2.75h-1.672zm-3.693 7.184h-3.28l.988-5.034h3.28l-.988 5.034z"
  let g = svg.append("g")
  g.append("path")
      .attr("d", d1)
      .attr("fill", colors[0])
      .attr("transform", `scale(0.7) translate(${x}, ${y})`)
  g.append("path")
    .attr("d", d2)
    .attr("fill", colors[1])
    .attr("transform", `scale(0.7) translate(${x+30}, ${y})`)
}
