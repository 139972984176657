import React, { useState, useEffect, useRef } from 'react'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Button from './Button'
import { get_prop } from '../js/util'

/*
    <Option
        error | warning | info
        w h
        title text
        button
    />
*/

export default (props) => {
    const w = props.w || '100%'
    const h = props.h || 280
    const type = get_prop(props, 'type', ['Error', 'Warning', 'Info', 'Success'], 'Info', 'Capitalize')
    const icons = {'Error':'error', 'Warning':'error', 'Info':'info', 'Success':'success'}
    const icon = icons[type]
    const iconc = type==='Error' ? 'red' : '#77ABBD'
    const color = type==='Error' ? 'red' : 'n80'
    return(
        <Box column w100 h100 pt={60} pb={20}>
            <Box w100 center mb={30}><Icon center size={70} color={iconc} icon={icon}/></Box>
            <Box w100 mb={10}><Text fs={24} center mb={10} color="pri160" text={props.title}/></Box>
            <Box w100><Text fs={16} color={color} center text={props.text}/></Box>
            <Box w100 alignbottom>
                {props.button && <Button focus={props.focus} lg mt={20} center centerv action text={props.button} onClick={props.onClick}/>}
            </Box>
        </Box>
    )
}
