import { useEffect } from "react"

export default function useClickOutside(ref, cb) {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target) && !(['svg', 'path'].includes(e.target.tagName))) return cb()
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    }
  })
}