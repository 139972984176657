import React, { useState, useRef, useEffect } from 'react'
import useWindowDims from '../hooks/useWindowDims'
import Box from './Box'
import Text from './Text'
import Image from './Image'
import { oget, urlf } from '../js/util'

export default (props) => {
    const [w, h] = useWindowDims()
    const [mobile, setMobile] = useState(false)
    const [logo, setLogo] = useState(null)
    const [imgb, setImgb] = useState(null)
    const [img, setImg] = useState(null)

    useEffect(() => {
        setMobile(w<900)
      }, [w,h])

    useEffect(() => {
        setLogo(props.logo)
        setImgb(urlf(oget(props.meta,['webapp','baseurl']),'images/under-background.png'))
        setImg(urlf(oget(props.meta,['webapp','baseurl']),'images/under-img.png'))
    }, [props.logo, props.content])

    const render_large = () => {
        return(
            <Box  w="100vw" h="100vh">
                <Box w={'100%'} h={'130%'} right={-300} top={'-25%'}>
                    <Image w100 h100 cover iurl={imgb}/>
                </Box>
                <Box w={500} h={200} left={180} top={440}>
                    <Text h0 text="Website under construction"/>
                    <Text sh3 text="We are working on it"/>
                </Box>
                <Box w={870} h={600} right={200} top={300}>
                    <Image w100 h100 cover iurl={img}/>
                </Box>
               
            </Box>
        )
    }

    const render_medium = () => {
        const pt = mobile ? 100 : 180
        return(
            <Box w='100vw'  h='100vh' hidden>
                <Box w={100} z={30} top={50} right={50}>
                    <Text bt3 color="pri100" text="Login" link="/app"/>
                </Box>
                <Box w={'100%'} h={'130%'} right={-300} top={'-25%'}>
                    <Image w100 h100 cover iurl={imgb}/>
                </Box>
                <Box w100 h100 pt={pt} ph={150}>
                    <Box column w={350} center pt={30} mb={50}>
                        <Box w={200} mb={50}>
                            <Image w100 iurl={logo}/>
                        </Box>
                        <Text fs={40} bold mb={20} text="Website under construction"/>
                        <Text fs={20} text="We are working on it"/>
                    </Box>
                    <Box w={480} h={330} center>
                        <Image w100 h100 cover iurl={img}/>
                    </Box>
                </Box>
            </Box>
        )
    }

    const style = {fontFamily: "'Poppins', sans-serif"}
    return (
        <Box style={style}>
            {render_medium()}
        </Box>
    )

}
