import React, { useState, useEffect, useRef } from 'react'
import Box from './Box'
import Spinner from './Spinner'
import Card from './Card'
import Switch from './Switch'
import Icon from './Icon'
import Text from './Text'
import Selector from './Selector'
import { is_obj, is_array, oget, aadd, arm, set_width, set_height, set_margin } from '../js/util'
import { get_col_name, get_col_type, is_col_numeric, get_col_align, get_width, formater } from '../js/field'
import { wrequest } from '../js/web'

/*
<Table
    loading={<bool>}
    data={data}
    cols={cols}
/>
*/

function useCorrectRef(initial) {
    const [value, setValue] = useState(initial);
    const [ref, setRef] = useState({ current: initial });
    useEffect(() => {
      setRef({
        get current() {
          return value;
        },
        set current(next) {
          setValue(next);
        }
      });
    }, [value]);
    return ref;
}

export default function Table(props) {
    const ref = useCorrectRef(null)
    const ref1 = useCorrectRef(null)
    const ref2 = useRef(null)
    const reft = useRef(null)
    const [w0, setW0] = useState(null)
    const [h0, setH0] = useState(null)
    const [h1, setH1] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [scol, setScol] = useState(null)
    const [ascending, setAscending] = useState(null)
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [tcomponent, setTcomponent] = useState(null)

    useEffect(() => {
        setScol(props.scol)
        setAscending(props.ascending||undefined)
        // set_tooltip()
        window.addEventListener('resize', setDimsHandler)
        return(() => {window.removeEventListener('resize', setDimsHandler)})
    }, [])

    useEffect(() => {
        if(props.scrollright && ref1.current && ref2.current) {
            ref1.current.scrollLeft = ref1.current.scrollWidth
            ref2.current.scrollLeft = ref2.current.scrollWidth
        }
    }, [ref1.current, ref2.current])

    const setDimsHandler = () => {
        if(ref.current==null) return
        const r = ref.current.getBoundingClientRect()
        setW0(r.width)
        setH0(r.height)
        if(props.onChangeDims) props.onChangeDims(r.width, r.height, true)
    }

    useEffect(() => {
        if(ref.current) setDimsHandler()
    }, [ref])

    useEffect(() => {
    }, [w0, h0])

    useEffect(() => {
        if(ref1.current && ref2.current) {
            ref1.current.addEventListener('scroll', e=>{ref2.current.scrollLeft=ref1.current.scrollLeft})
            ref2.current.addEventListener('scroll', e=>{ref1.current.scrollLeft=ref2.current.scrollLeft})
        }
    }, [ref1.current, ref2.current])

    useEffect(() => {
        setScol(props.scol)
        setAscending(props.ascending||undefined)
        // set_tooltip()
    }, [props.scol, props.ascending])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading, props.data, props.cols])

    const onSetPage = (n) => {
        if(props.onSetPage) props.onSetPage(n)
    }

    const onSort = (col, ascending) => {
        if(props.onSort) props.onSort(col, ascending)
    }

    const set_tooltip = (component,x1,y1) => {
        const x0=ref.current.getBoundingClientRect().x, y0=ref.current.getBoundingClientRect().y
        if(tcomponent!==component) setTcomponent(component)
        reft.current.style.display = 'flex'
        reft.current.style.left = (x1-x0-(200-102)/2)+'px'
        reft.current.style.top = (y1-y0)-(100-36)/2+'px'
    }
    const reset_tooltip = () => {setTcomponent(null);reft.current.style.display='none'}

    const is_selected = (n) => selected ? selected.indexOf(n)!=-1:false
    const add_select = (n) => is_selected(n) ? null : setSelected(aadd(selected,n))
    const rm_select = (n) => is_selected(n) ? null : setSelected(arm(selected,n))

    const onFieldClick = (e, idx, row, col) => {
        e.stopPropagation()
        if(row!=null && props.onSelect) props.onSelect(idx, row, col)
    }

    const render_header = (cols, wn, fixed=false) => {
        if(!cols)
            return null
        return(
            <Box w100 h={35} nowrap>
                {cols.map((col,i)=>render_header_field(col, i, cols.length, wn, fixed))}
            </Box>
        )
    }

    const render_header_field = (col, i, ncols, wn, fixed=false) => {
        const wf = get_col_w(props, col, i, ncols, wn, fixed)
        if(is_obj(col)) col = col.name
        const is_sort_col = props.scols && props.scols.includes(col)
        const align = get_col_align(props,col)
        const render = (!fixed||i!==0) && props.hrender
        const col_name = get_col_name(props.meta, col, props.layout)
        const fs = props.compact ? 9 : 10
        return(
            <Box key={i} w={wf} ph={5} onClick={e=>onFieldClick(e,i,null,col)}>
                <Box align={align}>
                    {!render && <Text fs={fs} centerv caps ph={5} mr={0} text={col_name}/>}
                    {render && props.hrender.render(col, props.ylookup)}
                    {is_sort_col && <Switch compact l0 ml={0} mr="auto" updown mt={5} innactive={col!=scol} check={ascending}
                                            onChange={(b)=>onSort(col,b)}/>}
                </Box>
            </Box>
        )
    }
    // onChange={(b)=>{setScol(col); setAscending(b)}}

    const render_footer = (cols) => {
        return null
    }

    const render_pad_bottom = () => {
        return (
            <Box cls="_PadBottom" w100 h={30} mt={0} pv={3}>
                 <Box h={28} w33>
                    {(props.onExcel) && <Box ml={20}><Icon left mt={5} icon="excel" onClick={props.onExcel}/></Box>}
                 </Box>
                 <Box h={28} w33>
                    {!props.scroll && props.npages>1 &&
                        <Selector npages={props.npages} page={props.page} onSet={onSetPage}/>}
                 </Box>
                 <Box h={28} w33>
                    {props.nrows>0 && <>
                        <Text alignright centerv text={props.nrows} color="n100" format="N"/>
                        <Text ml={5} mr={20} centerv color="n100" text='rows'/>
                    </>}
                </Box>
            </Box>
        )
    }

    const render_rows = (cols, wn, fixed=false) => {
        if(!props.data) return null
        return (<>
            {props.data.map((row,i)=>render_row(cols, row, i, wn, fixed))}
        </>)
    }

    const render_row = (cols, row, i, wn, fixed) => {
        const selected = props.select && is_selected(page*props.trows+i)
        const bg = selected ? 'green' : 'transparent'
        const bb = i!==props.trows-1 ? "n40":null
        return (
            <Box key={i} cls="_Row" w100 h={h1} bg={bg} bb={bb} hidden={false} nowrap>
                {/* {props.ctrls && <div className="Ctrls"></div>} */}
                {cols && cols.map((col,i1)=>render_field(i1, i, cols.length, row, col, wn, fixed))}
            </Box>
        )
    }

    const render_field = (i1, i, ncols, row, col, wn, fixed) => {
        let wf = get_col_w(props, col, i1, ncols, wn, fixed)
        if(ncols===1) wf='100%'
        const align = get_col_align(props,col)
        let f = get_render_field_f(row,col,align)
        return(
            <Box key={i1} cls="_TData" w={wf} h100 white onClick={e=>onFieldClick(e, i, row, col)}>
                {f()}
            </Box>
        )
    }

    const get_render_field_f = (row, col, align) => {
        if(is_obj(col) && col.render) return ()=>col.render(row, props.ylookup)
        if(props.cell) {
            const Cell = props.cell
            return ()=><Cell {...row[col]} col={col} {...props.cellprops} hover={set_tooltip}/>
        }
        const v = formater(get_col_type(props, col), row[col])
        const fs = props.compact ? 12 : 14
        return () =>
            <Box align={align} h100 pl={10} pr={10}>
                <Text fs={fs} centerv lineHeight={1.25} text={v}/>
            </Box>
    }

    const render_empty_table = (e) =>
        <>
            <Box w100 h={35} bg="n40"/>
            <Box w100 hz={70} bottomround white>
                {e}
            </Box>
            <Box w100 h={35}/>
        </>

    const empty_el = () => <Box center centerv><Icon size={128} icon="empty-table" fill="#999999"/></Box>
    const error_el = (error) => <Box center centerv><Card error={error} error_msg={""}/></Box>
    
    const render_table = () => {
        if(error) return render_empty_table(error_el(error))
        if(loading) return render_empty_table(<Spinner/>)
        if(!props.data || !props.data.length) return render_empty_table(empty_el())
        const cols = is_array(props.data) && is_array(props.cols) ? props.cols : Object.keys(props.data[0])
        const [cols1,cols2] = get_cols1_cols2(cols, props.nfixed)
        let [w1,w2,wn] = get_table_ws(props, w0, cols, cols1, cols2)
        if(!cols2) w1=Math.max(w1,w0)
        const scrollh = w2 > w0-w1-2
        const HGAP = props.scroll ? 20:2
        const hh = scrollh ? 45:35
        if(h1===null) setH1(get_h1(ref, props, scrollh))

        if(ref.current==null) return null
        return(<>
            <Box cls="_THeader" wz={0} h={hh} bg="n40">
                {cols1.length>0 &&
                    <Box cls="_THeader1" w={w1}>
                        {render_header(cols1, wn, true)}
                        {scrollh && <Box w={w1} h={10} bt={"white"}/>}
                    </Box>}
                {cols2.length>0 &&
                    <Box ref={ref1} cls="_THeader2" w={w0-w1-HGAP} scrollh>
                        <Box cls="_THeader2_Inner" w="auto">
                            {render_header(cols2, wn, false)}
                        </Box>
                    </Box>}
            </Box>

            <Box cls="_TTable" wz={0} hz={34+hh} white scrollv={props.scroll} bottomround>
                {cols1.length>0 &&
                    <Box column cls="_TTable1" w={w1}>
                        {render_rows(cols1, wn, true)}
                    </Box>}
                {cols2.length>0 &&
                    <Box ref={ref2} cls="_TTable2 noscrollbar" w={w0-w1-HGAP} scrollh>
                        <Box column cls="_TTable2_Inner">
                            {render_rows(cols2, wn, false)}
                        </Box>
                    </Box>}
            </Box>

            <Box cls="_TFooter" w100 h={35} br="0px 0px 8px 8px">
                {render_pad_bottom()}
            </Box>
        </>)
    }

    const cls = props.cls || '_Table'
    const b = props.b || null
    return(
        <Box ref={ref} cls={cls}s w100 h100 pad={0} b={b} br={8} hidden onClick={reset_tooltip} bg={props.bg}>
            {render_table()}
            <div ref={reft} className="Ttooltip" onMouseLeave={null} onClick={reset_tooltip}>{tcomponent}</div>
        </Box>
    )
}

const get_cols1_cols2 = (cols, nfixed) => {
    if(!cols) return [[],[]]
    if(!nfixed) return [cols,[]]
    return [cols.slice(0,nfixed),cols.slice(nfixed)]
}

const get_h1 = (ref, props, scrollh, def=35) => {
    if(props.compact) return 28
    if(props.h1) return props.h1
    const h = oget(ref, ['current', 'offsetHeight'])
    if(!h || !props.trows) return def
    let h0 = scrollh ? 90:72
    return (h-h0)/props.trows
}

const is_numeric_table = (props, cols) => {
    if(!cols) return false
    for(let i=1; i<cols.length; i++)
        if(!is_col_numeric(props,cols[i])) return false
    return true
}

const get_col_w = (props, col, i, ncols, wn=null, fixed=false) => {
    const min_w = 48
    if(fixed && ncols===1)
        return 500
    if(wn && !fixed) return wn
    if(is_obj(col)) {
        if(col.width)
            return col.width
        col=col.name
    }
    let w = oget(props, ['layout', 'cols', col, 'width'])
    if(w)
        return Math.max(w,min_w)
    w = oget(props, ['layout', 'colswidth'])
    if(w)
        return Math.max(w,min_w)
    w = oget(props.meta, ['fields', col, 'width'])
    if(w)
        return Math.max(w,min_w)
    const t = get_col_type(props,col)
    w = get_width(t)
    return Math.max(w,min_w)
}

const get_cols_w = (props, cols) => {
    if(!cols || !cols.length) return 0
    let w = 0
    for(let i=0; i<cols.length; i++)
        w += get_col_w(props, cols[i], i, cols.length)
    return w
}

const get_table_w = (props, cols, wn) => {
    if(!cols) return 500
    let w = 0
    for(let i=0; i<cols.length; i++)
        w += get_col_w(props, cols[i], i, cols.length, wn)
    return Math.max(w, 500)
}

const get_table_ws = (props, w0, cols, cols1, cols2) => {
    let wn = 120
    if(!cols || !w0) return [0,0,0]
    let w1,w2
    if(cols1 && cols1.length===1 && cols2 && cols2.length===1) {
        w1 = is_numeric_table(props, cols2) ? w0-wn : parseInt(w0*2/3)
        w2 = w0-w1-2
        return [w1,w2,wn]
    }
    w1 = get_cols_w(props, cols1)
    const numeric = is_numeric_table(props, cols2)
    if(!numeric) {
        w1 = cols1 ? w1:0
        w2 = get_table_w(props, cols)-w1
        wn = null
        return [w1,w2,wn]
    }
    w2 = wn*cols2.length
    if(w2<w0-400)
        w1 = w0-w2-2
    return [w1,w2,wn]
}
