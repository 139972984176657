import React, { useState, useEffect, useRef, createRef } from 'react'
import Box from './Box'
import Input from './Input'
import Button from './Button'
import Message from './Message'
import { is_array } from '../js/util.js'
import { wrequest } from '../js/web.js'

// const fields = [
//     {name:'name', type:'Text', size:'xl', label:'Name', placeholder:'Enter your name', minlen:2},
//     {name:'email', type:'Email', size:'xl', label:'Email', placeholder:'Enter your email'},
//     {name:'msg', type:'Area', size:'xl', label:'Message', placeholder:'Enter your meassage', minlen:5},
// ]

export default (props) => {
    const [refs,serRefs] = useState([])
    const [requiresCheck,setRequiresCheck] = useState([])
    const [canSubmit,setCanSubmit] = useState(false)
    const [mode,setMode] = useState('Form')
    const [focusButton,setFocusButton] = useState(false)

    useEffect(() => {
        serRefs(null)
        if(props.fields) {
            // setFrefs(Array(props.fields.length).fill().map((_,i)=>frefs[i] || createRef()))
            serRefs(Array(props.fields.length).fill().map((_,i)=>createRef()))
            setRequiresCheck(Array(props.fields.length).fill().map((_,i)=>requires_check(props.fields[i])))
        }
     }, [props.fields])

    useEffect(() => {
        setTimeout(e=>{if(is_array(refs) && refs.length && refs[0].current) refs[0].current.setFocus()},50)
    }, [refs])

    const check_can_submit = () => {
        for(let i=0; i<refs.length; i++) {
            if(!requiresCheck[i]) continue
            if(!refs[i].current.getCheck()) return false
        }
        return true
    }

    const onInputChange = (seq, name, value, check) => {
        if(props.onInputChange) props.onInputChange(seq, name, value, check)
        setTimeout(()=>setCanSubmit(check_can_submit(),50))
    }

    const onInputBlur = (seq) => {
        if(seq<refs.length-1) {
            setFocusButton(false)
            refs[seq+1].current.setFocus()
        }
        setFocusButton(true)
    }

    const onInputDone = (seq, name, value, check) => {
        if(props.onInputDone) props.onInputDone(seq, name, value, check)
    }
    
    const handleSubmit = (ev) => {
        ev.preventDefault()
        if(!props.onSubmit || !canSubmit) return
        const d = {}
        for(let i=0; i<refs.length; i++)
            d[props.fields[i].name] = refs[i].current.getValue()
        props.onSubmit(d)
    }

    const render_error = () => {
        return <Message warning title="Try again" text="Upload error" button="Retry" onClick={()=>setMode('Form')}/>
    }

    const render_done = () => {
        return <Message success title="Message sent" text="" button="Done" onClick={()=>setMode('Form')}/>
    }

    const render_button = () => {
        const action = canSubmit
        const innactive = !canSubmit
        const text = props.button || 'Done'
        return <Button w100 lg submit focus={focusButton} action={action} innactive={innactive} text={text}/>
    }

    const render_input = (e,i) => {
        if(!e) return null
        return(
            <Input
                blank
                key={i}
                seq={i}
                ref={refs[i]}
                type={e.type}
                size={e.size}
                label={e.label}
                name={e.name}
                placeholder={e.placeholder}
                minlen={e.minlen}
                maxlen={e.maxlen}
                min={e.min}
                max={e.max}
                onChange={onInputChange}
                onBlur={onInputBlur}
                onDone={onInputDone}
            />
        )
    }

    const render_form = () => {
        const minh = props.minh || 400
        return(
            <Box cls="_Form" column w100 minh={props.minh} ph={props.ph||0} pv={props.pv||30}>
                <form onSubmit={handleSubmit}>
                    <Box column w100 minh={minh-140}>
                        {props.fields.map((e,i)=>render_input(e,i))}
                    </Box>
                    <Box w100 mb={0}>{render_button()}</Box>
                    {props.button2 && <Box w100 h={25} contentcenter mb={0}><Button link text={props.button2} onClick={props.onClick2}/></Box>}
                    {props.button3 && <Box w100 h={25} contentcenter mb={0}><Button link text={props.button3} onClick={props.onClick3}/></Box>}
                </form>
            </Box>
        )
    }

    if(!is_array(props.fields) || refs==null) return null
    if(mode==='Error') return render_error()
    if(mode==='Done') return render_done()
    return render_form()
}

const requires_check = (e) => {
    if(!e) return false
    const t = e.type ? e.type.toLowerCase() : 'text'
    if(t==='long' && !e.minlen) return false
    if(t==='text' && !e.minlen) return false
    return true
}