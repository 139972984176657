import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import useWindowDims from '../hooks/useWindowDims'
import Box from './Box'
import Breads from '../components/Breads'
import Filters from './Filters'
import Table from './Table'
import Download from './Download'
import { PubSub } from '../js/pubsub'
import { wrequest } from '../js/web.js'
import { parse_filters, find_f, add_f, merge_fs, add_fs, rm_f } from '../js/filters'
import { oget } from '../js/util'
import { is_array } from '../js/util'
import { fireEvent } from '@testing-library/react'

// history.replace({ pathname: 'home', search: '?query=abc', state:{isActive: true}})
// props.location.state

// const stateObj = { foo: 'bar' };
// history.pushState(stateObj, '', 'bar.html');

// history.replaceState(stateObj, '', 'bar2.html')


export default (props) => {
    const ref = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [w, h] = useWindowDims()
    const [loadingParams, setLoadingParams] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [urlstate, setuUrlstate] = useState(null)
    const [cols, setCols] = useState(null)
    const [join, setJoin] = useState(null)
    const [f1, setF1] = useState(null)
    const [f2, setF2] = useState(null)
    const [f3, setF3] = useState(null)
    const [scols, setScols] = useState(null)
    const [scol, setScol] = useState(null)
    const [ascending, setAscending] = useState(null)
    const [data, setData] = useState(null)
    const [nrows, setNrows] = useState(null)
    const [npages, setNpages] = useState(null)
    const [offset, setOffset] = useState(null)
    const [page, setPage] = useState(0)
    const [ylookup, setYlookup] = useState(null)
    const [trows, setTrows] = useState(null)
    const [selected, setSelected] = useState(false)
    const [breads, setBreads] = useState([])
    const [back, setBack] = useState('/app')
    const [h0, setH0] = useState(null)

    useEffect(() => {
        setLoadingParams(true)
        if(trows) set_url_state()
    }, [location, trows])

    useEffect(() => {
        if(urlstate) {
            setLoadingParams(true)
            set_params()
        }
    }, [urlstate])

    useEffect(() => {
        if(loadingParams===false) get_data()
    }, [loadingParams])

    const set_url_state = () => {
        const attrs = ['tbl', 'cols', 'wlisttbl', 'wlist', 'join', 'f1', 'f2', 'f3', 'filters', 'idfilter', 'sfilters',
                       'scols', 'scol', 'ascending', 'page', 'offset', 'breads', 'back']
        const s = location.search && location.search.length ? `${location.search.substring(1)}` : '{}'
        const o1 = get_props_subset(props, attrs)
        if(location.search.length==0 && o1 && o1.f2)
            for(let i=0; i<o1.f2.length; i++)
                o1.f2[i].val = null
        const o2 = {...JSON.parse(decodeURI(s))}
        const o = {...o1, ...o2}
        setuUrlstate(o)
    }

    const set_params = () => {
        const o = urlstate
        if(o.cols) setCols(o.cols)
        if(o.wlisttbl && o.wlist) {
            const join = {tbl2:o.wlisttbl, key1:'uid', key2:'ecg', col:'wlist', val:o.wlist}
            setJoin(join)
        } else {
            if(o.join) setJoin(o.join)
        }
        setF1(parse_filters(o.f1))
        setF2(parse_filters(o.f2))
        setF3(parse_filters(o.f3))
        if(o.scols) setScols(o.scols)
        if(o.scol) setScol(o.scol)
        if(o.sort) setScol(o.sort)
        if(o.ascending!==undefined) setAscending(o.ascending)
        const of = (o.offset!=null || selected) ? o.offset : page*trows
        setOffset(of)
        setSelected(o.selected)
        if(o.page!=null) setPage(o.page)
        if(o.breads) setBreads(o.breads)
        setBack(o.back || o.back2 || '/app')
        setLoadingParams(false)
    }

    const get_payload = (excel=false) => {
        const o = urlstate
        const fs = get_filters(o.f1, o.f2)
        const idf = fs.filter(e=>e.type==='id')
        const lfs = fs.filter(e=>e.type==='lookup'||e.type==='tree')
        const sfs = fs.filter(e=>e.type==='search')
        const filters = idf.length===0 ? lfs:idf
        let of = o.selected ? o.offset : o.page*trows
        if(isNaN(of)) of=null
        if(idf.length!==0)
            of = 0
        let payload = {
            cols: null,
            join: join,
            filters: filters,
            sfilters: sfs,
            sort: o.scol,
            ascending: o.ascending,
            offset: of,
            limit: o.selected ? 1 : trows,
            excel: excel
        }
        if(props.tbl)
            payload.tbl=props.tbl
        if(props.payload)
            payload={...payload, ...props.payload}
        return payload
    }

    const get_data = async (excel=false) => {
        const payload = get_payload()
        setLoading(true)
        // console.log(props.url)
        // console.log(payload)
        let res = await wrequest(props.url, 'POST', payload)
        // console.log(res)
        setLoading(false)
        if(!res) {
            setError('Data Access Error')
            return
        }
        setCols(get_cols(res))
        if(res.ylookup) setYlookup(res.ylookup)
        setData(res.data)
        const np = Math.ceil(res.nrows/trows)
        if(res.nrows) {
            let n = res.nrows
            if(res.data.length===0) n=0
            setNrows(n)
            setNpages(np)
        }
        if(selected && props.selbreads) setBreads(props.selbreads(res.data[0]))
    }

    const get_cols = (res) => {
        if(!res) return []
        let cols1 = props.cols || ((res.data && res.data.length) ? Object.keys(res.data[0]) : [])
        if(!res.vcols) return cols1
        let cols2 = cols1.filter(e=>isNaN(e))
        cols1 = cols2.concat(res.vcols)
        return cols1
    }

    const onChangeDims = (w, h, hscroll) => {
        const t_ = props.scroll ? 64 : parseInt((h-70-(hscroll?10:0))/35)
        setTrows(t_)
    }

    const onFiltersChange = (f2_) => {
        const urlstate2 = {...urlstate}
        urlstate2.f2 = f2_
        const url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate2))}`
        history.replace(url)
    }

    const onSetPage = (n) => {
        const urlstate2 = {...urlstate}
        urlstate2.page = n
        const url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate2))}`
        history.push(url)
    }

    const onLeft = () => offset>0 ? onSetOffset(offset-1):null
    const onRight = () => offset<nrows-1 ? onSetOffset(offset+1):null
    const onDone = () => history.push(urlstate.back)

    const onSetOffset = (n) => {
        const urlstate2 = {...urlstate}
        urlstate2.offset = n
        const url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate2))}`
        history.push(url)
    }

    const onSort = (col, ascending) => {
        const urlstate2 = {...urlstate}
        urlstate2.scol = col
        urlstate2.ascending = ascending
        const url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate2))}`
        history.push(url)
    }

    const onSelect = (idx, row, col) => {
        const back_url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate))}`
        if(props.onSelect && !props.onSelect(idx, row, col)) return
        const idcol = props.recordIdCol || 'id'
        const urlstate2 = {...urlstate}
        urlstate2.selected = true
        urlstate2.offset = page*trows+idx
        urlstate2.nrows = nrows
        urlstate2.back = back_url
        urlstate2.back2 = back
        urlstate2.breads = props.selbreads ? props.selbreads(row):[]
        const url = `${location.pathname}?${encodeURI(JSON.stringify(urlstate2))}`
        history.push(url)
    }

    const onExcel = (fname) => {
        PubSub.publish('MODAL-SIDENAV',
                        {Component:Download,
                         Params: {title:"Excel Report", fname:fname, type:'xlsx', onGetReport:onGetReport}})
    }

    const onGetReport = async () => {
        const payload = get_payload(true)
        return await wrequest(props.url, 'POST', payload)
    }

    const render_filters = () => {
        return(
            <Filters
                meta={props.meta}
                loading={loading}
                f1={f1}
                f2={f2}
                f3={f3}
                onChange={onFiltersChange}
            />
        )
    }

    const render_table = () => {
        return(
            <Table
                loading={loading}
                h1={props.h1}
                meta={props.meta}
                layout={props.layout}
                data={data}
                cols={cols}
                scols={props.scols}
                scol={scol}
                ascending={ascending}
                nrows={nrows}
                trows={trows}
                scroll={props.scroll}
                npages={npages}
                page={page}
                onSetPage={onSetPage}
                onSort={onSort}
                onSelect={onSelect}
                onExcel={props.excel?()=>onExcel(props.title):null}
                cell={props.cell}
                ylookup={ylookup}
                hrender={props.hrender}
                nfixed={props.nfixed}
                frmt={props.frmt}
                onChangeDims={onChangeDims}
                scrollright={props.scrollright}
            />
        )
    }

    const render_data_table = () => {
        return(
            <Box column w100 hz={80} center>
                <Box cls="_FiltersContainer" w100 h={50} mb={25}>
                    {render_filters()}
                </Box>
                <Box cls="_TableContainer" w100 hz={50}>
                    {render_table()}
                </Box>
            </Box>)
    }

    const render_record = () => {
        if(!props.recordComponent) return null
        const Component = props.recordComponent
        if(props.newrec)
            return <Component meta={props.meta} newrec={props.newrec} onnewrecdone={props.onnewrecdone}/>
        if(!data || data.length>1) return null
        const idx = data.length>1 ? 0:0
        return <Component meta={props.meta} row={data[idx]} layout={props.recordLayout}
                          idcol={props.recordIdCol} {...props.recordComponentProps} newrec={props.newrec}
                          onnewrecdone={props.onnewrecdone} onLeft={onLeft} onRight={onRight} onDone={onDone}/>
    }

    const render_breads = () => {
        return(
            <Breads nav={props.breadsnav} back={back} breads={breads} mt={props.breadsmt} mb={props.breadsmb}
                    nrows={nrows} offset={offset} first={!offset} last={offset==nrows-1}
                    onLeft={selected?onLeft:null} onRight={selected?onRight:null} />
        )
    }
    
    const w_ = selected ? (props.w2||'100%') : (props.w1||'100%')
    const mode = (selected || props.newrec) ? 'Record' : 'Table'
    return (
        <Box column ref={ref} cls="_DataTable" w={w_} h100 center>
            {props.breads && render_breads()}
            {mode==='Table' && render_data_table()}
            {mode==='Record' && render_record()}
        </Box>
    )
}

const get_props_subset = (props, attrs) => {
    const o = {}
    for(let i=0; i<attrs.length; i++)
        if(props[attrs[i]]!==undefined)
            o[attrs[i]] = props[attrs[i]]
    return o
}

const get_filters = (f1, f2) => {
    if(!f1) f1=[]
    if(!f2) f2=[]
    const fs = [...f1]
    f2.forEach(e => {
      if(e.val)
        fs.push(e)
    })
    return fs
  }
