import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { oget, css_flex, set_width, set_height, set_absolute, set_margin, set_padding, set_border, set_background} from '../js/util'

const SHADOW = '0px 0px 40px rgba(34, 41, 69, 0.12)'

/*
    alignleft alignright props.center aligntop centerv alignbottom
    align={align}
    column contenttop contentcenter contentright
    panel shadow opacity br topround bottomround
    hidden hiddenx hiddeny noscroll scroll scrollv scrollv
    onClick onMouseOver onMouseOut onMouseDown onMouseUp onMouseMove
    style={style} link={link} href={href}
*/

export default React.forwardRef((props, ref) => {
    let style = {...css_flex(!props.nowrap)}
    set_width(props, style)
    set_height(props, style)
    set_absolute(props, style)
    set_margin(props, style)
    set_padding(props, style)
    set_border(props, style)
    set_background(props, style)

    if(props.z) style.zIndex = props.z

    if(props.column) {style.flexDirection='column'; style.flexWrap='nowrap'}
    if(props.contenttop) style.alignItems='flex-start'
    if(props.contentcenter) style.justifyContent='center'
    if(props.contentright) style.justifyContent='flex-end'

    if(props.panel) {style.borderRadius=10; style.boxShadow=SHADOW}
    if(props.shadow) style.boxShadow = (props.shadow===true ? SHADOW : props.shadow)
    if(props.opacity) style.opacity=props.opacity
    if(props.br) style.borderRadius = props.br===true?10:props.br
    if(props.topround) style.borderRadius = "10px 10px 0px 0px"
    if(props.bottomround) style.borderRadius = "0px 0px 10px 10px"

    if(props.hidden) style.overflow='hidden'
    if(props.hiddenx) style.overflowX='hidden'
    if(props.hiddeny) style.overflowY='hidden'
    if(props.noscroll) {style.overflow='hidden'}
    if(props.scroll) {style.overflow='auto'}
    if(props.scrollh) {style.overflowX='auto'; style.overflowY='hidden'}
    if(props.scrollv) {style.overflowY='auto'; ; style.overflowX='hidden'}

    style.cursor='auto'
    if(props.onClick||props.pointer) style.cursor='pointer'
    if(props.innactive) style.cursor='not-allowed'
    if(props.style) style={...style, ...props.style}
    const cls = props.cls ? `_Box ${props.cls}`:'_Box'
    const box_props = {style:style, link:props.link, href:props.href}

    const k = (props.id!=null) ? props.id : null

    if(props.link)
        return <Link className={cls} to={{pathname:props.link, state:props.state}} {...box_props}>{props.children}</Link>
    if(props.href)
        return <a ref={ref} key={k} className={cls} href={props.href} {...box_props}>{props.children}</a>
    return(
        <div ref={ref||null} id={k} className={cls} style={style}
             onClick={props.onClick}
             onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} onMouseLeave={props.onMouseLeave}
             onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp} onMouseMove={props.onMouseMove}
             draggable={false} onDragStart={null} onDragEnd={null}>
            {props.children}
        </div>
    )
})
