import React, { useState, useEffect, useRef } from 'react'
import Box from '../components/Box'
import ScaleLoader from 'react-spinners/ScaleLoader'

export default (props) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timer = setInterval(()=>setShow(true), 100)
    return ()=>clearInterval(timer)
  }, [])
  if(!show) return null
  return(
    <Box w100 h100>
      <Box center centerv>
        <ScaleLoader height={100} width={4} color={'#4293B6'}/>
      </Box>
    </Box>
  )
}
