import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import useWindowDims from '../hooks/useWindowDims'
import '../App.css'
import Box from './Box'
import Image from './Image'
import Text from './Text'
import Icon from './Icon'
import Card from './Card'
import Form from './Form'
import Button from './Button'
import { oget, get_prop, urlf, is_array, getDevice, is_string } from '../js/util'

const W0 = 320
const W1 = 480
const W2 = 820
const W3 = 1024
const W4 = 1366
const W5 = 2560

const MAXW = 3000
const MAXW_CONTENT = W4
const MINW = 300

/*
    Mobile Viewports:
        iphone 5 : 320 x 568
        iphone 6: 375 x 667
        iphone 8: 375 x 667
        iphone 14: 390 × 844
        iphone 14 Max: 430 × 844
        Samsung Galaxy Note 5: 480 x 853

        ipad: 820 x 1180
        ipad pro: 1024 x 1366

    Desktop Viewports:
        1366 × 768	(11.20%)
        1440 × 900	(9.55%)
        1536 × 864	(8.60%)
        1920 × 1080	(22.62%)
        2560 × 1440	(7.41%)
*/

const TYPES = ['Hero1', 'Hero', 'Intro', 'Topics', 'Banner', 'Solution', 'Features', 'Demo', 'Versus', 'Partners', 'About', 'TeamMember',
               'Technology', 'Person', 'Contact', 'Portfolio', 'Documents', 'Menu']

export default (props) => {
    const history = useHistory()
    const [w, h] = useWindowDims()
    const ref = useRef(null)
    const refinfo = useRef(null)

    useEffect(() => {
        if(ref.current && check_path(props.id, props.selectedHash)) setTimeout(()=>ref.current.scrollIntoView(),0)
    }, [props.id, props.selectedHash])

    useEffect(() => {
        if(props.idx===0 && props.setHeaderInBackMode) {
            // props.setHeaderInBackMode(props.menutopic)
            // setTimeout(()=>ref.current?ref.current.scrollIntoView():null,100)
        }
    },[])

    /* ****************************************************************************************************
    *   Components
    *  ************************************************************************************************* */

    const TextBox = (props) => {
        const size = props.size!=null ? props.size : 2
        const W2S = ['90%','90%','80%',800,800,800]
        const FS1 = [20,28,28,40,40,50]
        const FS2 = [18,18,20,26,26,26]
        const FS3 = [16,16,18,20,20,20]
        const p = props.p || 1
        const p1 = props.p1|| 1
        const fs1 = p1*FS1[size]
        const fs2 = p*FS2[size]
        const fs3 = p*FS3[size]
        const w2 = props.w100 ? '100%' : (props.w || W2S[size])
        const mb = props.mb1 || 20
        const center = !props.left
        return(
            <Box w100>
                <Box w={w2} mb={50} center>
                <Box w100 center={center}>
                    {props.title && <Text fs={fs1} lineHeight={1.5} bold w100 mb={mobile?20:40} center={center} text={props.title}/>}
                    {props.subtitle && <Text bold fs={fs2} w100 lineHeight={1.5} mb={40} center={center} text={props.subtitle}/>}
                    {props.text && <Text fs={fs3} w100 lineHeight={1.5} mb={30} center={center} text={props.text}/>}
                    {props.textlines && props.textlines.map((e,i)=>
                        <Text key={i} w100 fs={fs3} lineHeight={1.5} mb={mb} align="left" text={e}/>)}
                </Box>
                </Box>
            </Box>
        )
    }

    const Widget = (props)=> {
        const WS2 = [100,100,200,250,250,250]
        const HS2 = [70,70,150,180,180,180]
        const FS1 = [18,20,40,50,50,50]
        const FS2 = [14,14,20,20,20,20]
        const FS3 = [12,12,15,18,18,18]
        const w2 = WS2[size]
        const fs1 = FS1[size]
        const fs2 = FS2[size]
        const fs3 = FS3[size]
        const color1 = props.color || "n160"
        const color2 = props.color || "sec120"
        const color3 = props.color || "n100"
        return(
            <Box column w={w2} pt={10}>
                <Text w100 fs={fs1} bold color={color1} text={props.value} format={props.format||'N'}/>
                <Text w100 mb={10} fs={fs2} caps color={color2} text={props.label}/>
                {props.text && <Text w100 fs={fs3} color={color3} text={props.text}/>}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Hero
    *  ************************************************************************************************* */

    const render_hero = () => {

        const render_hero_text = () => {
            const FS1 = [24,28,32,32,36,45]
            const FS2 = [18,20,20,23,24,25]
            const fs1 = FS1[size]
            const fs2 = FS2[size]
            const w1 =  mobile ? '100%' : '50%'
            return(
                <Box column w={w1} pv={mobile?50:120} ph={0} pr={mobile?0:50}>
                    <Text w100 fs={fs1} bold mb={20} color="pri80" text={props.title}/>
                    <Text w100 fs={fs2} lineHeight={1.5} mb={30} color="n100" text={props.subtitle}/>
                    {props.cta && <Button action text={props.cta} onClick={()=>history.push('/#contact')}/>}
                </Box>
            )
        }

        const render_hero_img = () => {
            const w1 =  mobile ? '100%' : '50%'
            const maxh = mobile ? 430 : null
            return(
                <Box w={w1} maxh={maxh} hidden>
                    {img && <Image meta={props.meta} h100={!mobile} w100={mobile} iurl={img}/>}
                </Box>
            )
        }

        return(
            <Box w100 minh={270} mt={40} center>
                {render_hero_text()}
                {render_hero_img()}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Intro
    *  ************************************************************************************************* */

    const render_intro = () => {
        const W2S = ['90%','90%',240,290,300,320]

        const render_logo_bar = () => {
            if(!is_array(props.logobar)) return null
            const mobile = w<W3
            const top = mobile ? -150 : -200
            return(
                <Box w100 maxh={100} mt={-150} center contentcenter>
                    {props.logobar.map((e,i)=><Image key={i} h={20} meta={props.meta} src={e} mr={30}/>)}
                </Box>
            )
        }

        return(
            <Box w100 center>
                {render_logo_bar()}
                <TextBox w100 mb1={10} left={props.left} p1={.8} size={size} title={props.title} subtitle={props.subtitle}
                         text={props.text} textlines={props.textlines}/>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Technology
    *  ************************************************************************************************* */

    const render_technology = () => {
        const W2S = ['90%','90%',240,290,300,320]
        const w2 = W2S[size]

        const render_cards = () => {
            if(!props.cards) return null
            return(<>
                <Box w100 maxw={1200} center contentcenter>
                    {props.cards.map((card,i)=><Card key={i} w={w2} content={props.content} mobile={mobile} {...card} mb={50}/>)}
                </Box>
            </>)
        }

        return(
            <Box w100 center>
                <TextBox w100 left={props.left} size={size} p1={.8} title={props.title} subtitle={props.subtitle}
                         text={props.text} textlines={props.textlines}/>
                {render_cards()}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   ImageText
    *  ************************************************************************************************* */

    const render_person = () => {
        const mobile = w<W3
        const FS = [24,24,24,24,24,24]
        const fs = FS[size]

        const render_img_box = () => {
            const w1 =  mobile ? '100%' : '50%'
            const maxh = mobile ? 430 : null
            return(
                <Box w={w1} minw={300} maxh={maxh} hidden>
                    {img && <Image meta={props.meta} h100={!mobile} w100={mobile} iurl={img}/>}
                </Box>
            )
        }

        const render_text_box = () => {
            const w1 =  mobile ? '100%' : '50%'

            return(
                <Box column w={w1} minw={300} pv={mobile?50:50} ph={mobile?20:30}>
                    <Text w100 fs={fs} mb={10} color="orange" bold text={props.name}/>
                    <Text w100 fs={fs*.8} lineHeight={1.5} mb={20} color="n100" text={props.role}/>
                    {props.textlines && props.textlines.map((e,i)=>
                        <Text key={i} w100 fs={18} lineHeight={1.5} mb={10} align="left" text={e}/>)}
                </Box>
            )
        }

        return(
            <Box w100 minh={400} center contentcenter>
                <TextBox w100 left={props.left} p1={.8} size={size} p={.7} title={props.title}/>
                {render_img_box()}
                {render_text_box()}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Topics
    *  ************************************************************************************************* */

    const render_topics = () => {
        const W2S = ['90%','90%',240,290,300,320]
        const w2 = W2S[size]

        const render_cards = () => {
            if(!props.cards) return null
            return(<>
                <Box w100 maxw={1200} center contentcenter>
                    {props.cards.map((card,i)=><Card key={i} w={w2} content={props.content} mobile={mobile} {...card} mb={50}/>)}
                </Box>
            </>)
        }

        return(
            <Box w100 center>
                <TextBox size={size} p={.7} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                {render_cards()}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Portfolio
    *  ************************************************************************************************* */

    const render_portfolio = () => {
        const mobile = w<=W2
        const W2S = ['90%','90%','90%','40%','40%','30%']
        const w2 = W2S[size]

        const render_cards = () => {
            if(!props.cards) return null
            return(<>
                <Box w100 maxw={1200} center contentcenter>
                    {props.cards.map((card,i)=><Card key={i} w={w2} content={props.content} mobile={mobile} {...card} mb={30}/>)}
                </Box>
            </>)
        }

        return(
            <Box w100 center>
                <TextBox w100 p1={.8} left={props.left} size={size} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                {render_cards()}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Banner
    *  ************************************************************************************************* */

    const render_banner = () => {
        const mv = 50
        const fs1 = mobile ? 28 : 40
        return(
            <Box w100 minh={260} maxh={400} hidden>
                {props.img && <Image meta={props.meta} cover src={props.img}/>}
                {props.title && <Box w={mobile?"80%":"70%"} mb={50} center><Text fs={fs1} w100 center color="white" text={props.title}/></Box>}
                <Box w100 center>
                    <Box w100 contentcenter>
                        {props.widgets && props.widgets.map((e,i)=><Widget key={i} color="white" label={e.label} value={e.value}/>)}
                        {props.text && <Box w70 mt={50} center><Text fs={32} w100 center color="white" text={props.text}/></Box>}
                    </Box>
                </Box>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Solution
    *  ************************************************************************************************* */

    const render_solution = () => {
        const WS1 = ['100%','100%','100%',450,600,800]
        const WS2 = ['100%','100%','100%',200,200,200]
        const w1 = WS1[size]
        const w2 = WS2[size]
        const mb = mobile?30:50
        return(<>
            <TextBox size={size} left title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}
                    cta="Watch Demo"/>
            <Box w100 mb={mb} center>
                <Box w={w1} mr={mobile?30:50} mb={mb}>
                    <Image meta={props.meta} src={props.img}/>
                </Box>
                <Box column={!mobile} w={w2} center contentcenter={mobile} spaceBetween={mobile}>
                    {props.widgets && props.widgets.map((e,i)=><Widget key={i} {...e}/>)}
                    <Button mt={50} center={mobile} action text="See details" onClick={()=>history.push('/solution')}/>
                </Box>
            </Box>
        </>)
    }

    /* ****************************************************************************************************
    *   Demo
    *  ************************************************************************************************* */

    const render_demo = () => {
        const mobile = w<W3

        const DemoCard = (props) => {
            const WS1 = ['90%','80%','70%',400,500,500]
            const WS2 = [240,260,320,350,350,350]
            const WS3 = [200,200,260,200,300,300]
            const w1 = WS1[size]
            const w2 = WS2[size]
            const w3 = WS3[size]
            const ph = mobile ? 30 : 40
            const pv = mobile ? 30 : 30
            const align = w<W2 ? 'center' : 'right'
            return(
                <Box w={w1} mb={mobile?30:50} ph={ph} pv={pv} br={30} center white>
                    <Box w={w2} mb={20} pr={10}>
                        <Text w100 fs={24} bold text={props.title} mb={10}/>
                        <Text w100 fs={16} text={props.text}/>
                    </Box>
                    <Box w={w3} align={align}>
                        <Image meta={props.meta} center src={props.img}/>
                    </Box>
                </Box>

            )
        }

        const mt = props.idx===0 ? 200:0
        let left = props.idx===0 ? false:true
        return(
            <Box w100 mt={mt} center scroll={!mobile}>
                <TextBox size={size} left={left} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                {/* 'Watch Demo' */}
                    <Box column w100> 
                    <Box w100 maxw={1500}>
                        {props.cards && props.cards.map((e,i)=><DemoCard key={i} {...e}/>)}
                    </Box>
                    </Box>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Features
    *  ************************************************************************************************* */

    const render_features = () => {
        const render_tag = (e, i) =>
            <Box key={i} w100 mr={10} mt={10} mb={10} ph={20} pv={8} br={10} white>
                <Text fs={14} color="pri80" text={e}/>
            </Box>

        const render_tag_group = (e, i) =>
            <Box column w={300} mr={30} mb={20} br={12} bg="#F4F9FB" b="#DCDDE1">
                <Box w100 h={70} ph={20} bb="#DCDDE1"><Text b3 caps centerv text={e.title} bold mt={15}/></Box>
                <Box w100 ph={20} maxh={500} pv={10} scroll>
                    {e.labels && e.labels.map((e1,i1)=>render_tag(e1,i1))}
                </Box>
            </Box>

        const mt = props.idx===0 ? 200:0
        return(
            <Box w100 pv={mt}>
                <TextBox size={size} title={props.title} subtitle={props.subtitle} textlines={props.textlines}/>
                {props.img && <Box w80 mb={50} center><Image meta={props.meta} w100 center src={props.img}/></Box>}
                {props.cards && <Box w100 center contentcenter>
                    {props.cards && props.cards.map((e,i)=>render_tag_group(e,i))}
                </Box>}
                {props.text && <TextBox size={size} text={props.text}/>}
                {props.cta && <Button center action text={props.cta} onClick={()=>history.push('/features')}/>}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Versus
    *  ************************************************************************************************* */

    const render_versus = () => {
        const mobile = w<=W2

        const render_topic = (e, i, k) => {
            let fs = mobile ? 15 : 20
            if(w<W1) fs = 14
            if(!is_array(e)) return null
            return(
                <Box key={i} w100 maxw={400} h={h1} center mb={30} ph={mobile?10:20} pv={20} br={20} bg="sec80">
                    <Text fs={fs} center text={e[k]}/>
                </Box>
            )
        }
        if(!is_array(props.titles) || !is_array(props.topics)) return null
        const w1 = 500
        const h1 = 120
        return(
            <Box>
                <TextBox size={size} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                <Box maxw={850} center>
                    <Box w50 ph={10}>
                        <Box w100 h={80} mb={30}><Text fs={24} bold center text={props.titles[0]}/></Box>
                        {props.topics.map((e,i)=>render_topic(e, i, 0))}
                    </Box>
                    <Box w50>
                        <Box w100 h={80} mb={30}><Text fs={24} bold center text={props.titles[1]}/></Box>
                        {props.topics.map((e,i)=>render_topic(e, i, 1))}
                    </Box>
                </Box>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   About
    *  ************************************************************************************************* */

    const render_about = () => {

        const render_member = (e, i) => {
            const onClick = () => history.push(`/team?member=${e.name}`)
            return(
                <Box column w={250} h={280} mr={20} mb={50} ph={20} pv={20} br={8} white onClick={onClick}>
                    <Box w100 h={170} pv={24} contentcenter><Image meta={props.meta} w={120} src={e.img}/></Box>
                    <Box w100><Text fs={20} mb={6} bold center text={e.name}/></Box>
                    <Box w100><Text fs={20} mb={20} center text={e.role}/></Box>
                    {/* <Box w100><Text fs={16} center text={e.text}/></Box> */}
                    {false && is_array(e.textlines) && e.textlines.map((e1,i1)=><Text key={i1} text={e1}/>)}
                </Box>
            )
        }
        if(!is_array(props.team) || !is_array(props.board)) return null
        return(
            <Box w100>
                <TextBox size={size} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                <Box w100 mb={50}><Text fs={22} mt={50} mb={30} center text="Team members"/></Box>
                <Box w100 maxw={1000} center contentcenter>{props.team.map((e,i)=>render_member(e, i, 1))}</Box>
                <Box w100 mb={50}><Text fs={22} mt={50} mb={30} center text="Board members"/></Box>
                <Box w100 maxw={1000} center contentcenter>{props.board.map((e,i)=>render_member(e, i, 1))}</Box>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   TeamMember
    *  ************************************************************************************************* */

    const get_member_data = () => {
        const member = oget(props,['query','member'])
        if(!member) return null
        const webpages = oget(props,['content','webpages',])
        if(!is_array(webpages)) return null
        const webpage = webpages.find(e=>e.path==='/')
        if(!webpage) return null
        const slide = webpage.slides.find(e=>e.id==='team')
        if(!slide) return null
        let m = slide.team ? slide.team.find(e=>e.name==member) : null
        if(m) return m
        m = slide.board ? slide.board.find(e=>e.name==member) : null
        return m
    }

    const render_member_full = (e, mobile) => {
        // props.setHeaderInBackMode(e.name)
        // setTimeout(()=>ref.current?ref.current.scrollIntoView():null,100)
        return(
            <Box column w100 maxw={800} mr={20} mb={20} ph={20} br={8} white>
                <Box w100 h={280} pv={24} contentcenter><Image meta={props.meta} w={200} src={e.img}/></Box>
                <Box w100><Text fs={20} mb={6} bold center text={e.name}/></Box>
                <Box w100><Text fs={20} mb={20} center text={e.role}/></Box>
                <Box w100 mb={30}><Text fs={24} center text={e.text}/></Box>
                {is_array(e.textlines) && e.textlines.map((e1,i1)=><Text key={i1} fs={20} mb={20} text={e1}/>)}
                {mobile && <Icon icon="back" onClick={()=>history.push('/#about')}/>}
            </Box>
        )
    }

    const render_team_member = () => {
        const mobile = w<=W2
        const member_data = get_member_data()
        if(!member_data) return null
        return(
            <Box w100 ph={50} pt={90+50} pb={50} center contentcenter>
                {render_member_full(member_data, mobile)}
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Contact
    *  ************************************************************************************************* */

    const ContactForm = (props) => {

        const fields = [
            {name:'name', type:'Text', size:'xl', label:'Name', placeholder:'Enter your name', minlen:2},
            {name:'email', type:'Email', size:'xl', label:'Email', placeholder:'Enter your email'},
            {name:'msg', type:'Area', size:'xl', label:'Message', placeholder:'Enter your meassage', minlen:5},
        ]
        return(
            <Box w100 h={680} mb={50} ph={20} pv={30} white br={12} shadow>
                <Form fields={fields} button="Send message" url={"/api/contact"}/>
            </Box>
        )
    }

    const Location = (e) => {
        return(
            <Box w100 mb={50} onClick={()=>window.open(e.maplink, '_blank')}>
                <Text fs={24} mb={10} bold text={e.country}/>
                <Box w100 mb={20}>
                    <Image meta={props.meta} src={e.img}/>
                </Box>
                <Box w100 ph={20}>
                    <Text fs={16} w100 text={e.address}/>
                    <Text fs={16} w100 text={e.postal}/>
                    <Text fs={16} w100 text={e.city}/>
                </Box>
            </Box>
        )
    }

    // Draft
    const copy_to_clipboard = () => {
        return null
        console.log(refinfo.current)
        if(!refinfo.current) return
        console.log(refinfo.current.value)
        // refinfo.current.select()
        navigator.clipboard.writeText('text to be copied')
        // e.target.focus()
    }

    const render_email_us = () => {
        if(!props.email) return null
        return(
            <Box w100 mb={20}>
                <Box w100>
                    <Text fs={18} mr={5} text="Fill in or email us at"/>
                    <Text ref={refinfo} fs={18} bold text={props.email} onClick={copy_to_clipboard}/>
                </Box>
            </Box>
        )
    }

    const render_contact = () => {
        const mobile = w<=W3
        const w1 = mobile ? '100%' : 430
        return(
            <Box>
                <TextBox size={size} title={props.title} subtitle={props.subtitle} text={props.text} textlines={props.textlines}/>
                <Box w100>
                    <Box w={w1} center>
                        {render_email_us()}
                        <ContactForm/>
                    </Box>
                    <Box w={w1} h={600} center>
                        {is_array(props.locations) && props.locations.map((e,i)=><Location {...e}/>)}
                    </Box>
                </Box>
            </Box>
        )
    }

    /* ****************************************************************************************************
    *   Partners
    *  ************************************************************************************************* */

    const render_partners = () => {
        return null
    }

    /* ****************************************************************************************************
    *   Main
    *  ************************************************************************************************* */

    const render_slide = () => {
        switch(type) {
            case 'Hero': return render_hero()
            case 'Intro': return render_intro()
            case 'Topics': return render_topics()
            case 'Person': return render_person()
            case 'Technology': return render_technology()
            case 'Banner': return render_banner()
            case 'Features': return render_features()
            case 'Solution': return render_solution()
            case 'Demo': return render_demo()
            case 'Versus': return render_versus()
            case 'Portfolio': return render_portfolio()
            case 'About': return render_about()
            case 'Teammember': return render_team_member()
            case 'Partners': return render_partners()
            case 'Contact': return render_contact()
            default: return null
        }
    }

    if(w==null) return null
    const type = get_prop(props, 'type', TYPES, 'Simple', 'Capitalize')
    const bgimg = urlf(oget(props.content,['baseurl']),oget(props,['bgimg']))
    const img = urlf(oget(props.content,['baseurl']),oget(props,['img']))
    let style = {fontFamily: 'Poppins'}
    if(bgimg) style = {
        backgroundImage:`url(${bgimg})`, backgroundSize:'cover', backgroundPosition:'50% 50%', background:props.bg||'transparent'
    }
    const size = get_size(w)
    // console.log(w,size)
    const mobile = w<=W2
    const mt1 = props.idx===0 ? (props.mobile ? 50:90) : 0
    const pt1 = props.idx===0 ?  (mobile ? 50:50) : 0
    const pv = props.idx===0 ?  0 : (mobile ? 70:100)

    const PH = [20,30,30,50,100,100,100]
    const ph = PH[size]

    return(
        <Box ref={ref} cls="_Slide" id={props.id||null} w100 center pt={pt1}>
            <Box cls="_SlideContentBackground" w100 minw={MINW} maxw={MAXW} center bg={props.bg} style={style}>
                <Box cls="_SlideContent" w100 minw={MINW} maxw={MAXW_CONTENT} ph={ph} pt={pv} pb={pv/2} center>
                    {render_slide()}
                </Box>
            </Box>
        </Box>
    )
}

const check_path = (slideId, selectedHash) => {
    if(slideId==null || !is_string(selectedHash)) return false
    if(slideId==='home' && selectedHash.length<2) return true
    return slideId===selectedHash.substring(1)
}

const get_size = (w) => {
    const WS = [W0,W1,W2,W3,W4,W5]
    for(let i=0; i<WS.length; i++)
        if(w<=WS[i]) return i
    return WS.length-1
}

    /* ****************************************************************************************************
    *   render_radial_call_out
    *  ************************************************************************************************* */

    /*

        const render_radial = (x, y) => {
            const b1 = '2px solid rgba(235, 242, 245, 0.4)'
            const b2 = '1px solid rgba(235, 242, 245, 0.8)'
            const bg1 = 'radial-gradient(66.95% 66.95% at 50% 50%, rgba(119, 171, 189, 0.186) 0%, rgba(235, 242, 245, 0) 100%)'
            const bg2 = 'radial-gradient(66.95% 66.95% at 50% 50%, rgba(119, 171, 189, 0.372) 0%, rgba(235, 242, 245, 0) 100%)'
            const bg3 = 'radial-gradient(66.95% 66.95% at 50% 50%, rgba(119, 171, 189, 0.465) 0%, rgba(235, 242, 245, 0) 100%)'
            const style4 = {boxShadow:'box-shadow: 0px 0px 4.26667px rgba(3, 42, 109, 0.6)', border: '4.26667px solid #77ABBD'}
            return (
                <Box w={112} h={112} left={x} top={y} b={b1} bg={bg1} br={'50%'}>
                    <Box w={80} h={80} left={112/2-80/2} top={112/2-80/2} b={b2} bg={bg2} br={'50%'}></Box>
                    <Box w={48} h={48} left={112/2-48/2} top={112/2-48/2} br={'50%'} bg={bg3}/>
                    <Box w={16} h={16} left={112/2-16/2} top={112/2-16/2} br={'50%'} white style={style4}/>
                </Box>
            )
        }
    
        const render_ballon = (x, y) => {
            return(
                <Box column w={256} h={84} left={x} top={y} ph={32} pv={20} bg={'#EBF2F5'} br={'20px 20px 20px 0px'}>
                    <Text w100 lg bold mb={5} color="pri160" text="AI Diagnostic"/>
                    <Text w100 lg color="pri160" text="Interpret every ECG feature"/>
                </Box>
            )
        }
    
        const render_radial_call_out = () => {
            const x = 990
            const y = 190
            const dx = 100
            const dy = -70
            return(<>
                {render_radial(x,y)}
                {render_ballon(x+dx,y+dy)}
            </>)
        }
    
    */