import React, { useState, useRef, createRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import Box from './Box'
import Input from './Input'
import Button from './Button'
import Message from './Message'
import { is_array } from '../js/util.js'
import { wrequest } from '../js/web.js'

export default (props) => {
    const [frefs,setFrefs] = useState([])
    const [requiresCheck,setRequiresCheck] = useState([])
    const [canSubmit,setCanSubmit] = useState(false)
    const [mode,setMode] = useState('Form')

    useEffect(() => {
        if(props.fields) {
            setFrefs(Array(props.fields.length).fill().map((_,i)=>frefs[i] || createRef()))
            setRequiresCheck(Array(props.fields.length).fill().map((_,i)=>requires_check(props.fields[i])))
        }
     }, [props.fields])

     const onSubmit = async (d) => {
        const res = await wrequest(props.url, 'POST', d)
        console.log(res)
        if(res==null || res.status==='ERROR') {setMode('Error'); return}
        setMode('Done')
    }

     const onFieldChange = (k, v, check) => {
        for(let i=0; i<props.fields.length; i++) {
            if(!requiresCheck[i]) continue
            const c = i===k ? check : frefs[i].current.getCheck()
            if(!c) {setCanSubmit(false); return}
        }
        setCanSubmit(true)
     }
    
    const onDone = () => {
        if(!canSubmit) return
        const d = {}
        for(let i=0; i<frefs.length; i++)
            d[props.fields[i].name] = frefs[i].current.getValue()
        onSubmit(d)
        if(props.onDone) props.onDone(d)
    }

    if(!is_array(props.fields) || frefs==null) return null
    if(mode==='Error') return <Message warning title="Try again" text="Upload error" button="Retry" onClick={()=>setMode('Form')}/>
    if(mode==='Done') return <Message success title="Message sent" text="" button="Done" onClick={()=>setMode('Form')}/>
    return(
        <Box w100 ph={50} pv={30}>
            <Box column>
                {props.fields.map((e,i)=>
                    <Input layout={props.layput} key={i} ref={frefs[i]} k={i} size={e.size} type={e.type} label={e.label} placeholder={e.placeholder}
                           minlen={e.minlen} onChange={onFieldChange}/>
                )}
            </Box>
            <Box w100 bottom>
                {props.button && canSubmit && <Button w100 action text={props.button} onClick={onDone}/>}
            </Box>
        </Box>
    )
}

const requires_check = (e) => {
    if(!e) return false
    const t = e.type ? e.type.toLowerCase() : 'text'
    if(t==='long' && !e.minlen) return false
    if(t==='text' && !e.minlen) return false
    return true
}