import React, { useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router-dom'
import useClickOutside from '../hooks/useClickOutside'
import { Link } from 'react-router-dom'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Triangle from './Triangle'
import Button from './Button'
import Option from './Option'
import { css_flex, get_prop, is_sub_link, compose_url, is_obj, is_array } from '../js/util'

import Notifications from '../components/Notifications'

export default (props) => {
  const history = useHistory()
  const ref = useRef(null)
  const [options, setOptions] = useState(null)
  const [open, setOpen] = useState(false)
  const [selected, setSected] = useState(0)

  useClickOutside(ref, () => {
    setOpen(false)
  })

  useEffect(() => {
    if(props.options) setOptions(props.options.map(e=>typeof e==='object'? e : ((type==='Icons') ? {icon:e}:{name:e})))
  }, [props.options])

  useEffect(() => {
    if(props.selected!==undefined && props.selected!==null) setSected(props.selected)
  }, [props.selected])

  const onSelect = (e, i, ev) => {
    if(props.toggle) {
      const s = !selected
      setSected(s)
      if(props.onSelect) props.onSelect(s,e,ev)
      return
    }
    if(i===selected) return
    setOpen(false)
    setSected(i)
    close_all()
    if(e.submenu) {
      e.open=true
      select2_clear(e.submenu)
      const first = select2_first(e.submenu)
      first.selected = true
      setTimeout(()=>history.push(compose_url(e.path,first),0))
    }
    if(props.onSelect) props.onSelect(i,e,ev)
    else if(e.path) history.push(e.path)
  }
  const onSelect2 = (e, i, parent, path) => { if(parent) unselect2(parent); e.selected=true; history.push(compose_url(path,e))}

  const close_all = () => options.map((e,i)=>e.open=false)
  const unselect2 = (e) => e.children ? e.children.map((e1)=>e1.selected=false):null

  const select2_clear = (submenu) => submenu.map(e=>e.children?e.children.map(e1=>e1.selected=false):e.selected=false)
  const select2_first = (submenu) => submenu[0].children ? submenu[0].children[0] : submenu[0]

  const get_submenu_item = (e,i,parent,path) => {
    const text_props = {dark:props.pri100, text:e.name}
    if(e.children) {
      return [
        <Text {...text_props} fs={12} caps dim mt={5} mb={5}/>,
        <Box w100 ml={5}>
          {e.children.map((e1,i1) => get_submenu_item(e1,i1,e,path))}
        </Box>
      ]
    }
    return <Text w100 fs={14} {...text_props} pointer selected={e.selected} onClick={()=>onSelect2(e,i,parent,path)}/>
  }

  const get_submenu = (submenu, path) => {
    if(!submenu) return null
    return(
      <Box w100 ml={30}>
        {submenu.map((e,i) =>
          <Box key={i} w100>
            {get_submenu_item(e,i,submenu,path)}
          </Box>)}
      </Box>
    )
  }

  const get_selected_idx = (n) => {
    if(type==='Pulldown') return null
    if(!is_obj(selected)) return selected
    let i = options.findIndex(e=>e.name===selected.name)
    if(i!=-1) return i
    return 0
  }

  const render_icons_menu = () => {
    return(
      <Box cls="_Options">
        {options.map((e,i) => {
          let sel = props.toggle ? selected : i==selected
          return <Icon key={i} id={i} icon={e.icon} size={20} m="auto 0px" pad={2} white b={[H,i,options.length]}
                       first={i==0} last={i==options.length-1} inactive={e.inactive}
                       selected={sel} onClick={(ev)=>onSelect(e,i,ev)}/>
        })}
      </Box>
    )
  }

  const render_buttons_menu = () => {
    return(
      <Box cls="_Options">
        {options.map((e,i) => {
          let sel = props.toggle ? selected : i==selected
          return <Button box key={i} id={i} icon={e.icon} size={e.large?32:16} b={[H,i,options.length]}
                         first={i==0} last={i==options.length-1} inactive={e.inactive}
                         selected={sel} onClick={()=>onSelect(e,i)}/>
        })}
      </Box>
    )
  }

  const render_sidenav_menu = () => {
    return(
      <Box cls='_Options' w100 column>
        {options.map((e,i) => {
          if(e.visible===false) return null
          return <Option key={i} selbar selected={i===selected} minimized={props.minimized} lg url={e.path} icon={e.icon} text={e.name}/>
        })}
      </Box>
    )
  }

  const onPullClick = (e) => {
    if(e.options || e.component) {
      if(open!==e.name)
        setOpen(e.name)
      else
      setOpen(null)
    }
  }

  const render_pullright_menu = () => {
    if(!is_array(options)) return null
    return(
      <Box ref={ref} w100>
        {options.map((e,i)=>{
          if(e.visible===false) return null
          const Comp = e.component ? ()=><e.component/> : null
          const sel = open===e.name
          return(
            <Box w100 key={i}>
              <Option key={i} selected={sel} minimized={props.minimized} lg icon={e.icon} text={e.name} icon2={e.options?"right":null}
                      onClick={()=>onPullClick(e)}/>
              {e.component && open==e.name && <Comp/>}
              {is_array(e.options) && open==e.name && 
                <Box w={300} pad={20} dark br top={-100} left={210} z={100}>
                  <Triangle type="left" left={-10} top={120} size={10} color="dark"/>
                  {e.options.map((e1,i1)=>{
                    return <Option key={i1} selected={null} lg url={e1.path} icon={e1.icon} text={e1.name||e1.text} onClick={()=>setOpen(false)}/>
                  })}
                </Box>}
            </Box>)
        })}
      </Box>
    )
  }

  const render_pulldown_menu = () => {
    return(
      <Box ref={ref} {...props}>
        <Icon size={24} icon={props.icon} onClick={()=>setOpen(!open)}/>
        {open &&
          <Box left={-25} top={-30} minw={300} minh={400} z={100} pad={20} white br="n60" bb="n60">
            <Icon left={25} top={30} size={24} z={1000} icon={props.icon} onClick={()=>setOpen(false)}/>
            <Box w100 h100 ph={30} pv={80}>{render_text_menu('V')}</Box>
          </Box>
        }
      </Box>
    )
  }

  const render_option = (e, i, mode='H') => {
    let sel = props.toggle ? selected : i==selected
    if(e.visible===false) return null
    if(props.item) return props.item(e, i, sel)
    const text = is_obj(e) ? e.name : e
    const color = props.dark ? (sel ? 'high100' : 'n100') : (sel ? 'pri160' : 'pri160')
    const fs = sel ? 14 : 14
    const bb = sel ? 'high100' : null
    const w1 = mode==='H' ? 'auto' : '100%'
    const h1 = mode==='H' ? '100%' : 50
    const h2 = mode==='H' ? '100%' : 25
    const center = mode==='H' ? true : false
    return(
        <Box key={i} href={e.path} w={w1} h={h1} mh={10} onClick={(ev)=>onSelect(e,i,ev)}>
          <Box h={h2} bb={bb} ph={5} center={center}>
            <Text mb={5} center={center} fs={fs} color={color} text={text}/>
          </Box>
        </Box>
    )
  }

  const render_text_menu = (mode='H') => {
    const h1 = mode==='H' ? 30 : '100%'
    const column = mode==='H' ? null : true
    return(
      <Box column={column} cls="_Options" centerv h={h1}>
        {props.options.map((e,i)=>render_option(e,i,mode))}
      </Box>
    )
  }

  const render_menu = () => {
    if(!type || !options) return null
    switch(type) {
      case 'Icons':
        return render_icons_menu()
      case 'Buttons':
        return render_buttons_menu()
      case 'Sidenav':
        return render_sidenav_menu()
      case 'Pullright':
        return render_pullright_menu()
      case 'Pulldown':
        return render_pulldown_menu()
      case 'Vertical':
        return render_text_menu('V')
      case 'Text':
      case 'Horizontal':
      default:
        return render_text_menu('H')
    }
  }

  const TYPES = ['Icons', 'Buttons', 'Sidenav', 'Pullright', 'Pulldown', 'Vertical', 'Horizontal']
  const type = get_prop(props, 'type', TYPES, 'Text', 'Capitalize')
  let H = get_prop(props, 'type', ['Horizontal', 'Vertical'], 'Horizontal', 'Capitalize') == 'Horizontal'
  if(type==='Pulldown') H=false
  
  return(
    <Box cls='_Menu' mh={props.mh||0} mv={props.mv||0} alignright={props.alignright}>
      {render_menu()}
    </Box>
  )
}


const get_margins = (props, H) => {
  if(H) return 'auto 20px auto 0px'
  if(props.right) return '5px 0px 5px auto'
  return '5px 0px 5px 0px'
}

const get_pad = (props, H) => {
  if(props.compact) return '0px 0px'
  return H?'0px 10px':'0px 0px'
}
