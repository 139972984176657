import React, { useState, useEffect, createContext } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
// https://v5.reactrouter.com/web/example/recursive-paths
import './App.css'
import Box from './components/Box'
import WebPage from './components/WebPage'
import Header from './components/Header'
import Footer from './components/Footer'
import SideNav from './components/SideNav'
import Login from './components/Login'
import Admin from './components/Admin'
import Under from './components/Under'
import Modal from './components/Modal'
import LOGO from './assets/Logo.png'
import { oget, is_array, urlf } from './js/util'
import { wrequest, request } from './js/web'
import { set_palette } from './js/palette'
import { WEBAPP, WEBSITE } from './Config'
import {AppContext } from './AppContext'

export const USEAUTH = WEBAPP ? true:false

export default function App(props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [content, setContent] = useState(null)
  const [logos, setLogos] = useState(null)
  const [meta, setMeta] = useState(null)
  const [auth, setAuth] = useState(null)
  const [online, setOnline] = useState(navigator.onLine)

  useEffect(() => {
    window.addEventListener('dragover', e=>e.preventDefault(), false)
    window.addEventListener('drop', e=>e.preventDefault(), false)
    window.addEventListener('online',  _ => setOnline(navigator.onLine))
    window.addEventListener('offline', _ => setOnline(navigator.onLine))
  }, [])

  useEffect(() => {
    if(USEAUTH) {
      if(localStorage.jwt_token && localStorage.user_info)
        startup()
      else {
        setAuth(false)
        setMeta({webapp:WEBAPP})
      }
    } else {
      setMeta({webapp:WEBAPP})
    }
    if(WEBSITE) {
      fetch(`/content/${WEBSITE}.json`, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
        .then(res=>res.json())
        .then(res=>setContent(res))
        .then(()=>setLoading(false))
        .catch(err=>{console.log('Fetch content error: ', err); setContent(null)})
    }
    else setLoading(false)
  }, [])

  useEffect(() => {
    setLogos(get_logos(content, WEBAPP, LOGO))
  }, [content])

  const startup = async () => {
    const meta = await wrequest('/api/get_meta', 'GET', {})
    if(!meta) {set_error('Metadata service error', ''); return}
    meta.user_info = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : null
    meta.webapp = WEBAPP
    if(WEBAPP && WEBAPP.startup) WEBAPP.startup(meta)
    setMeta(meta)
    setAuth(true)
  }

  const remove_auth_tokens = () => {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('user_info')
  }

  const set_error = (msg1, msg2) => {localStorage.removeItem('jwt_token'); console.log(`${msg1}: ${msg2}`); setAuth(false); setError(msg1)}
  const onAuth = (b) => setAuth(b)

  const render_error = () => {
    return(
      <Box w={500} h={500} center centerv red></Box>
    )
  }

  const render_loading = () => {
    return null
  }

  const render_website = () => {
    if(!content || oget(content,['under']))
      return <Under meta={meta} content={content} logo={oget(logos,['logo'])}/>
    if(meta) meta.content = content
    return(
      <Switch>
        <WebPage meta={meta} content={content} logos={logos}/>
      </Switch>
    )
  }

  const render_login = () => {
    const style = {fontFamily: "'Roboto', sans-serif"}
    return(
      <Box column cls="_WebApp" w="100vw" h="100vh" n20 style={style}>
        <Switch>
          <Route exact path="/auth/login"><Login meta={meta} onAuth={onAuth} logo={oget(logos,['logo'])} online={online}/></Route>
          <Route exact path="/auth/set_passwd"><Login meta={meta} onAuth={onAuth} logo={oget(logos,['logo'])} online={online}/></Route>
          <Route path="/"><Redirect to="/auth/login"/></Route>
        </Switch>
      </Box>
    )
  }

  const render_app_routes = (menu, DefaultComponent) => {
    if(!menu && DefaultComponent)
      return <DefaultComponent meta={meta} content={content}/>
    const logo = oget(logos,['logo'])
    return (
      <Switch>
        {menu && menu.map((e,i) => {
          if(!e.component) return null
          return <Route key={i} exact path={e.path}><e.component meta={meta} content={content} {...e}/></Route>
        })}
        <Route key="001" exact path="/app/change_passwd"><Login meta={meta} onAuth={onAuth} logo={logo} online={online}/></Route>
        <Route key="002" exact path="/app/logout"><Login meta={meta} onAuth={onAuth} logo={logo} online={online}/></Route>
        <Route key="003" exact path="/app/admin"><Admin meta={meta}/></Route>
        <Route key="004" exact path="/app"><DefaultComponent meta={meta} content={content}/></Route>
        {!content && <Route key="998" path="/"><Redirect to="/app"/></Route>}
        {content && <Route key="999" path="/">{render_website()}</Route>}
      </Switch>
    )
  }

  const render_webapp_page = (menu, DefaultComponent) => {
    const menu2 = get_menu2(meta, auth)
    if(WEBAPP.nav!=='Left')
      return <Box w100>{render_app_routes(menu, DefaultComponent)}</Box>
    return(
      <SideNav meta={meta} menu={menu} menu2={menu2} useauth={USEAUTH} auth={auth} logos={logos} update online={online}>
        {render_app_routes(menu, DefaultComponent)}
      </SideNav>
    )
  }

  const render_webapp = () => {
    if(!WEBAPP) return null
    // const menu =  auth ? WEBAPP.menu_private : WEBAPP.menu_public
    // const menu2 = auth ? WEBAPP.menu2 : null
    
    const menu =  true ? WEBAPP.menu_private : WEBAPP.menu_public
    const menu2 = true ? WEBAPP.menu2 : null

    const DefaultComponent = (menu && menu.length) ? menu[0].component : null
    const show_loading = !error && auth===null
    const show_login = !error && USEAUTH && auth===false
    const show_page = !error && !show_loading && !show_login
    const footerinfo = oget(meta,['footerinfo'])
    set_palette(WEBAPP.palette)
    const style = {fontFamily: "'Roboto', sans-serif"}
    return(
      <Switch>
        <Box w100 column cls="_WebApp" h="100vh" n20 style={style}>
          {WEBAPP.nav==='Top' &&
            <Header useauth={USEAUTH} auth={auth} webapp={WEBAPP} meta={meta} logo={logos.logo} menu={menu} menu2={menu2} update online={online}/>}
          {render_webapp_page(menu, DefaultComponent)}
          <Footer webapp meta={meta} content={content} info={footerinfo}/>
          <Modal id="Global"/>
        </Box>
      </Switch>
    )
  }

  const render_main = () => {
    if(loading) return render_loading()
    if(error) return render_error()
    const can_login = !(USEAUTH && auth===false)
    if(content===undefined) return null
    if(!WEBAPP && !content) return null
    if(!WEBAPP && content)
      return(
        <Switch>
          <Route key="04" path="/">{render_website()}</Route>
        </Switch>
      )
    return(
      <Switch>
        {can_login && <Route key="01" path="/app">{render_webapp()}</Route>}
        {!can_login && <Route key="02" path="/app">{render_login()}</Route>}
        <Route key="03" exact path="/auth/login">{render_login()}</Route>
        {content && <Route key="04" path="/">{render_website()}</Route>}
        {!content && <Route key="998" path="/"><Redirect to="/app"/></Route>}
      </Switch>
    )
  }

  // remove_auth_tokens()  // For debug, to reset auth tokens
  return(
    <AppContext.Provider value={meta}>
      <BrowserRouter>
        {render_main()}
      </BrowserRouter>
    </AppContext.Provider>
  ) 
}

const get_logos = (content, webapp, LOGO) => {
  const baseurl = oget(webapp, ['baseurl'])
  const defaul_logos = {logo: urlf(oget(content,['baseurl']),oget(content,['logo']))}
  if(!baseurl || (content && !webapp))
    return defaul_logos
  return {
    logo: urlf(baseurl, oget(webapp,['logos','logo'])),
    logodark: urlf(baseurl, oget(webapp,['logos','logodark'])),
    logodarkmin: urlf(baseurl, oget(webapp,['logos','logodarkmin'])),
    logo2dark: urlf(baseurl, oget(webapp,['logos','logo2dark'])),
    logo2darkmin: urlf(baseurl, oget(webapp,['logos','logo2darkmin']))
  }
}

const get_menu2 = (meta, auth) => {
  if(!auth || !meta.webapp) return null
  const role = oget(meta,['user_info','role'])
  const menu2 = meta.webapp.menu2
  if(!menu2) return null
  if(role!='su' && role!='admin') return menu2
  let options = oget(menu2,[0,'options'])
  if(!is_array(options)) options = []
  options.push({name: 'Admin', icon: 'admin', path: '/app/admin'},)
  return menu2
}
