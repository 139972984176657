import React, { useState, useEffect, useRef, useCallback } from 'react'
import Box from './Box'
import Icon from './Icon'
import { ALIGNS, FORMATS, get_pass_props, get_pass_int_props, get_prop,
         set_width, set_height, set_margin, set_padding, set_border,
         set_absolute, get_background, set_color } from '../js/util'
import { formater } from '../js/field'

const FS = {H0:50, H1:32,  H2:28,  SH1:24,  SH2:22,  SH3:22,  SH4:20,  SH5:20,  BT1:18,  BT2:18,  BT3:17,  B1:16,  B2:16,  B3:16, 
    LG:14,  MD:12,  SM:10,  XS:8}
const FW = {H0:700, H1:600, H2:500, SH1:600, SH2:700, SH3:400, SH4:700, SH5:600, BT1:600, BT2:400, BT3:500, B1:700, B2:400, B3:400, LG:400, MD:400, SM:400, XS:400}

export default (props) => {
    let size = get_prop(props, 'size', Object.keys(FS), 'MD', 'Uppercase')
    let format = get_prop(props, 'format', FORMATS, 'S', 'Uppercase')
    let align = get_prop(props, 'align', ALIGNS, null, 'Capitalize')

    const box_style = {flexWrap: 'nowrap', overflow: 'hidden'}
    set_width(props, box_style)
    set_height(props, box_style)
    set_margin(props, box_style)
    set_padding(props, box_style)
    set_border(props, box_style)
    set_absolute(props, box_style)
    if(props.br) box_style.br = props.br
    if(props.onClick||props.pointer) box_style.cursor='pointer'
    box_style.cursor='pointer'

    const style = {textAlign: 'left', color: 'n100', fontWeight:FW[size]}
    // style.fontSize = FS[size]
    style.fontSize = 10
    if(props.fs) style.fontSize=props.fs
    set_color(props, style)
    if(props.center) {style.textAlign='center';style.margin='auto auto'}
    if(props.right) {style.textAlign='right'; style.marginLeft='auto'; style.marginRight=0}
    if(props.nowrap) style.whiteSpace = 'nowrap'
    if(align) style.textAlign = align
    if(align==='center') {style.marginLeft='auto'; style.marginRight='auto'}
    if(props.br) box_style.borderRadius = props.br===true ? 10:props.br
    style.lineHeight = props.lineHeight || 1.2
    if(props.bold) style.fontWeight=600
    if(props.italic) {style.fontStyle='italic'; if(!style.paddingRight) style.paddingRight=3}
    if(props.noselect) {style.userSelect='none'; style.pointerEvents='none'}
    if(props.noselect) style.WebkitUserSelect='None'

    let text = props.text!=null ? props.text : props.children
    if(text && props.caps) text = text.toUpperCase()
    if(format!=='S') text = formater(format, text)
    const onClick = props.onClick ? (ev)=>props.onClick(props.id, ev) : null

    return(
        <Box cls="_TextBox" minw={props.minw} bg={props.bg} nowrap pointer={props.pointer} href={props.href} link={props.link}
             style={box_style} onClick={onClick}>
            {props.icon && <Icon size={14} icon={props.icon} color={style.color} mr={10}/>}
            <p style={style}>{text}</p>
        </Box>
    )
}
