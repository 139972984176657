import React, { useState, useEffect, useRef} from 'react'
import Box from './Box'
import { get_color } from '../js/util'

export default (props) => {
    const sz = props.size
    const color = get_color(props.color)
    const style_left = { borderRight: `solid ${sz}px ${color}`, borderBottom: `solid ${sz}px transparent`,
                         borderTop: `solid ${sz}px transparent`}
    const style_right = { borderLeft: `solid ${sz}px ${color}`, borderBottom: `solid ${sz}px transparent`,
                         borderTop: `solid ${sz}px transparent`}
    const style_up = { borderRight:`solid ${sz}px transparent`, borderLeft: `solid ${sz}px transparent`,
                         borderBottom: `solid ${sz}px ${color}` }
    const style_down = { borderRight: `solid ${sz}px transparent`, borderLeft: `solid ${sz}px transparent`,
                         borderTop: `solid ${sz}px ${color}`}
    let STYLES = {'left': style_left, 'right': style_right, 'up': style_up, 'down': style_down}
    const style = STYLES[props.type]
    return <Box w={sz} h={sz} left={props.left} right={props.right} top={props.top} bottom={props.bottom} style={style}/>
  }