import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import useWindowDims from '../hooks/useWindowDims'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Button from './Button'
import Image from './Image'
import Datetime from './Datetime'
import Menu from './Menu'
import { oget, getDevice, menu_find } from '../js/util'

const W0 = 320
const W1 = 480
const W2 = 820
const W3 = 1024
const W4 = 1366
const W5 = 2560

const MAXW = 3000
const MAXW_CONTENT = W4
const MINW = W0

export default (props) => {
    const { pathname, hash, key } = useLocation()
    const history = useHistory()
    const [mobile, setMobile] = useState(null)
    const [selected, setSelected] = useState(false)
    const [w, h] = useWindowDims()

    useEffect(() => {
        if(props.menu) setSelected(menu_find(props.menu, pathname))
        if (hash === '') {
            window.scrollTo(0, 0);
        } else {
            setTimeout(() => {
              const id = hash.replace('#','')
              const el = document.getElementById(id)
              if(el) {el.scrollIntoView()}
            }, 0)
          }
    }, [pathname, hash, key])

    useEffect(() => {
        if(props.menu) render_menu(500, props.menu, null)
    }, [selected])

    useEffect(() => {
        // const m = getDevice()==='mobile' || window.innerWidth<900  // window.screen.width
        setMobile(w<1024)
    }, [w,h])

    const render_logo = (mobile=false) => {
        const align = mobile?'right':'left'
        const mr = mobile?20:0
        const url = mobile ? props.logomin : props.logo
        const w1 = mobile ? 30 : (props.webapp ? 180:220)
        const h1 = mobile ? 30 : 50
        return(
            <Box onClick={()=>history.push('/#home')} align={align}>
                <Box centerv mr={mr}>
                    <Image h={h1} iurl={url}/>
                </Box>
                {props.logo2 && <Box w={w1} centerv>
                    <Image h100 iurl={props.logo2}/>
                </Box>}
            </Box>
        )
    }

    const render_menu = () => {
        if(!props.menu) return null
        if(props.backMode)
            return(
                <Box centerv onClick={()=>history.goBack()}>
                    <Icon icon="back" ml={50} onClick={()=>history.goBack()}/>
                    <Text fs={16} ml={20} color="" text={props.backMode}/>
                </Box>
            )
        return(
            <Menu alignright={!props.webapp} lg={props.lg} link options={props.menu} selected={selected} onClick={(i)=>setSelected(i)}/>
        )
    }

    const render_update = () => {
        return(
            <Box h100 mh={20}>
                {props.update && <Datetime horizontal value={oget(props,['meta','etl','day_hour'])}/>}
            </Box>
        )
    }

    const render_profile = () => {
        return(
            <Box right>
                {props.auth && props.menu2 && <Menu pulldown right icon="profile" options={props.menu2}/>}
            </Box>
        )
    }

    const render_login = (mobile=false) => {
        const render_text = (w1) =><Text fs={16} bold color="high100" text="Login" onClick={()=>history.push('/app')}/>
        if(mobile)
            return <Box right={30} top={15} alignright> {render_text(30)}</Box>
        return <Box alignright centerv>{render_text(40)}</Box>
    }

    const render_burger = () =>
        <Box>
            <Menu pulldown left={20} top={10} z={100} h1={props.webapp?50:90} icon="burger" options={props.menu}/>
        </Box>

    // const error_msg = (!("online" in props) || props.online) ? null : "BROWSER OFFLINE"

    let style = {}
    if(!props.webapp) style={position:'fixed', 'top':0, 'left':0, zIndex:1, opacity:.95}

    if(mobile)
        return(
            <Box cls="_Header" w100 h={50} bb='n40' style={style}>
                 {props.menu && render_burger()}
                 <Box w100 h100 contentcenter>{render_logo(true)}</Box>
                 {props.login && render_login(true)}
                 {props.auth && props.menu2 && render_profile()}
            </Box>
        )

    return(
        <Box cls="_Header" w100 h={props.lg?90:50} style={style}>
            <Box cls="_HeaderBackground" w100 h100 minw={MINW} maxw={MAXW} center white>
                <Box cls="_HeaderContent" w100 h100 minw={MINW} maxw={MAXW_CONTENT} ph={mobile?0:100} center>
                    {render_logo()}
                    {render_menu()}
                    {props.update && render_update()}
                    {props.login && render_login()}
                    {render_profile()}
                </Box>
            </Box>
        </Box>
    )
}
