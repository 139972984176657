import React, { useState, useEffect, useRef, useContext } from 'react'
import Box from './Box'
import Text from './Text'
import { oget, urlf, get_prop, get_size, css_flex, set_margin} from '../js/util'

/* *
 * Image Component
 *
 * A versatile and customizable image component that supports different types, sizes, and styles.
 *
 * Props:
 * - meta: Object containing metadata for the component, namely the baseurl
 * - type: One of the following string values: 'Icon', 'Cell', 'Cellh', 'Cellv', 'Normal'. Default is 'Normal'.
 * - iurl: Optional string for image URL (overrides src property)
 * - lurl: Optional string for image URL (overrides src property)
 * - src: Optional string for image URL
 * - onClick: Optional function to handle click events on the component
 * - id: Optional identifier to pass to the onClick handler
 * - text: Optional string to display below the image
 * - w: Optional width of the image
 * - h: Optional height of the image
 * - w100: Optional boolean to set the image width to 100%
 * - h100: Optional boolean to set the image height to 100%
 * - cover: Optional boolean to set the object-fit property of the image to 'cover'
 * - pos: Optional string to set the object position of the image
 * - bg: Optional boolean to render the image as a background image
 * - op: Optional number to set the opacity of the background image overlay
 * - url: Optional string to set the background URL of the Box component
 *
 * Usage:
 * ```jsx
 * <ImageComponent
 *   meta={meta}
 *   type="Cell"
 *   src="/path/to/image.jpg"
 *   onClick={handleClick}
 *   id="image_id"
 *   text="Image description"
 *   w={200}
 *   h={100}
 *   w100={true}
 *   h100={true}
 *   cover={true}
 *   pos="50% 0%"
 *   bg={true}
 *   op={0.5}
 *   url="/path/to/background.jpg"
 * />
 * ```
 */
  
export default (props) => {
    const meta = props.meta
    const type = get_prop(props, 'type', ['Icon','Cell', 'Cellh', 'Cellv', 'Normal'], 'Normal', 'Capitalize')
    const size = get_size(props)

    const get_src = () => {
        if(props.iurl) return props.iurl
        if(props.lurl) return props.lurl
        if(!props.src) return null
        let baseurl = oget(meta,['webapp','baseurl'])
        if(!baseurl) baseurl = oget(meta,['content','baseurl'])
        return urlf(baseurl, props.src)
    }

    const render_cell = () => {
        const WS = {'Cell':50, 'Cellh':100, 'Cellv':60}
        const HS = {'Cell':28, 'Cellh':20, 'Cellv':20}
        const w1 = WS[type]
        const h1 = HS[type]
        const wi = type==='Cellv' ? WS[type] : 'auto'
        const hi = (type==='Cellh' || type==='Cell') ? HS[type] : 'auto'
        const right = type==='Cellv'
        const im = type==='Cellv' ?  'auto 0px auto auto' : 'auto'
        const stylei = {width: wi, height: h1, margin: im, objectFit:'contain', objectPosition: '50% 0%'}
        const text = props.text || ''
        return(
            <Box cls="_CellImage" w100 onClick={()=>props.onClick?props.onClick(props.id):null}>
                <Box w={w1} alignright={right}>
                    <img style={stylei} src={src}/>
                </Box>
                {props.text && <Box wz={w1}><Text mv='auto' fs={14} text={text}/></Box>}
            </Box>
        )
    }

    const render_image_text = () => {
        const HS = {'XS':50, 'SM':100, 'MD':150, 'LG':200, 'XL':300}
        const h1 = HS[size]
        const style0 = {...css_flex(), width:'auto', height:'auto'}
        const style1 = {...css_flex(), width:'auto', height:h1, overflow:'hidden'}
        const style2 = {...css_flex(), width:`100%`, height:20, overflow:'hidden'}
        const stylei = {height: h1, margin: 'auto auto', objectFit:'contain', objectPosition: '50% 0%'}
        const text = props.text || ''
        return(
            <Box style={style0} onClick={()=>props.onClick?props.onClick(props.id):null}>
                <Box style={style1}>
                    <img style={stylei} src={src}/>
                </Box>
                <Box style={style2}>
                    <Text fs={14} text={text}/>
                </Box>
            </Box>
        )
    }

    const render_background_image = () => {
        const h = props.h||'100%'
        const op = props.op || 0
        const style1 = {...css_flex(), position:'absolute', 'top':0, 'left':0, width:'100%', height:h, overflow:'hidden'}
        set_margin(props, style1)
        const style2 = {position:'absolute', 'top':0, 'left':0, width:'100%', height:h, background: "#000000", opacity:op, zIndex:1}
        const style3 = {width:'100%', height:'auto', objectFit:'cover'}
        return(
            <Box cls="_Image" style={style1} url={props.url}>
                <div className="_ImageOverlay" style={style2}/>
                <img style={style3} src={src}/>
            </Box>
        )
    }

    const render_image = () => {
        let w1 = props.w || '100%'
        let h1 = props.h || 'auto'
        const img_style = {width:'100%', height:'auto', objectFit:'contain'}
        if(props.w100) {w1='100%';h1='auto';img_style['width']='100%';img_style['height']='auto'}
        if(props.h100) {w1='auto';h1='100%';img_style['width']='auto';img_style['height']='100%'}
        if(props.w) {w1=props.w;h1='auto';img_style['width']='100%';img_style['height']='auto'}
        if(props.h) {w1='auto';h1=props.h;img_style['width']='auto';img_style['height']='100%'}
        if(props.cover) {img_style['objectFit']='cover'}
        if(props.pos) {img_style['objectPosition'] = props.pos}
        return(
            <Box cls="_Image" w={w1} h={h1} mr={props.mr} mb={props.mb} url={props.url} center={props.center}>
                <img style={img_style} src={src}/>
            </Box>
        )
    }

    const src = get_src()
    if(['Cell', 'Cellh', 'Cellv'].includes(type)) return render_cell()
    if(props.bg) return render_background_image()
    if(props.text) return render_image_text()
    return render_image()
}
