import React, { useState, useEffect, useRef } from 'react'

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments);
      }, ms)
    }
}

export default function useWindowDims() {
    const [wdims, setDimension] = useState([window.innerWidth, window.innerHeight,])
    useEffect(() => {
      const debouncedResizeHandler = debounce(() => {
        // console.log('***** debounced resize')
        setDimension([window.innerWidth, window.innerHeight])
      }, 100)
      window.addEventListener('resize', debouncedResizeHandler)
      return () => window.removeEventListener('resize', debouncedResizeHandler)
    }, [])
    return wdims
}