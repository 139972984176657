
// export {WEBAPP} from './kardio/Config.js'
// export const WEBSITE = 'cardiolife.global'

// export {WEBAPP} from './pw/Config.js'
// export const WEBSITE = null

export const WEBAPP = null
export const WEBSITE = 'dl2050.com'

// export {WEBAPP} from './test/Config.js'
// export const WEBSITE = null

// export const WEBAPP = null
// export const WEBSITE = 'anarp.org.pt'
