import React, { useState, useRef, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Spinner from './Spinner'
import Image from './Image'
import Field from './Field'
import Button from './Button'
import Form from './Form2'
import { wrequest } from '../js/web'
import { is_obj, oget, urlf } from '../js/util'
// https://levelup.gitconnected.com/using-jwt-in-your-react-redux-app-for-authorization-d31be51a50d2

const USER = {type:'User', name:'email', label:'Email', size:'lg', placeholder:'Enter your email'}
const PASSWD = {type:'Password', name:'password', label:'Password', size:'lg', placeholder:'Enter your password'}
const PASSWD1 = {type:'Password1', name:'password1', label:'Password', size:'lg', placeholder:'Enter a new password'}
const PASSWD2 = {type:'Password2', name:'password2', label:'Repeat Password', size:'lg', placeholder:'Retype the password'}
const OTP = {type:'OTP', name:'otp', label:'One Time Code',  size:'lg', placeholder:'Enter code sent by email', maxlen:4, maxlen:4}

const MSG = {
    'SERVICE_ERROR': {type:'Error', title:'Service access error', text:'', button:'Retry', mode:'login'},
    'USER_SUSPENDED': {type:'Error', title:'User suspended', text:'Contact your Account Manager'},
    'WAIT_SET_PASSWD_SUSPENDED': {type:'Error', title:'User suspended', text:'Too many wrong codes'},
    'OTP_ERROR': {type:'Warning', title:'Try again', text:'Wrong code', button:'Retry', mode:'set_passwd'},
    'WAIT_SET_PASSWD_TIMEOUT': {type:'Warning', title:'Code expired', text:'Check you email for a new OTP', button:'Retry', mode:'set_passwd'},
    'WRONG_PASSWD': {type:'Warning', title:'Try again', text:'Wrong password', button:'Retry', mode:'login'},
    'CHANGE_PASSWD_OK': {type:'Success', title:'Password changed', text:'Use your new password on the next login', button:'Ok', mode:'redirect_app'},
    'USER_NOT_REGISTERED': {type:'Error', title:'Try again', text:'User not registered', button:'Retry', mode:'login'},
    'USER_NOT_ACTIVE': {type:'Error', title:'Service error', text:'User not active', button:'Retry', mode:'login'},
    'UNHANDLED': {type:'Error', title:'Service error', text:''},
}

export default (props) => {
    const frefs = [useRef(), useRef(), useRef()]
    const [mode, setMode] = useState('login')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(false)
    const [email, setEmail] = useState(false)
    const [focus, setFocus] = useState(null)
    const method = props.otp_mode || 'email'

    useEffect(() => {
        check_path()
    }, [])

    const check_path = () => {
        const url = new URL(window.location.href)
        switch(url.pathname) {
            case '/auth/login': setMode('login'); break
            case '/auth/set_passwd': setMode('set_passwd'); break
            case '/app/change_passwd': setMode('change_passwd'); break
            case '/app/logout': setMode('logout'); break
        }
    }

    const set_msg = (k) => setMessage(MSG[k])

    const save_creditials_and_do_login = (jwt_token, user_info) => {
        localStorage.setItem('jwt_token', jwt_token)
        if(user_info) localStorage.setItem('user_info', JSON.stringify(user_info))
        if(props.onAuth) props.onAuth(true)
        setTimeout(()=>window.location.href='/app', 50)
        // setTimeout(() => window.location.reload(), 50)
    }

    // Used after user inserts email to check if it is active
    const checkUser = async (email1) => {
        const res = await wrequest('/api/auth/check_user', 'POST', {email:email1})
        console.log(email1,res)
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        if(res.auth_status==='USER_OK') {setEmail(email1); return}
        if(res.auth_status==='WAIT_SET_PASSWD') {setEmail(email1); setMode('set_passwd');return}
        if(res.auth_status==='USER_SUSPENDED') {set_msg('USER_SUSPENDED');return}
        if(res.auth_status==='USER_NOT_REGISTERED') {set_msg('USER_NOT_REGISTERED');return}
        set_msg('UNHANDLED')
    }

    // Used after user inserts OTP to check if it is correct
    const checkOTP = async () => {
        if(!frefs[0].current) return false
        const otp1 = frefs[0].current.getValue()
        if(!otp1) return false
        const res = await wrequest('/api/auth/check_otp', 'POST', {email:email, otp:otp1})
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        if(res.auth_status==='OTP_OK') return // setOTP(otp1)
        if(res.auth_status==='OTP_ERROR') {set_msg('OTP_ERROR'); return}
        if(res.auth_status==='WAIT_SET_PASSWD_TIMEOUT') {set_msg('WAIT_SET_PASSWD_TIMEOUT'); return}
        if(res.auth_status==='WAIT_SET_PASSWD_SUSPENDED') {set_msg('WAIT_SET_PASSWD_SUSPENDED');return}
        if(res.auth_status==='USER_SUSPENDED') {set_msg('USER_SUSPENDED');return}
    }

    const login = async (d) => {
        // 'USER_NOT_REGISTERED', 'NOT_AUTHORIZED', 'PASSWD_NOT_STRONG', 'SUSPENDED_FAILS', 'WRONG_PASSWD, 'AUTHORIZED'
        if(d.email==null || d.password==null) return false
        setLoading(true)
        const res = await wrequest('/api/auth/login', 'POST', {email:d.email, passwd:d.password})
        setLoading(false)
        if(!res || res.status!=='OK') {set_msg('SERVICE_ERROR'); return}
        if(res.auth_status==='AUTHORIZED') {setMode('done'); save_creditials_and_do_login(res.jwt_token, res.user_info); return}
        if(res.auth_status==='WRONG_PASSWD') {set_msg('WRONG_PASSWD'); return}
        if(res.auth_status==='WAIT_SET_PASSWD') {setMode('set_passwd'); return}
        set_msg('UNHANDLED')
    }

    const set_passwd = async (d) => {
        console.log(d)
        // 'SET_PASSWD_DONE', 'USER_SUSPENDED', 'WAIT_SET_PASSWD_SUSPENDED', 'OTP_ERROR', 'WAIT_SET_PASSWD_TIMEOUT, 'PASSWD_NOT_STRONG', 'SET_PASSWD_OK'
        if(d.otp==null || d.password1==null) return false
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('user_info')
        setLoading(true)
        console.log({email:email, otp:d.otp, passwd:d.password1})
        const res = await wrequest('/api/auth/set_passwd', 'POST', {email:email, otp:d.otp, passwd:d.password1})
        setLoading(false)
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        if(res.auth_status==='SET_PASSWD_OK') {save_creditials_and_do_login(res.jwt_token, res.user_info); return}
        if(res.auth_status==='OTP_ERROR') {set_msg('OTP_ERROR'); return}
        if(res.auth_status==='WAIT_SET_PASSWD_TIMEOUT') {set_msg('WAIT_SET_PASSWD_TIMEOUT'); return}
        if(res.auth_status==='WAIT_SET_PASSWD_SUSPENDED') {set_msg('WAIT_SET_PASSWD_SUSPENDED');return}
        if(res.auth_status==='USER_SUSPENDED') {set_msg('USER_SUSPENDED');return}
    }

    const recover_passwd = async (d) => {
        // 'USER_NOT_ACTIVE', 'WAIT_SET_PASSWD'
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('user_info')
        setLoading(true)
        const res = await wrequest('/api/auth/recover_passwd', 'POST', {email:d.email})
        setLoading(false)
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR'); return}
        if(res.auth_status==='WAIT_SET_PASSWD') {setEmail(d.email); change_mode('set_passwd'); return}
        set_msg('USER_SUSPENDED')
    }

    const change_passwd = async (d) => {
        // 'USER_NOT_ACTIVE', 'PASSWD_NOT_STRONG', 'SUSPENDED_FAILS', 'WRONG_PASSWD', 'CHANGE_PASSWD_OK'
        if(d.password==null || d.password1==null) return false
        setLoading(true)
        const res = await wrequest('/api/auth/change_passwd', 'POST', {'passwd':d.password, 'new_passwd':d.password1})
        setLoading(false)
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        if(res.auth_status==='WRONG_PASSWD') {set_msg('WRONG_PASSWD'); return}
        if(res.auth_status==='CHANGE_PASSWD_OK') {set_msg('CHANGE_PASSWD_OK'); return}
        set_msg('UNHANDLED')
    }

    const logout = () => {
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('user_info')
        if(props.onAuth) props.onAuth(false)
        window.location.href='/'
    }

    // DRAFT
    const register = async (d) => {
        // 
        if(d.email==null) return false
        let res = await wrequest('/api/auth/check_user', 'POST', {email:d.email})
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        // If user exists go to login
        // localStorage.removeItem('jwt_token')
        // localStorage.removeItem('user_info')
        // if(props.allow_register) ...
        setLoading(true)
        res = await wrequest('/api/auth/register', 'POST', {'email':d.email})
        setLoading(false)
        if(!res || res.auth_status==null) {set_msg('SERVICE_ERROR');return}
        set_msg('UNHANDLED')
    }

    const render_logo = () => <Box cls="_Logo" w100 h={40} mt={40} mb={60} centercontent><Image center h100 iurl={props.logo}/></Box>
    const change_mode = (mode) => {setMode(mode); setError(null)}

    const onInputChangeOrDone = (seq, name, value, check) => {
        if(!check) return
        if(mode=='login' && name==='email') {checkUser(value); return}
        if(mode=='set_passwd' && name==='otp') {checkOTP(value); return}
    }

    const render_mode = () => {
        const h1 = 310
        switch(mode) {
            case 'login':
                return(
                    <Form
                        fields={[USER,PASSWD]}
                        button="Login"
                        button2={"Recover password"} onClick2={()=>change_mode('recover_passwd')}
                        button3={props.allow_register?null:'Not a user? Register'} onClick3={()=>change_mode('register')}
                        onInputChange={onInputChangeOrDone}
                        onSubmit={login} />)
            case 'register':
                return(
                    <Form
                        fields={[USER]}
                        button="Register"
                        button2={"Already a user? Login"} onClick2={()=>change_mode('login')}
                        onSubmit={register} />)
            case 'set_passwd':
                return(
                    <Form
                        fields={[OTP,PASSWD1,PASSWD2]}
                        button="Set Password"
                        onInputDone={onInputChangeOrDone}
                        onSubmit={set_passwd}
                    />)
            case 'recover_passwd':
                return(
                    <Form
                        fields={[USER]}
                        button="Recover Password"
                        button2={"Back to login"} onClick2={()=>change_mode('login')}
                        onSubmit={recover_passwd}
                    />)
            case 'change_passwd':
                return(
                    <Form
                        fields={[PASSWD,PASSWD1,PASSWD2]}
                        button="Change Password"
                        button2={"Cancel"} onClick2={()=>setMode('redirect_app')}
                        onSubmit={change_passwd}
                    />)
            case 'logout':
                return(
                    <Box column center>
                        <Box column minh={h1}/>
                        {!error && <Button lg action center focus={true} text="Logout" onClick={logout}/>}
                        {!error && <Button link center text="Cancel" onClick={()=>setMode('redirect_app')}/>}
                    </Box>
                )
        }
    }

    const render_message = () => {
        const h1 = 280
        const m = message
        if(!is_obj(m)) return null
        const icon = (m.type==='Error' || m.type==='Warning') ? 'error' : 'success'
        const iconc = m.type==='Error' ? 'red' : '#77ABBD'
        const color = error ? 'red':'n80'
        return(<>
            <Box column minw={350} center>
                <Box column w100 h={h1} br={8} white mb={70} pv={64}>
                    <Box w100 center mb={30}><Icon center size={70} color={iconc} icon={icon}/></Box>
                    <Box w100 mb={10}><Text fs={24} center mb={10} color="pri160" text={m.title}/></Box>
                    <Box w100><Text fs={16} color={color} center text={m.text}/></Box>
                </Box>
                {m.button && <Button focus={true} action lg text={m.button} onClick={()=>{setMessage(null); change_mode(m.mode)}}/>}
            </Box>
        </>)
    }

    const render_loading = () => <Box column minw={350} center centerv><Spinner/></Box>

    const render_form = () => {
        return(
            <>
                {render_logo()}
                {loading && render_loading()}
                {!loading && !message && render_mode()}
                {!loading && message && render_message()}
            </>
        )
    }

    const render_small = () => {
        return(
            <Box cls="_LoginPage" w100 h100 bg='grey050'>
                <Box cls="_Login" w={410} h={600} column panel center centerv onClick={()=>setError(null)}>
                    {render_form()}
                </Box>
            </Box>
        )
    }

    const render_large = () => {
        return(
            <Box cls="_LoginPage" w100 minw={800} maxw={3000} center h="100vh" bg='grey1'>
                <Box cls="_Login" w50 h100 onClick={()=>setError(null)}>
                    <Box w={410} center centerv>
                        {render_form()}
                    </Box>
                </Box>
                <Box cls="_LoginSlide" w50 h100 black center centerv>
                    <video width="100%" autoPlay loop muted>
                        <source src={video_url} type="video/mp4" />
                    </video>
                </Box>
            </Box>
        )
    }

    // const user_info = oget(props.meta,['user_info'])
    const video_url = urlf(oget(props.meta,['webapp', 'baseurl']), oget(props.meta,['webapp', 'login_movie']))
    if(mode==='redirect_app') return <Redirect to={`/app`}/>
    if(mode==='logout' || mode==='change_passwd') return render_small()
    return render_large()
}
