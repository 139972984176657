import React, { useState, useEffect } from 'react'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import { get_background, get_size, get_lpos } from '../js/util'

// To be used on: Dropdown, Tree, Switch, Date, DateRange, Input, Ranger, Radio, Value

/*
<ValueBox
    ll | lt | lb | lr | l0 | lpos={'T'|'B'|'L'|'R'|'0'}
    bb
    size=<size>
    dark | transparent | bg
    action | innactive
    h100
    nomargin | margintop
    onOpenClose
    onClear
    onRemove
    onEdit
    onOk
    onCancel
    isedit
    check
    error
>
    {children}
</ValueBox>
*/

export default (props) => {
    const [hover, setHover] = useState(false)

    const HL = 15
    const HV = props.h ? 15:24
    const BR = 5
    const WS = {'XS': 170, 'SM': 170, 'MD': 170, 'LG': 300,  'XL': 500}

    const onMouseOver = (ev) => setHover(true)
    const onMouseLeave = (ev) => setHover(false)

    const onClick = () => {
        if(props.onOpenClose) {props.onOpenClose(); return}
        if(props.onClick) props.onClick()
    }

    const render_labelbox = () => {
        const w_ = V ? '100%' : props.w ||'auto'
        return <Box cls="_LabelBox" w={w_} h={HL}><Text sm label>{props.label}</Text></Box>
    }

    const render_errorbox = () => {
        return(
            <Box cls="_Error" w={w1} h={HL} ph={5}><Text red text={props.error}/></Box>
        )
    }

    const render_value = () => {
        if(props.content)
            return props.content
        if(!props.value && props.placeholder) return <Text placeholder text={props.placeholder}/>
        if(props.value) return <Text value text={props.value} format={props.format}/>
    }

    const render_valuebox = () => {
        let w_ = (V||props.w100) ? '100%' : props.w
        if(props.w) w_=props.w
        const wi = get_wi()
        const style1 = {overflow:'hidden'}
        return(
            <Box cls="_ContentBox" w={w_} h={HV} bg={bg} ph={5} pv={2} br={br} bb={bb} style={style1}>
                <Box cls="_Value" w={`calc(100% - ${wi}px)`} h100>
                    {render_value()}
                </Box>
                <Box cls="_Icons" w={wi}>
                    {hover && (props.value || props.content) && props.onClear && <Icon box size={16} icon="selectclear" onClick={props.onClear}/>}
                    {props.onOpenClose && <Icon box right size={10} icon='down' mr={0} onClick={props.onOpenClose}/>}
                    {props.onEdit && <Icon box size={10} icon='down' onClick={props.onEdit}/>}
                    {props.onOk && <Icon box size={10} icon='down' onClick={props.onOk}/>}
                    {props.onCancel && <Icon box size={10} icon='down' onClick={props.onCancel}/>}
                </Box>
            </Box>
        )
    }

    const render_remove = () => {
        return(
            <Box cls="_Remove" w={20} h={20}>
                <Icon box icon="close_red" size={16} onClick={props.onRemove}/>
            </Box>
        )
    }

    const render_childrenbox = () => {
        return(
            <Box cls="_ChildrenBox" w100 h={h1} br={br} bb={bb}>
                {props.children}
            </Box>
        )
    }

    const get_w0 = () => {
        if(props.w) return props.w
        if(props.compact) return props.compact===true ? 75 : props.compact*15
        if(V) return WS[size]
        return 'auto'
    }

    const get_w1 = () => {
        if(props.w) return props.w
        if(props.w100) return '100%'
        if(V) return props.onRemove ? w0-20 : w0
        return 'auto'
    }

    const get_wi = () => {
        let n = 0
        if(props.onOpenClose) n+=1
        if(props.onClear && (!props.onEdit||props.onOk||props.onCancel)) n+=1
        return n*20
    }

    const get_h0 = () => {
        if(props.h) return props.h
        if(props.hcompact) return 24
        if(lpos==='T') return 55
        return 40
    }

    const get_bb = () => {
        if(props.bb) return props.bb
        if(props.error) return 'var(--red)'
        if(props.check) return 'var(--cyan600)'
        return 0
    }

    const size = get_size(props)
    const lpos = get_lpos(props)
    const bg = get_bg(props)
    const V = lpos==='T' || lpos==='B'
    const w0 = get_w0()
    const w1 = get_w1()
    const h1 = HV
    const h0 = get_h0()
    const bb = get_bb()
    const br = bb ? '5px 5px 0px 0px':BR
    const mr = props.w100 ? 0 : props.mr===undefined?10:props.mr
    const mb = props.mb||0

    // console.log(props.label, props.lpos, props.mr)
    
    return(
        <Box cls="_ValueBox" w={w0} h={h0} mr={mr} mb={mb} mt={props.mt} onClick={onClick} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Box cls="_ValueBox_Content" w={w1} h={h0}>
                {lpos==='T' && <Box cls="_ValueBox_Row_1" w100 h={HL}>{render_labelbox()}</Box>}
                <Box cls="_ValueBox_Row_2" w100 h={HV}>
                    {lpos==='L' && render_labelbox()}
                    {render_valuebox()}
                    {lpos==='R' && render_labelbox()}
                </Box>
                <Box cls="_ValueBox_Row_3" w100 h={HL}>
                    {lpos==='B' && !props.error && render_labelbox()}
                    {props.error && render_errorbox()}
                </Box>
                {props.children && render_childrenbox()}
            </Box>
            {props.onRemove && <Box cls="_ValueBox_Remove" w={20} h={HV} mt={lpos==='T'?HL:0}>{render_remove()}</Box>}
        </Box>
    )
}

const get_bg = (props) => {
    if(props.dark) return 'transparent'
    if(props.bb) return 'transparent'
    if(props.innactive) return 'var(--grey300)'
    if(props.bg) return get_background(props)
    return 'white'
}
