import React, { useState, useEffect, useRef } from 'react'
import useKeyPress from '../hooks/useKeyPress'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import { get_pass_props, SIZES, get_prop, set_margin, set_absolute, is_obj, is_array } from '../js/util'

const W = 140
const H = 40
const BG = 'white'
const B = null
const COLOR = 'high100'

const TYPE_PROPS = {
    Ok: {w:W, h:H, bg:'white', b:'high100', color:'high100', text:'OK'},
    Close: {w:W, h:H, bg:'white', b:'high100', color:'high100', text:'Close'},
    Cancel: {w:W, h:H, bg:'white', b:'high100', color:'high100',text:'Cancel'},
    Delete: {w:W, h:H, bg:'white', b:'high100', color:'high100', text:'Delete'},
    Innactive: {w:W, h:H, bg:'white', b:'high100', color:'high100'},
    Action: {w:W, h:H, bg:'high100', bg2:'high140', b:null, color:'white'},

    Link: {bg: 'transparent', color: 'var(--pri500)'},
    Danger: {bg: 'var(--red500)', color: 'white'},
    X: {}
}
const TYPES = Object.keys(TYPE_PROPS)

const PASS_PROPS = [
    'topleft','topright','bottomleft','bottomright',
    'left','right','top','bottom',
    'alignleft','alignright','aligntop','alignbottom',
    'center', 'centerv'
]

const WS = {'XS': 20, 'SM': 32, 'MD': 140, 'LG': 220,  'XL': 300}
const HS = {'XS': 20, 'SM': 30, 'MD': 24, 'LG': 36,  'XL': 50}
const FS = {XS:10, SM:11, MD:14, LG:16, XL:18}

export default (props) => {
    const ref = useRef(null)
    const enterPressed = useKeyPress(13)
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setSelected(props.selected)
    }, [props.type, props.cls, props.text, props.selected, props.inactive])

    useEffect(() => {
        if(ref.current && props.focus)
            ref.current.focus()
    }, [ref, props.focus])

    useEffect(() => {
        if(props.focus && enterPressed) onClick()
    }, [enterPressed, props.focus])

    const onClick = () => {
        if(props.inactive) return
        if(props.onClick) props.onClick(props.id)
    }

    const render_link = () => {
        const mv = props.mv || 10
        const color = 'blue'
        return(
            <Box w={props.w} mv={mv} onClick={onClick} {...pass_props}>
                <Text noselect fs={14} italic center color={color} text={props.text}/>
            </Box>
        )
    }

    const render_x = () => {
        return(
            <Box w={25} h={25} br="50%" z={100} {...pass_props} onClick={onClick} shadow>
                <Icon size={20} mt={5} ml={5} icon="close"/>
            </Box>
        )
    }

    const render_button = () => {
        const p = TYPE_PROPS[type]
        const w = props.w100 ? '100%' : (props.w || (props.lg ? 350 : (p.w || W)))
        const h = props.h || (props.lg ? 50 : (p.h || H))
        const bg = get_bg(props, p)
        const b = props.b || p.b || B
        const color = props.color || p.color || COLOR
        const text = props.text || p.text
        const { ml, mr, mt, mb } = get_ms(props)
        const fs = FS[size]
        const cls = props.cls ? `_Button ${props.cls}` : '_Button'
        const center = !props.icon
        const buttontype = props.submit?'submit':'button'
        const tag_style = {width:'100%', height:'100%', cursor:props.innactive?'not-allowed':'auto'}
        return(
            <Box ref={ref} className={cls} w={w} h={h} ml={ml} mr={mr} mt={props.mt} mb={mb} br={6} bg={bg} b={b} {...pass_props}
                onClick={onClick} innactive={props.innactive}>
                <button type={buttontype} style={tag_style}>
                    <Box w100 h100>
                        {props.icon && <Icon centerv ml={10} mr={10} size={16} icon={props.icon} color={color}/>}
                        <Text noselect centerv center={center} mr={20} fs={fs} text={text} color={color} italic={type==='Link'}/>
                        {props.arrow && <Icon icon="right" size={10}/>}
                    </Box>
                </button>
            </Box>
        )
    }

    const type = get_prop(props, 'type', TYPES, 'Ok', 'Capitalize')
    const size = get_prop(props, 'size', SIZES, 'MD', 'Uppercase')
    const pass_props = get_pass_props(props, PASS_PROPS)

    // console.log(props.action, props.innactive, props.text)

    if(type==='Link') return render_link()
    if(type==='X') return render_x()
    return render_button()
}

const get_ms = (props) => {
    return {
        ml: props.ml!=null ? props.ml : 0,
        mr: props.mr!=null ? props.mr : 0,
        mt: props.mt!=null ? props.mt : 'auto',
        mt: props.mb!=null ? props.mb : 'auto',
    }
}


export const get_margin_h = (props) => {
    if(props.w100) return 0
    return props.mh || 0
    // const left = props.left || str_cmp_caps(props.align,'left')
    // const center = props.center || str_cmp_caps(props.align,'center')
    // const right = props.right || str_cmp_caps(props.align,'right')
    // const top = props.top || str_cmp_caps(props.alignv,'top')
    // const centerv = props.centerv || str_cmp_caps(props.alignv,'centerv')
    // const bottom = props.bottom || str_cmp_caps(props.alignv,'bottom')
    // if(def!==null) style.margin=def
    // if(left) {style.marginLeft=0; style.marginRight='auto'}
    // if(center) {style.marginLeft='auto'; style.marginRight='auto'}
    // if(right) {style.marginLeft='auto'; style.marginRight=0}
    // if(!left && !center && !right && props.mr!==undefined) style.marginRight=props.mr===true?10:props.mr
    // if(!left && !center && !right && props.ml!==undefined) style.marginLeft=props.ml===true?10:props.ml
    // if(!left && !center && !right && props.mh!==undefined)
    //     {style.marginLeft=props.mh===true?10:props.mh; style.marginRight=props.mh===true?10:props.mh;}
    // if(top) {style.marginTop=0; style.marginBottom=0}
    // if(centerv) {style.marginTop='auto'; style.marginBottom='auto'}
    // if(bottom) {style.marginBottom=0; style.marginTop=0}
    // if(!top && !centerv && ! bottom && props.mt!==undefined) style.marginTop=props.mt===true?10:props.mt
    // if(!top && !centerv && !bottom && props.mb!==undefined) style.marginBottom=props.mb===true?10:props.mb
    // if(!top && !centerv && ! bottom && props.mv!==undefined)
    //     {style.marginTop=props.mv===true?10:props.mv; style.marginBottom=props.mv===true?10:props.mv}
}

export const get_margin_v = (props) => {
    if(props.mv) return props.mv
    return 10
}

const get_bg = (props, e) => {
    if(props.innactive) return '#BBBBBB'
    if(props.bg) return props.bg
    if(e.bg) return props.focus ? (e.bg2||e.bg) : e.bg
    return BG
}