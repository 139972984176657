import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import { oget, css_flex, get_prop, get_color, set_width, set_height, set_absolute, set_margin, set_padding, set_border, set_background} from '../js/util'
import { BACKSPACE, ENTER, formater, get_checkc, get_checkf } from '../js/field'

/*
    <Input
        w100
        type={type}
        size={size} | sm | md | lg | xl
        maxlen
        min
        max
    />

*/

export default forwardRef((props, parentref) => {
    const ref = useRef(null)
    const [hover, setHover] = useState(false)
    const [edit, setEdit] = useState(null)
    const [v0,setV0] = useState(null)
    const [v,setV] = useState(null)
    const [vf,setVf] = useState('')
    const [error, setError] = useState(null)
    const [check, setCheck] = useState(false)

    useImperativeHandle(parentref, () => ({
        getValue: ()=>vf,
        getCheck: ()=>check,
        setFocus: ()=>ref.current?ref.current.focus():null
    }))

    useEffect(() => {
        setV0(null); setV(null); setVf('');setCheck(false)
    }, [props.type, props.name])

    useEffect(() => {
        if(ref.current) ref.current.focus()
    }, [ref.current])

    useEffect(() => {
        setV(props.value)
        setVf(formater('S',props.value))
      }, [props.value])

    useEffect(() => {
        if(edit) {setV0(v)}
        else {if(v!==v0 && props.onUpdate) props.onUpdate(v)}
    }, [edit])

    const onClick = (e) => {
        setError(null)
        // e.preventDefault(); e.stopPropagation()
        // console.log(ref.current)
        // if(ref.current) ref.current.select()
    }

    const onFocus = (e) => {
        if(edit===true || edit===null) return
        setError(null)
        setEdit(true)
    }

    const onKeyDown = (e) => {
        setError(null)
        let c = e.keyCode <=96 ? e.keyCode : e.keyCode-(48* Math.floor(e.keyCode/48))
        if(c===BACKSPACE||c==37||c==39) return
        if(c===13) {e.preventDefault(); e.stopPropagation(); onDone(); ref.current.blur(); return}
        if(c===27) {onDone(); ref.current.blur(); return}
        if(check_c && !check_c(c,vf,props)) {e.preventDefault(); e.stopPropagation(); return}
        if(props.maxlen && v.length+1>props.maxlen) {{e.preventDefault(); e.stopPropagation(); return}}
        return
        const v1 = parseInt((v.toString())+(c-48).toString())
        if(true) { // is_numeric
            if(props.min && v1<props.min) {e.preventDefault(); e.stopPropagation(); return}
            if(props.max && v1>props.max) {e.preventDefault(); e.stopPropagation(); return}
        }
        // if(check_f && !check_f(v1)) e.preventDefault(); e.stopPropagation(); return}
    }

    const onChange = (e) => {
        let v = e.target.value
        // if(props.minlen && v1.length<props.minlen) {setCheck(false);return}
        const c = !check_f||check_f(v,props)
        setCheck(c)
        setV(v)
        setVf(formater('S',v))
        if(props.onChange) props.onChange(props.seq, props.name, v, c)
    }

    const onClear = () => {
        setV(null)
        setVf('')
        setCheck(false)
        setError(null)
        if(props.onChange) props.onChange(props.seq, props.name, null, false)
        ref.current.focus()
    }

    const onBlur = (e) => {
        if(v && !check) setError('Error')
        setEdit(false)
        if(props.onBlur) props.onBlur(props.seq, props.name)
    }

    const onDone = () => {
        if(v && !check) setError('Error')
        if(props.onDone) props.onDone(props.seq, props.name, v, check)
    }

    const render_clear = () => {
        if(!hover || !v) return null
        const mt = size==='MD'?8:14
        return <Icon size={8} mt={mt} icon='x' onMouseDown={onClear}/>
    }

    const render_input_tag = () => {
        const tag_style = {width:'100%', height:'100%', padding:`0px ${ph}px`, textAlign:align, fontSize:fs, border:0, color:color,
                           background:'transparent', border:0}
        const wz = props.blank ? 20:0
        return(
            <Box w100 h={h1} bg={bg} b={b} br={br} hidden>
                <Box wz={wz}>
                    <input
                        ref={ref}
                        style={tag_style}
                        type={inputtype}
                        name={name}
                        placeholder={props.placeholder}
                        autoComplete={autocomplete}
                        value={vf||''}
                        onClick={onClick}
                        onFocus={onFocus}
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                    />
                </Box>
                {props.blank && <Box w={20} onMouseOver={()=>setHover(true)} bg={bg}>{render_clear()}</Box>}
            </Box>
        )
    }

    const render_textarea_tag = () => {
        const tag_style = {width:'100%', height:'100%', padding:`10px ${ph}px`, textAlign:align, fontSize:fs, border:0, color:color}
        const h2 = 300
        return(
            <Box w100 h={h2} bg={bg} b={b} br={br} hidden red>
            <textarea
                ref={ref}
                style={tag_style}
                value={vf||''}
                onKeyDown={onKeyDown}
                onChange={onChange}
            />
            </Box>
        )
    }

    const render_error = () => {
        if(!error || type==='password2') return null
        return(
            <Box w100 ph={10} pv={3}>
                <Text w100 fs={12} color="red" text={error}/>
            </Box>
        )
    }

    const render_label = () => {
        if(props.nolabel || size==='SM') return null
        const w1 = props.ll ? 'auto' : '100%'
        const mb = props.ll ? 0 : 5
        return(
            <Box w={w1} mb={mb}>
                {props.label && <Text w100 fs={lfs} n80 text={props.label}/>}
            </Box>
        )
    }

    const render_input = () => {
        const mr = props.mr
        return(
            <Box cls="_Input" w={w} h={h} mr={mr} mb={mb} br={br} onClick={onClick} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                {render_label()}
                {type!=='area' && render_input_tag()}
                {type==='area' && render_textarea_tag()}
                {render_error()}
            </Box>
        )
    }

    const TYPES = ['Text', 'Email', 'Area', 'Number', 'User', 'Password', 'Password1', 'Password2', 'OTP']
    const type = get_prop(props, 'type', TYPES, 'Text', 'Lowercase')

    const check_c = get_checkc(type)
    const check_f = get_checkf(type)

    const size = get_prop(props, 'size', ['SM','MD','LG','XL'], 'MD', 'Uppercase')
    const inputtype = get_inputtype(type)
    const name = get_name(type)
    const autocomplete = get_autocomplete(type)

    const h = get_h(props, type, size)
    const h1 = get_h1(props, type, size)
    const w = get_w(props, type, size)
    const mb = get_mb(props, type, size)
    const ph = get_ph(props, type, size)
    const br = get_br(props, type, size)
    const b = get_b(props, type, size, check)
    const bb = check ? get_color('n100') : get_color('n60')
    const bg = get_bg(props, type, size)
    const color = get_color('n160')
    const align = 'left'
    const fs = get_fs(props, type, size)
    const lfs = get_lfs(props, type, size)
    return render_input()
})

const get_inputtype = (type) => {
    if(type==='user') return 'email'
    if(type==='email') return 'email'
    if(type==='password') return 'password'
    if(type==='password1') return 'password'
    if(type==='password2') return 'password'
    if(type==='OTP') return 'text'
    if(type==='number') return 'Number'
    return 'text'
}

const get_autocomplete = (type) => {
    if(type==='email') return 'username'
    if(type==='password') return 'current-password'
    if(type==='password1') return 'new-password'
    if(type==='password2') return 'new-password'
    if(type==='otp') return 'one-time-code'
    return null
}

const get_name = (type) => {
    if(type==='user') return 'username'
    if(type==='password') return 'password'
    if(type==='password1') return 'password'
    if(type==='password2') return 'password'
    return type
}

const get_h1 = (props, type, size) => {
    const H1 = {'SM':15, 'MD':25, 'LG':35, 'XL':40}
    if(type==='area') return props.h || '100%'
    return props.h1 || H1[size]
}

const get_h = (props, type, size) => {
    const H = {'SM':15, 'MD':35, 'LG':45, 'XL':50}
    if(props.ll || props.nolabel) return get_h1(props, type, size)
    return H[size]
}

const get_w = (props, type, size) => {
    const W = {'SM':50, 'MD':200, 'LG':'100%', 'XL':'100%'}
    if(type==='area') return props.w || '100%'
    return props.w || W[size]
}

const get_mb = (props, type, size) => {
    const MB = {'SM':0, 'MD':10, 'LG':50, 'XL':50}
    return props.mb || MB[size]
}

const get_ph = (props, type, size) => {
    const PH = {'SM':2, 'MD':10, 'LG':20, 'XL':20}
    return PH[size]
}

const get_br = (props, type, size) => {
    if(size==='SM') return 5
    return 8
}

const get_b = (props, type, size, check) => {
    if(size==='SM') return 0
    return check ? 'green':'#BBBBBB'
}

const get_bg = (props, type, size) => {
    if(props.white || size==='LG') return 'white'
    return 'transparent'
}

const get_fs = (props, type, size) => {
    const FS = {'SM':11, 'MD':12, 'LG':16, 'XL':18}
    return props.fs || FS[size]
}

const get_lfs = (props, type, size) => {
    const FS = {'SM':10, 'MD':12, 'LG':14, 'XL':16}
    return props.fs || FS[size]
}