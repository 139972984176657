
export const set_palette = (p) => p ? palette = {...base_palette, ...palettes[p]} : null

export const get_c = (c) => palette[c]

const base_palette = {
    dark: '#222945',

    white: '#FFFFFF',
    black: '000000',

    orange: '#E66A25',
    yellow: '#FFD568',

    success: '#009C22',
    fail: '#CF0000',
  
    green: '#009C22',
    green20: '#F5FFF0',
    green100: '#009C22',
    green160: '#009C22',
  
    red: '#E22A00',
    red20: '#FFF6F6',
    red60: '#FCEAE6',
    red80: '#F6BDB0',
    red100: '#E22A00',
    red120: '#F53205',
    red140: '#B52200',
    red160: '#881900',

    n00: '#FFFFFF',
    n20: '#F4F4F6',
    n40: '#E9EAEC',
    n60: '#DCDDE1',
    n80: '#90939E',
    n100: '#757781',
    n120: '#616161',
    n140: '#424242',
    n160: '#212121',

    pri40: '#77ABBD',
    pri80: '#313B63',
    pri100: '#222945',
    pri120: '#1F253E',
    pri140: '#1B2137',
    pri160: '#1A1F34',

    sec60: '#EBF2F5',
    sec80: '#F4F9FB',
    sec100: '#D5E5EB',
    sec120: '#419CBC',

    high60: '#F0F5FF',
    high80: '#DAE7FE',
    high100: '#0542AD',
    high120: '#043893',
    high140: '#032A6D',
}

const pw_palette = {
    dark: '#1E414C',

    orange: '#E66A25',

    pri40: '#DDE3E4',
    pri60: '#DDE3E4',
    pri80: '#426C7B',
    pri100: '#1E414C',
    pri120: '#1B3B44',
    pri140: '#18343D',
    pri160: '#18343D',

    high60: '#DDE3E4',
    high80: '#426C7B',
    high100: '#1E414C',
    high120: '#1B3B44',
    high140: '#18343D',

    blue100: '#1E414C',

    green20: '#E6F7E6',
    green40: '#B0E6B2',
    green60: '#00AE07',
    green80: '#008B06',
    green100: '#006804',

    red20: '#FCEAE6',
    red40: '#F6BDB0',
    red60: '#E22A00',
    red80: '#B52200',
    red100: '#B52200',

    yellow60: '##FFB800',
}

export const palettes = {
    base: base_palette,
    pw: pw_palette
}

let palette = base_palette
