import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'
import Image from './Image'
import { oget, urlf } from '../js/util'

const W2 = 1440

export default function Footer(props) {
    const history = useHistory()

    const render_footer_webapp = () => {
        const copy = oget(props.meta,['webapp','copy'])
        return(
            <Box cls="_Footer" w100 h={30} ph={20} pt={10} dark>
                <Text alignright mr={30} sm color="n40" text={copy}/>
            </Box>
        )
    }

    const render_contact = (e) => {
        // onClick={()=>window.open(props.maplink, '_blank')}
        const mb = 5
        return(
            <Box w100 mb={50}>  
                <Box w100 ph={20}>
                    {e.address && <Text fs={12} w100 mb={mb} color="white" text={e.address}/>}
                    {e.address2 && <Text fs={12} w100 mb={mb} color="white" text={e.address2}/>}
                    {e.postal && <Text fs={12} w100 mb={mb} color="white" text={e.postal}/>}
                    {e.country && <Text fs={12} w100 mb={mb} color="white" text={e.country}/>}
                    {e.email && <Text fs={14} w100 mt={4*mb} mb={mb} color="white" text={e.email}/>}
                </Box>
            </Box>
        )
    }

    const render_p2020 = () => {
        const url1 = urlf(oget(props.content,['baseurl']),'images/P2020.png')
        const url2 = urlf(oget(props.content,['baseurl']),'images/P2020.pdf')
        const h = props.mobile ? 30 : 50
        return(
            <Box w100>
                <Box alignright={!props.mobile} mb={50} onClick={()=>window.open(url2)}>
                    <Image meta={props.meta} h={h} iurl={url1}/>
                </Box>
            </Box>
        )
    }

    const render_footer_website = () => {
        const copy = oget(props,['footer','copy'])
        const company = oget(props,['footer','company'])
        let logo = oget(props,['content','logodark'])
        const description = oget(props.footer,['description'])
        const contacts = oget(props.footer,['contacts'])
        const linkedin = oget(props.footer,['linkedin'])
        const p2020 = oget(props.footer,['p2020'])
        const login = oget(props.footer,['login'])
        const w1 = props.mobile ? '100%':'50%'
        const h = props.mobile ? 'auto':'auto'
        const align = props.mobile ? 'center':'left'
        const pl = props.mobile ? 30:100
        const pt = props.mobile ? 50:100
        const w2 = props.mobile ? '100%' : '50%'
        const ph = props.mobile ? 20 : 100
        return(
            <Box cls="_Footer" w100 bg="pri160">
                <Box w100 maxw={W2} center ph={ph} pv={100} bg="pri160">
                    <Box w={w2} h={200}>
                        <Box w100>
                            <Box w100 pl={20} mb={20}><Image meta={props.meta} h={20} w={180} src={logo}/></Box>
                            {company && <Text h3 bold mb={10} color="white" text={company}/>}
                            {description && <Text xl mb={10} align={align} color="white" text={description}/>}
                        </Box>
                        {contacts && contacts.map(e=>render_contact(e))}
                        <Box w100>
                            {/* <Text fs={12} mr={20} color="n80" text="Privacy policy"/>
                            <Text fs={12} mr={20} color="n80" text="Quality and safety policy"/>
                            <Text fs={12} mr={20} color="n80" text="Cookie policy"/>
                            <Text fs={12} mr={20} color="n80" text="Legal terms"/> */}
                        </Box>
                    </Box>
                    <Box w={w2} h={200} pl={20}>
                        {p2020 && render_p2020()}
                        <Box w100 mb={0}>
                            {linkedin!==null && <Box alignright={!props.mobile}><Icon align={align} ml={0} mt={10} size={24} icon="linkedin" href={linkedin}/></Box>}
                        </Box>
                        <Box w100 h={100}>
                            <Text w100 right={!props.mobile} color="white" bold text={copy}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    if(props.webapp) return render_footer_webapp()
    return render_footer_website()
}
