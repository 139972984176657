import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import useWindowDims from '../hooks/useWindowDims'
import Box from './Box'
import Text from './Text'
import Header from './Header'
import Footer from './Footer'
import Slide from './Slide'
import { oget, urlf, getDevice, is_array, is_string } from '../js/util'

export default (props) => {
  const [w, h] = useWindowDims()
  const {pathname, hash, key, search} = useLocation()
  const [webpage, setWebpage] = useState(null)
  const [logo, setLogo] = useState(null)
  const [logomin, setLogomin] = useState(null)
  const [menu, setMenu] = useState(null)
  const [mobile, setMobile] = useState(null)
  const [headerBackMode, setHeaderBackMode] = useState(null)
  const [selectedHash, setSelectedHash] = useState(null)

  useEffect(() => {
    load()
  }, [props.content])

  useEffect(() => {
    setSelectedHash(hash)
  }, [hash])

  useEffect(() => {
    const m = getDevice()==='mobile' || window.innerWidth<900  // window.screen.width
    setMobile(m)
  }, [w,h])

  const load = () => {
    if(!props.content) return
    let logo_ = oget(props.content,['logo'])
    let logo_min_ = oget(props.content,['logo-min'])
    let menu_ = oget(props.content,['header','menu'])
    let webpage_ = null
    if(props.content.webpages && props.content.webpages.length) {
      let n = props.content.webpages.findIndex(e=>e.path===pathname)
      webpage_ = props.content.webpages[(n!==-1)?n:0]
      let webpage_logo = oget(webpage_,['header','logo'])
      let webpage_logomin = oget(webpage_,['header','logo-min'])
      let webpage_menu_ = oget(webpage_,['header','menu'])
      logo_ = webpage_logo || logo_
      logo_min_ = webpage_logomin || logo_min_
      menu_ = webpage_menu_ || menu_
    }
    setLogo(urlf(oget(props.content,['baseurl']),logo_))
    setLogomin(urlf(oget(props.content,['baseurl']),logo_min_))
    setMenu(menu_)
    setWebpage(webpage_)
  }

  const maxw = 1440
  if(!webpage) return null
  const query = parse_query(search)
  const style = {fontFamily: "'Poppins', sans-serif"}
  const login = oget(props,['content','login'])
  return(
    <Box cls="WebPage" w100 style={style}>
      <Header fixed lg webapp={false} menu={menu} logo={logo} logomin={logomin} login={login} backMode={headerBackMode}/>
      <Box cls="_Sections" w100 center>
        {webpage && webpage.slides && webpage.slides.map((e,i)=>
          <Slide key={i} meta={props.meta} content={props.content} pathname={pathname} query={query} mobile={mobile} webpage={webpage} idx={i} {...e}
                 selectedHash={selectedHash}
                 setHeaderInBackMode={(e)=>setHeaderBackMode(e)} setHeaderInNormalMode={()=>setHeaderBackMode(null)}/>)}
      </Box>
      <Footer webpage meta={props.meta} mobile={mobile} content={props.content} logos={props.logos} footer={webpage.footer||props.content.footer}/>
    </Box>
  )
}

const parse_query = (search) => {
  if(!search || search.length<1) return null
  return JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}