import React, { useState, useRef, useEffect } from 'react'
import Box from './Box'
import Text from './Text'
import SVGDefs from '../assets/svg'
import { ICONS } from '../assets/svg'
import { css_flex, get_props_cls, set_margin, set_border, set_absolute, get_color} from '../js/util'

export default (props) => {
    const onClick = (ev) => {if(props.onClick) props.onClick(ev, props.id)}
    const onMouseDown = (ev) => {if(props.onMouseDown) props.onMouseDown(ev, props.id)}

    const cls = get_props_cls(props, '_Icon')
    const size = get_size(props)
    let color = props.dark ? 'white' : get_color(props.color)
    let bg = props.white ? 'white':'transparent'
    if(props.selected) {color='white'; bg=get_color('n120')}
    if(props.transparent) bg='transparent'
    const is_text = props.text || !(props.icon in ICONS)
    let style = {...css_flex(), width:size, height:is_text?20:size, background:bg}
    set_margin(props, style, 'auto 5px')
    set_border(props, style)
    set_absolute(props, style)
    if(props.pad) style.padding = props.pad
    if(props.z) style.zIndex = props.z

    const render_icon = () => {
        return <SVGDefs icon={props.icon} fill={color} stroke={color}></SVGDefs>
    }

    const render_text = () => {
        const text1 = props.text || props.icon
        const text = typeof(text1)==='string' ? text1.slice(0,6).toUpperCase() : ''
        const n = text.length
        const fs = n===1 ? 12 : 10
        const mt = n===1 ? 0 : 1
        const bg = props.selected ? get_color('n120') : 'transparent'
        const color = props.selected ? 'white' : 'n160'
        return(
            <Box w100 h100 bg={bg}>
                <Text fs={fs} mt={mt} color={color} center text={text}/>
            </Box>
        )
    }
    return(
        <Box href={props.href||null} cls={cls} onClick={onClick} onMouseDown={onMouseDown} style={style}>
            {!is_text && render_icon()}
            {is_text && render_text()}
        </Box>
    )
}

const get_size = (props) => {
    const is_text = props.text || !(props.icon in ICONS)
    if(!is_text) return props.size || 16
    const text = props.text || props.icon
    if(!text || text.length<3) return 24
    return text.length * 9
}