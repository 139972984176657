import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Box from './Box'
import Text from './Text'
import Icon from './Icon'

/*
    <Option
        key
        selbar
        selected
        minimized
        icon
        text
        icon2
    />
*/

export default (props) => {

    const render_item = (ml1) => {
        if(props.minimized)
            return <Box centerv w={40} ml={15}><Icon dark size={20} mr={20} icon={props.icon}/></Box>
        return(
            <Box centerv ml={ml1} wz={40}>
                {props.icon && <Icon dark size={20} mr={20} icon={props.icon}/>}
                {props.open!==false && <Text fs={16} mt={2} bold color="white" text={props.text}/>}
                {props.icon2 && <Icon dark size={14} alignright mt={4} mr={30} icon={props.icon2}/>}
            </Box>
        )
    }

    const render_selbar = () => {
        if(!props.selbar) return null 
        const w = 5
        const bg = props.selected===true ? 'pri40':'transparent'
        const br = '0px 5px 5px 0px'
        return <Box cls="_Selbar" w={w} h100 bg={bg} br={br}/>
    }

    const w = props.w || '100%'
    const h = props.h || 56
    const w1z = props.selbar ? 5 : 0
    const bg = props.selected===true ? 'pri140':'transparent'
    const ml = props.minimized ? 15 : 40-5

    return(
        <Box key={props.id} w={w} h={h} bg={bg} onClick={()=>props.onClick?props.onClick():null}>
            {render_selbar()}
            <Box wz={w1z}>
                {props.url!=null && <Box centerv w100><Link style={{width:'100%'}} to={props.url}>{render_item(ml)}</Link></Box>}
                {props.url==null && <Box centerv w100>{render_item(ml)}</Box>}
            </Box>
        </Box>
    )
}